<template>
  <div class="privacy-policy-inner">
    <div class="content">
      <div class="header1">隐私政策</div>
      <span>更新日期：2021年12月10日</span>
      <br />
      <span>
欢迎使用工作蜂水印相机，简称工作蜂（“平台”）。该平台由北京自由视觉科技有限公司（“我们”或“我们”）提供和控制。我们致力于保护和尊重您的隐私。
</span>
<br />
<span>
本政策规定了我们处理您从您那里收集或您提供给我们的任何个人数据的基础。请仔细阅读以下内容，以了解我们对您的个人数据以及我们将如何处理的看法和做法。通过访问或使用我们的网站，服务，应用程序，产品和内容（包括但不限于平台）（统称为“服务”），即表示您接受并同意本政策中所述的做法。本政策未定义的大写术语具有“ 服务条款”中赋予它们的含义。
</span>

      <div class="header2">1.我们使用的个人数据类型</div>
      <span>我们可能会收集和使用有关您的以下信息：</span>
      <ul>
        <li>
          <span class="contentBold">APP收集的信息：</span
          >APP会自动从您那里收集某些数据，包括IP地址或其他唯一设备标识符，IMEI，MAC，Cookie（定义如下），移动运营商，时区设置，语言选项，移动或设备型号，屏幕分辨率，操作系统和平台以及有关您使用平台的信息，主要用保证APP正常运行使用和体验优化；APP是在用户同意隐私政策内容之后才会开始收集，不同意协议不会收集。
        </li>
        <li>
          <span class="contentBold">位置数据：</span
          >当您在移动设备上使用平台时，我们集成了高德定位服务，来处理有关您位置的信息，包括基于您的SIM卡，IP地址或移动设备位置设置的位置信息，以及如果在您的移动设备上被激活的位置信息。我们可能会使用此信息为您提供基于位置的服务，例如推荐附近1000以内的服务地点。如果您不希望与我们共享您的位置，则可以在移动设备上关闭GPS功能；高德SDK是在用户同意隐私政策内容之后才会开启，不同意协议不会开启。
        </li>
        <li>
          <span class="contentBold">友盟SDK初始化：</span
          >APP接入了友盟SDK需要收集您的设备Mac地址、唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息）以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力；友盟SDK是在用户同意隐私政策内容之后才会开启，不同意协议不会开启。
        </li>
        <li>
          <span class="contentBold">TPNS SDK初始化：</span
          >APP已集成 TPNS SDK（腾讯旗下云服务产品），主要用于APP应用内团队消息推送，让消息更快跟准的送达给用户；TPNS SDK是在用户同意隐私政策内容之后才会开启，不同意协议不会开启。
        </li>
        <li>
          <span class="contentBold">ADmobile广告聚合服务SDK和ADCDN SDK：</span
          >APP已集成使用ADmobile和ADCDN的广告聚合服务，聚合多家广告平台进行变现。
        </li>
      </ul>
      <span>第三方SDK收集的信息：1.【设备信息】：系统版本名、系统版本号、设备型号、分辨率、屏幕DPI、设备生产商、网络类型、系统语言、时区、时间戳、User Agent信息、屏幕方向。2.【应用信息】当前应用包名、应用版本名、应用版本信息。3.【设备标识信息】设备id(android_id)、IMEI（用户授权才收集）、OAID、Mac地址、IDFA。4. 地理位置信息（用户授权才收集）、广告交互数据。</span>


      <div class="header2">2.权限获取相关说明</div>
      <span>工作蜂需要您授权以下权限：</span>
      <br />
    <ul>
        <li>
          <span class="contentBold">获取手机状态（电话或识别码）。</span
          >当您在移动设备上使用平台时，需允许平台访问设备的电话和唯一识别码，来完成一键绑定手机号或一键手机号登录平台。
        </li>
        <li>
          <span class="contentBold">获位置权限（基于网络）。</span
          >当您在移动设备上使用平台时，需允许平台访问您的位置信息，因为工作蜂集成了高德定位服务，用于解决水印拍照时显示当前位置信息，为用户提供附近1000米以内的地点服务。
        </li>
        <li>
          <span class="contentBold">获位置权限（基于GPS和网络）。</span
          >当您在移动设备上使用平台时，需允许平台访问您的位置信息，当设备处于无网络或网络正常状态下，需通过全球定位系统（GPS）或网络位置信息源（例如基站和WLAN）获取您的精确位置。解决水印拍照时显示当前位置信息，为用户提供附近1000米以内的地点服务。
        </li>
        <li>
          <span class="contentBold">获取麦克风访问权限（录音）。</span
          >当您在移动设备上使用平台录制视频时，需要访问手机麦克风权限来完成视频声音的录制。
        </li>
        <li>
          <span class="contentBold">获取SD卡访问权限（存储、修改或删除内容）。</span
          >当您在移动设备上使用平台时，拍摄的照片和视频存储到SD卡里（本地相册）。如照片或视频添加水印时，需从读取您SD卡（本地相册）上的内容，来完成添加水印功能。
        </li>
        <li>
          <span class="contentBold">获取相机访问权限（拍摄照片和视频）。</span
          >当您在移动设备上使用平台时，拍摄照片和视频是需要开启相机访问权限，才能访问摄像头来完成拍照和录制视频。
        </li>
        <li>
          <span class="contentBold">查看WLAN链接（网络链接）。</span
          >当您在移动设备上使用平台时，需要查看网络链接状态，来完成水印时间校对、位置信息显示，团队登录和分享存储功能。
        </li>
        <li>
          <span class="contentBold">控制振动（拍视频提醒）。</span
          >当您使用工作蜂开始录制视频时，控制振动来提醒视频开始录制。
        </li>
        <li>
          <span class="contentBold">获取软件安装列表（第三方登录、软件跳转）。</span
          >当您使用工作蜂时，为实现信息分享、第三方登录、跳转应用市场时不会出现因为您没有安装第三方APP导致应用崩溃，我们会在您使用信息分享、第三方登录、跳转应用市场时获取您手机的软件安装列表来检查您手机中有没有安装相应的第三方APP。
        </li>
        <li>
          <span class="contentBold">防止手机休眠。</span
          >当您使用工作蜂拍摄视频时，因为拍摄视频时间过长，手机休眠而导致中断拍摄，允许平台来控制手机休眠状态。 
        </li>
        <li>
          <span class="contentBold">检索正在运行的应用。</span
          >当您使用工作蜂时，为了让您快速的完成版本更新，会检索设备里是否安装各大应用商店，快速跳转至各大应用商店来完成版本更新。
        </li>
    </ul>

      <div class="header2">3.Cookies</div>
      <span>我们使用cookie和其他类似技术（例如，网络信标，Flash cookie等）（“ Cookies”）来增强您使用平台的体验。Cookies是很小的文件，当它们放在您的设备上时，使我们能够提供某些功能。</span>
      <br />
      <br />
      <span>如果出于任何原因您不希望使用Cookies，可以通过更改浏览器的设置来禁用Cookies。但是，如果您这样做，将影响您对平台的使用，我们将不再能够为您提供个性化内容。除非您选择退出Cookies，否则我们将假设您同意使用Cookies。</span>
      <br />
      <br />
      <span>“不跟踪”信号是用户可以在其网络浏览器上设置的首选项，以限制跨第三方网站或在线服务跟踪其活动的方式。平台不响应您的Web浏览器中的“不跟踪”信号。</span>
      <span></span>


      <div class="header2">4.我们如何使用您的个人数据</div>
      <span>我们将通过以下方式使用这些信息：</span>

        <ul>
        <li>根据我们与您签订的合同，我们可能会将您的信息用于：</li>
        <ul>
          <li>个性化您收到的内容，并为您提供您可能感兴趣的定制内容；</li>
          <li>通知您有关我们服务的更改；</li>
          <li>为您提供用户支持；</li>
          <li>执行我们的条款，条件和政策；</li>
          <li>向您发送礼物或付款以进行营销或其他活动</li>
          <li>与您交流</li>
        </ul>

        <li>
          响应您并确保我们的产品和组织正常运行符合我们的合法利益，因此我们可能会将您的信息用于：
        </li>

        <ul>
          <li>
            改善平台，并确保以最有效的方式为您和您的设备展示平台中的内容；
          </li>
          <li>
            管理平台并进行内部操作，包括故障排除，数据分析，测试，研究，统计和调查目的；
          </li>
          <li>允许您选择参与平台的交互功能；</li>
          <li>个性化您收到的内容；</li>
          <li>保持平台安全；</li>
          <li>开发我们的平台并进行产品开发；</li>
          <li>衡量并了解我们为您和他人服务的广告的有效性；</li>
          <li>
            就您或他们感兴趣的商品或服务向您和平台的其他用户提出建议和建议；
          </li>
          <li>
            向您提供（或允许选定的第三方向您提供）有关我们认为您可能会感兴趣的商品或服务的信息。如果我们允许选定的第三方使用您的数据，则我们（或他们）只有在您同意的情况下才会通过电子方式与您联系。
          </li>
        </ul>
      </ul>
      

      <div class="header2">5.我们在哪里存储数据</div>
      <span>我们从您那里收集的信息可能会转移到您的国家和欧洲经济区（“ EEA”）以外的目的地，并存储在该目的地，尤其是新加坡或中国。还可能由员工（例如，在我们国家/地区或为我们工作的EEA以外的国家/地区运营的员工）为我们的供应商之一或我们的业务合作伙伴之一进行处理。提交您的信息，即表示您同意此传输，存储或处理。我们将采取合理必要的所有步骤，以确保按照本政策安全地处理您的信息。</span>
      <br />
      <br />
      <span
        >中国国内用户数据都通过阿里巴巴旗下的阿里云服务器加密存储在中国境内。</span
      >
      <div class="header2">6.您的个人数据的安全性</div>
      <span>不幸的是，通过互联网传输信息并不完全安全。尽管我们将尽最大努力保护您的个人数据（例如通过加密），但我们不能保证您通过平台传输的信息的安全性；任何传输均由您自担风险。</span>
      <br />
      <br />
      <span
        >我们可能会不时包含往返于我们合作伙伴网络，广告商和分支机构网站的链接。如果您访问这些网站中的任何一个的链接，请注意，这些网站有其自己的隐私政策，我们对这些政策不承担任何责任。在向这些网站提交任何信息之前，请检查这些政策。</span>

      <div class="header2">7.账号注销及数据保留</div>
      <span>只要适用法律要求我们保留您的信息，我们将保留您的信息。</span>
      <br />
      <br />
      <span>注销工作蜂帐号是不可恢复的操作，你应自行备份工作蜂帐号相关的信息和数据。操作之前，请确认在工作蜂帐号内退出所有相关团队，且所有服务均已进行妥善处理。</span>
      <br />
      <br />
      <span>请谨记：注销工作蜂帐号，你将无法再使用本工作蜂帐号或找回你添加或绑定的任何内容或信息（即使你使用相同的手机号码或微信号再次注册并使用工作蜂），包括但不限于：（1）你将无法登录、使用本工作蜂帐号。（2）你打卡帐号的个人资料和历史信息（包含昵称、头像、团队记录、消息记录、拍照内容等）都将无法找回。（3）注销工作蜂帐号前，你必须退出所有的团队。你需自行保存团队照片数据。</span>
      <br />
      <br />
      <span>请注意，注销你的工作蜂帐号并不代表本工作蜂帐号注销前的帐号行为和相关责任得到豁免或减轻。</span>
      <br />
      <br />
      <span>在您终止使用我们的服务后，我们可能会以汇总的匿名或假名格式存储您的信息。我们将根据要求删除您的数据。尽管有上述规定，我们也可能会保留合理必要的任何个人信息，以履行我们的法律义务，允许我们解决和诉讼争端以及执行我们的协议。</span>
      <br />
      <br />
      <span>注销路径：打开APP-进入个人中心-点击头像信息-进入个人信息页面-选择注销账号。</span>
    
    <div class="header2">8.数据保留</div>
      <span>只要适用法律要求我们保留您的信息，我们将保留您的信息。</span>
      <br />
      <br />
      <span>在您终止使用我们的服务后，我们可能会以汇总的匿名或假名格式存储您的信息。我们将根据要求删除您的数据。尽管有上述规定，我们也可能会保留合理必要的任何个人信息，以履行我们的法律义务，允许我们解决和诉讼争端以及执行我们的协议。</span>
    <div class="header2">9.关于儿童的信息</div>
      <span>该平台并非针对13岁以下的儿童（或韩国的14岁以下的儿童）。未经父母或法定监护人同意，相关年龄以下的用户不得使用平台或在平台上注册帐户。如果我们发现根据相关人士收集的个人数据并且未提供父母或法定监护人的同意，我们将删除此信息并终止该人的帐户。如果您认为我们可能包含有关年龄以下儿童的个人数据或从中收集的个人数据，请通过本政策末尾的地址与我们联系。</span>
      <div class="header2">10.投诉</div>
      <span
        >如果您希望就我们如何处理您的个人数据提出投诉，请首先通过本政策末尾的电子邮件地址与我们联系，我们将尽力尽快处理您的请求。这不影响您向数据保护机构提出索赔或遵循争议程序的权利。</span
      >

      <div class="header2">11.变更</div>
      <span
        >我们将采取商业上合理的努力，以将本政策的任何重大变更通知所有用户，例如通过平台上的通知，但是，您应定期查看本政策以检查此类变更。我们还将在此政策的顶部更新“最后更新”日期，以反映该政策的生效日期。您在更新政策日期之后继续访问或使用服务即表示您接受更新政策。如果您不同意更新的策略，则必须停止访问或使用服务。</span
      >

      <div class="header2">12.联系我们</div>
      <span>欢迎对此政策提出疑问，意见和要求，并应解决以下问题：</span>
      
      <br/><span class = "contentSmall">邮箱： dakaxiangji@126.com</span>
      <br/><span class = "contentSmall">微信号：dakaxiangji01</span>
      <br/><span class = "contentSmall">QQ群：720844363</span>
    </div>
  </div>
</template>

<script>
// import {getApi} from "@/common"

export default {
  name: "privacy-policy",
  data() {
    return {};
  },
  created() {},
  methods: {}
};
</script>
<style scoped lang="scss">
.privacy-policy-inner {
  width: 100%;
  display: flex;
  justify-content: center;

  .content {
    width: 1000px;
    margin-top: 100px;
    margin-bottom: 100px;
    font-size: 15px;
    line-height: 30px;
    font-weight: 100;
    padding: 0 20px;
    box-sizing: border-box;

    .header1 {
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 8px;
    }

    .header2 {
      font-weight: 700;
      margin-top: 30px;
    }

    .header3 {
      font-size: 16px;
      line-height: 28px;
      font-weight: 700;
      margin-top: 15px;
    }

    .contentBold {
      font-size: 15px;
      line-height: 24px;
      font-weight: 600;
    }

    .contentSmall {
      font-size: 14px;
      line-height: 24px;
      font-weight: 100;
      margin-top: 10px;

    }
  }
}
</style>
