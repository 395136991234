<template>
  <div class="folder-list">
    <div class="row-header">
      <el-checkbox class="check" v-model="checkAll" @change="handleCheckAll">
        {{
          $store.state.checkedFolderTotal > 0
              ? "已选中" + $store.state.checkedFolderTotal + "个文件夹"
              : "文件名"
        }}
      </el-checkbox>
      <div class="time">
        {{ $store.state.category === "member" ? "图片数量" : "时间" }}
      </div>
    </div>
    <div
        @click="handleCheckFolder($event, findex)"
        @dblclick="handleDbclickFolder($event, findex)"
        :class="folder.checked ? 'row checked' : 'row'"
        v-for="(folder, findex) in $store.state.folders"
        :key="findex"
    >
      <el-checkbox class="check" v-model="folder.checked"></el-checkbox>
      <div class="data">
        <div class="image">
          <img src="../../assets/images/folder.png" alt="Tag"/><span>
            {{ folerDisplayTitle(folder) }}
          </span>
        </div>
        <div class="time">
          {{
            $store.state.category !== "member"
                ? new Date(folder.updateTime).format("yyyy-MM-dd hh:mm")
                : folder.cameraTeamImageInfos
                ? folder.cameraTeamImageInfos.length
                : 0
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FolderItemList",
  data() {
    return {
      checkAll: false,
      singleClickTimeout: null
    };
  },
  methods: {
    folerDisplayTitle(folder) {
      var displayStr = ""
      if (folder.title) {
        displayStr = folder.title
      } else {
        displayStr = folder.nickname
      }
      if (displayStr.length > 8) {
        return displayStr.substring(0,7) + "..."
      }
      return displayStr
    },
    handleCheckAll(check) {
      console.log("全选状态：", check);
      this.$store.commit("checkAllFolders", check);
      this.calcCheckedStatus();
    },
    handleCheckFolder(check, index) {
      const that = this
      clearTimeout(this.singleClickTimeout)
      this.singleClickTimeout = setTimeout(function () {
        console.log("单张文件夹的选中状态：", check, index);
        that.$store.commit("checkOneFolder", index);
        that.calcCheckedStatus();
      }, 200)
    },
    handleDbclickFolder(evt, index) {
      clearTimeout(this.singleClickTimeout)
      const currentFolder = this.$store.state.folders[index];
      console.log("当前的文件夹：", currentFolder);
      if (this.$store.state.category === "work" && currentFolder.grade === 1) {
        //一级目录，需要进入二级目录
        this.$store.commit("syncFolders", {
          folders: currentFolder.cameraTeamLabelInfos,
          previousFolder: currentFolder,
          previousFolders: this.$store.state.folders,
          folderLevel: 2,
        });
      } else {
        //二级目录，需要进入图片列表页面
        this.$store.commit("syncPictures", {
          pictures: currentFolder.cameraTeamImageInfos,
          previousFolder: currentFolder,
          previousFolders: this.$store.state.folders,
        });
      }
    },
    calcCheckedStatus() {
      //计算全选的状态
      let checkAll = true;
      this.$store.state.folders.forEach((folder) => {
        if (!folder.checked) {
          checkAll = false;
        }
      });
      this.checkAll = checkAll;
    },
  },
};
</script>

<style scoped lang="scss">
.folder-list {
  margin-left: 25px;
  background: #fff;

  .row-header {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #6a6a6a;
    height: 50px;
    border-bottom: 1px solid #f2f6fd;

    .check {
      flex: 3;
      margin-right: 10px;
    }

    .time {
      width: 400px;
      text-align: center;
    }
  }

  .row.checked {
    .data {
      background: #f8fbff;
    }
  }

  .row {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #6a6a6a;
    height: 40px;
    border-bottom: 1px solid #f2f6fd;

    .check {
      margin-right: 10px;
    }

    .data:hover {
      background: #f8fbff;
    }

    .data {
      flex: 1;
      height: 40px;
      display: flex;
      align-items: center;

      .image {
        flex: 1;
        display: flex;
        align-items: center;

        img {
          width: 22px;
          height: 26px;
          margin-right: 10px;
          cursor: pointer;
        }
      }

      .time {
        width: 400px;
        text-align: center;
      }
    }
  }
}
</style>
