<template>
  <div class="forward-inner">
    <div class="header">
        <div class="navi-title">
            <span @click="goBack()" style=" color: #2298F3;cursor: pointer;">返回上一级 | 闪电转发</span>&nbsp;>&nbsp;转发绑定设置
        </div>
    </div>
    <div class="content">
      <div style="margin-top:12px;margin-bottom:30px;">1.首先选择你要转发的工作群，获取Webhook地址。</div>
      <router-link class="webhook-link" tag="a" target="_blank" :to="{name:'forward-guide'}">获取【钉钉】Webhook地址</router-link>
      <div style="margin-top:40px;">2.工作群名称</div>
      <el-input class="group-name" type="textarea" placeholder="输入名称" v-model="groupName" resize="none"></el-input>
      <div style="margin-top:30px;">3.粘贴Webhook地址，绑定即可完成设置</div>
      <el-input class="webhook-address" type="textarea" placeholder="粘贴Webhook地址" v-model="webhookUrl" resize="none"></el-input>
      <button class="new-group-btn" @click="onSaveNewGroup()" v-if="mode===0">完成</button>
      <div class="operations" v-if="mode!==0">
        <button class="edit-group-btn" @click="onEditGroup()">完成</button>
        <button class="delete-group-btn" @click="onDeleteGroup()">删除</button>
      </div>
    </div>
  </div>
</template>

<script>

import {postApi} from "@/common"

export default {
  name: "forward-detail",
  data() {
    return {
      groupName: '',
      webhookUrl: '',
      mode: 0,
      editGroup: null
    }
  },
  created() {
    var inputGroup = this.$route.params.group
    if (inputGroup) {
      this.mode = 1
      this.editGroup = inputGroup
      this.groupName = this.editGroup.name
      this.webhookUrl = this.editGroup.webhook
    }
  },
  methods: {
    goBack () {
        this.$router.back(-1)
    },
    onSaveNewGroup () {
      var self = this
      if (this.groupName.trim() === '') {
        this.$message.error('工作群名称不可为空')
        return
      } else if (this.webhookUrl.trim() === '') {
        this.$message.error('Webhook地址不可为空')
        return
      }

      postApi(this.apiBase + '/camera/forward/addOne', { teamId: this.$store.state.teamId, webhook: self.webhookUrl, name: self.groupName }, 
        function (data) {
          if (Number(data.success) < 11) {
            self.goBack()
            self.$message.info('添加工作群成功')
          } else {
            self.$message.error(data.message);
          }
      })
    },
    onDeleteGroup () {
      const self = this;
      this.$confirm("确认删除该工作群？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        postApi(
            this.apiBase + "/camera/forward/delete",
            {
              teamId: this.$store.state.teamId,
              robotId: this.editGroup.id
            },
            function (res) {
              if (res.success === "1") {
                self.$message.info("删除工作群成功")
                self.goBack()
              } else {
                self.$message.error(res.message)
              }
            }
        )
      })
    }
  }
}
</script>

<style scoped lang="scss">
.forward-inner {
height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 19px;

  .header {
    height: 80px;
    padding: 0 20px;
    background-color: #F7F7F7;

    .navi-title {
        height: 80px;
        display: flex;
        align-items: center;
        color: #666;
    }
  }

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    .content-header {
      height: 32px;
      margin-top: 17px;
      margin-left: 20px;
      font-size: 14px;
      line-height: 19px;

      .add-group-btn {
        width: 160px;
        height: 32px;
        background-color: #0A8DF2;
        border-radius: 2px;
        box-sizing: border-box;
        border: solid 1px #0A8DF2;
        color: #FFF;
        cursor: pointer;
      }
    }

    .webhook-link {
      margin-left: 13px;
      width: 200px;
      color: #0A8DF2;
      cursor: pointer;
    }

    .group-name {
      width: 415px;
      height: 60px;
      margin-top: 20px;
    }

    .webhook-address {
      width: 415px;
      height: 160px;
      margin-top: 20px;
    }

    .new-group-btn {
      width: 300px;
      height: 32px;
      margin-left: 57.5px;
      margin-top: 30px;
      background-color: #0A8DF2;
      border-radius: 2px;
      box-sizing: border-box;
      border: solid 1px #0A8DF2;
      color: #FFF;
      cursor: pointer;
    }

    .operations {
      margin-top: 30px;
      width: 415px;
      display: flex;
      justify-content: space-between;

      .edit-group-btn {
        width: 200px;
        height: 32px;
        background-color: #0A8DF2;
        border-radius: 2px;
        box-sizing: border-box;
        border: solid 1px #0A8DF2;
        color: #FFF;
        cursor: pointer;
      }

      .delete-group-btn {
        width: 200px;
        height: 32px;
        background-color: #FF6668;
        border-radius: 2px;
        box-sizing: border-box;
        border: solid 1px #FF6668;
        color: #FFF;
        cursor: pointer;
      }
    }
  }
}
</style>
