<template>
  <div class="body" v-loading="$store.state.loading">
    <img class="left-bg" src="../assets/images/login_left_bg.png" />
    <div class="right">
      <div class="login-wx" v-show="isWXLogin">
        <!-- <span class="title">微信扫码登录<span class="subtitle">（仅限团队管理员登录）</span></span>
        <span>登录即代表你已阅读并同意<router-link class="desc" tag="a" target="_blank" :to="{name:'user-license'}">用户协议</router-link>和<router-link class="desc" tag="a" target="_blank" :to="{name:'privacy-policy'}">隐私政策</router-link></span> -->
        <!-- <img class="qrcode" src="../assets/images/qrdemo.png" /> -->
        <!-- <div id="wx_qrcode" :class="[isWXLogin ? 'qrcode' : 'qrcode-hidden']"></div> -->
        <div id="wx_qrcode" class="qrcode"></div>
        <div class="split-line">
          <div class="line"></div>
          <span class="line-desc">更多的登录方式</span>
          <div class="line"></div>
        </div>
        <button class="switch-to-tel-btn" @click="isWXLogin = !isWXLogin">手机号登录</button>
      </div>
      <div class="login-tel" v-show="!isWXLogin">
        <span class="title">登录管理后台<span class="subtitle">（仅限团队管理员登录）</span></span>
        <span>登录即代表你已阅读并同意<router-link class="desc" tag="a" target="_blank" :to="{name:'user-license'}">用户协议</router-link>和<router-link class="desc" tag="a" target="_blank" :to="{name:'privacy-policy'}">隐私政策</router-link></span>
        <div class="form-item">
          <div class="mobile-prefix">+86</div>
          <div class="deli"></div>
          <input type="text" v-model="mobile" placeholder="手机号">
        </div>
        <div class="form-item">
          <input type="text" class="code-input" v-model="code" placeholder="验证码">
          <div class="code-btn" :disabled="disabled" @click="sendCode" :style="{cursor:disabled?'not-allowed':'pointer'}">
            {{ sendTxt }}
          </div>
        </div>
        <button :disabled="loginDisabled" :class="loginDisabled ? 'login-btn-disabled':'login-btn'" @click="doLogin">
          登录
        </button>
        <div class="split-line">
          <div class="line"></div>
          <span class="line-desc">更多的登录方式</span>
          <div class="line"></div>
        </div>
        <button class="switch-to-wx-btn" @click="isWXLogin = !isWXLogin" style="line-height:45px;">
          <div class="inner">
            <img src="../assets/images/wx-icon.png" class="wx"/>
            <span >微信登录</span>
            </div>
            </button>
      </div>
    </div>
    <div class="login-box">
      <img class="logo" @click="$router.push('/')" src="../assets/images/logo.png" alt="Logo"/>
      <div class="title">工作蜂管理后台</div>
      <div class="form-item">
        <div class="mobile-prefix">+86</div>
        <div class="deli"></div>
        <input type="text" v-model="mobile" placeholder="手机号">
      </div>
      <div class="form-item">
        <input type="text" class="code-input" v-model="code" placeholder="验证码">
        <div class="code-btn" :disabled="disabled" @click="sendCode" :style="{cursor:disabled?'not-allowed':'pointer'}">
          {{ sendTxt }}
        </div>
      </div>
      <button :disabled="loginDisabled" :class="loginDisabled ? 'login-btn-disabled':'login-btn'" @click="doLogin">
        登录
      </button>
      <div class="weixin-box">
        <img src="../assets/images/weixin_gray.png" alt="微信"/>
        <div>微信登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getApi, isLogin, postApi} from '@/common'
import router from "@/router";
import $ from 'jquery';

export default {
  name: "Login",
  data() {
    return {
      sendTxt: "获取验证码",
      timeout: 60,
      disabled: false,
      mobile: '',
      code: '',
      isWXLogin: false,
      wx_state: ''
    };
  },
  computed: {
    loginDisabled() {
      return !this.code || !this.mobile
    }
  },
  created() {
    const islogin = isLogin()
    if (islogin) {
      router.push({
        path: '/teams'
      })
    }
  },
  mounted () {
    console.log('打印路由：')
    console.log(this.$route)
    if (this.$route.params.code) {
      console.log('获取到code')
      this.fetchWXLoginResult()
    }else {
      console.log('没有获取到code')
      this.generateWXQrcode()
    }
  },
  // watch: {
  // '$route' (to, from) {
  //     if (to.params.code !== undefined && from.fullPath === '/login' && to.path === '/login') {
  //       this.fetchWXLoginResult()
  //     }
  //   }
  // },
  methods: {
    sendCode() {
      if (this.disabled) {
        return;
      }
      const that = this;
      console.log("发送验证码：", this.mobile);
      if (this.mobile.trim() === "") {
        this.$message.error('手机号码不能为空')
        return;
      }
      this.disabled = true;
      const sendInterval = setInterval(function () {
        if (that.timeout > 0) {
          that.sendTxt = that.timeout + "秒后重发";
          that.timeout--;
        } else {
          clearInterval(sendInterval);
          that.sendTxt = "获取验证码";
          that.timeout = 60;
          that.disabled = false;
        }
      }, 1000);
      getApi(this.apiBase + '/camera/admin/smscode?mobile=' + this.mobile,
        function (data) {
          console.log("验证码结果：", data);
          if (data.success === "1") {
            that.$message.success('验证码发送成功，请注意查收')
          } else {
            that.$message.error(data.message);
          }
        })
    },
    doLogin() {
      const that = this
      console.log("点击登录：", this.mobile, this.code);
      if (this.mobile.trim() === "") {
        this.$message.error('手机号码不能为空')
        return;
      }
      if (this.code.trim() === "") {
        this.$message.error('验证码不能为空')
        return;
      }
      that.$store.commit('showLoading')
      postApi(this.apiBase + '/camera/admin/login', {
        mobile: this.mobile,
        code: this.code,
      }, function (data) {
        console.log("登录结果：", data);
        if (data.success === "1") {
          const userInfo = data.content;
          localStorage.setItem('camera_admin_user_info', JSON.stringify(userInfo));
          localStorage.setItem('camera_admin_user_token', data.content.token);
          localStorage.setItem('camera_admin_user_expire_at', String(data.content.times));
          //初始化团队列表
          if (userInfo.teams && userInfo.teams.length > 0) {
            const tmpTeams = userInfo.teams;
            tmpTeams.forEach(function (team) {
              team.active = false
              team.shortName = team.name
              if (team.name.length > 4) {
                team.shortName = team.name.substring(0, 4)
              }
            })
            that.$store.commit('syncTeams', tmpTeams)
          }
          that.$message.info({
            message: '登录成功，前往团队页面',
            onClose: function () {
              that.$store.commit('hideLoading')
              that.$router.push({
                path: '/teams'
              })
            }
          })
        } else {
          that.$message.error({
            message: data.message,
            onClose: function () {
              that.$store.commit('hideLoading')
            }
          });
        }
      })
    },
    generateWXQrcode () {
      this.wx_state = (Math.random() * 10000 + Date.parse(new Date()) + Math.random() * 20000).toString()
      console.log(this.wx_state)
      /* eslint-disable */
      new WxLogin({
        self_redirect: false,
        id: "wx_qrcode",
        appid: "wxc1610e9f890e1922",
        scope: "snsapi_login",
        redirect_uri: encodeURIComponent('https://www.dakaxiangji.cn/#/wxlogin'),
        state: this.wx_state,
        style: "black",
        href: "",
        debug: true
      })
      // encodeURIComponent('https://www.dakaxiangji.cn/admintest/#/wxlogin'),
  
      // this.fetchWXLoginResult()
    },
    fetchWXLoginResult () {
      var self = this
      $.ajax(this.apiBase + '/camera/adminwechat/login', {
        method: 'POST',
        dataType: 'json',
        data: { state: this.$route.params.state, code: this.$route.params.code },
        success: function (data) {
          if (data.success === "1") {
          const userInfo = data.content;
          localStorage.setItem('camera_admin_user_info', JSON.stringify(userInfo));
          localStorage.setItem('camera_admin_user_token', data.content.token);
          localStorage.setItem('camera_admin_user_expire_at', String(data.content.times));
          //初始化团队列表
          if (userInfo.teams && userInfo.teams.length > 0) {
            const tmpTeams = userInfo.teams;
            tmpTeams.forEach(function (team) {
              team.active = false
              team.shortName = team.name
              if (team.name.length > 4) {
                team.shortName = team.name.substring(0, 4)
              }
            })
            self.$store.commit('syncTeams', tmpTeams)
          }
          self.$message.info({
            message: '登录成功，前往团队页面',
            onClose: function () {
              self.$store.commit('hideLoading')
              self.$router.push({
                path: '/teams'
              })
            }
          })
        } else {
          self.$message.error(res.message)
        }
        }
      })
      // postApi(this.apiBase + '/camera/adminwechat/webLogin', { state: this.wx_state }, function (data) {
      //   if (data.success === "1") {
      //     const userInfo = data.content;
      //     localStorage.setItem('camera_admin_user_info', JSON.stringify(userInfo));
      //     localStorage.setItem('camera_admin_user_token', data.content.token);
      //     localStorage.setItem('camera_admin_user_expire_at', String(data.content.times));
      //     //初始化团队列表
      //     if (userInfo.teams && userInfo.teams.length > 0) {
      //       const tmpTeams = userInfo.teams;
      //       tmpTeams.forEach(function (team) {
      //         team.active = false
      //         team.shortName = team.name
      //         if (team.name.length > 4) {
      //           team.shortName = team.name.substring(0, 4)
      //         }
      //       })
      //       self.$store.commit('syncTeams', tmpTeams)
      //     }
      //     that.$message.info({
      //       message: '登录成功，前往团队页面',
      //       onClose: function () {
      //         self.$store.commit('hideLoading')
      //         self.$router.push({
      //           path: '/teams'
      //         })
      //       }
      //     })
      //   } else {
      //     self.fetchWXLoginResult()
      //   }
      // })
    }
  }
}
</script>

<style scoped lang="scss">

.body {
  height: 100%;
  display: flex;
  font-size: 16px;
  line-height: 21px;
  // align-items: center;

  .left-bg {
    height: 100%;
    // width: 500px;
  }

  .right {
    flex-grow: 1;
    display: flex;
    justify-content: center;

    .login-wx {
      margin-top: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .subtitle {
        font-size: 16px;
        line-height: 21px;
      }

      .desc {
        color: #0a8df2;
        // cursor: pointer;
        text-decoration:none;
      }

      .qrcode {
        width: 300px;
        height: 400px;
        margin-top: 15px;
      }

      .qrcode-hidden {
        display: none;
      }

      .split-line {
        margin-top: 5px;
        width: 100%;
        height: 19px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .line {
          width: 100px;
          height: 0.5px;
          background-color: #9B9A9A;
        }

        .line-desc {
          font-size: 14px;
          line-height: 19px;
          color: #9B9A9A;
        }
      }

      .switch-to-tel-btn {
        width: 100%;
        height: 45px;
        border-radius: 5px;
        background-color: #FFF;
        border: solid 1px #E4E4E4;
        cursor: pointer;
        margin-top: 22px;
      }
    }

    .login-tel {
      margin-top: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .subtitle {
        font-size: 16px;
        line-height: 21px;
      }

      .desc {
        color: #0a8df2;
        // cursor: pointer;
        text-decoration:none;
      }

      .form-item {
      display: flex;
      width: 100%;
      // align-items: center;
      height: 45px;
      border: 1px solid #e4e4e4;
      border-radius: 5px;
      background: #ffffff;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;

      .mobile-prefix {
        font-size: 14px;
        margin-left: 10px;
        margin-right: 10px;
        line-height: 45px;
        width: 28px;
        height: 45px;
        float: left;
      }

      .deli {
        width: 1px;
        height: 45px;
        background: #e4e4e4;
        margin-right: 10px;
        float: left;
      }

      input {
        flex: 1;
        border: none;
        font-size: 14px;
        float: left;
        height: 20px;
        margin-top: 11.5px;
      }

      .code-input {
        padding-left: 10px;
        width: 228px;
        float: left;
      }

      .code-btn {
        width: 80px;
        height: 20px;
        font-size: 14px;
        color: #0a8df2;
        margin-top: 11.5px;
        margin-left: 0px;
        margin-right: 10px;
        cursor: pointer;
        float: left;
        text-align: right;
      }
    }


    .login-btn-disabled {
      width: 100%;
      height: 45px;
      margin-top: 22px;
      background-color: #0a8df2;
      border-radius: 2px;
      opacity: 0.3;
      border: none;
      font-size: 16px;
      color: #ffffff;
    }

    .login-btn {
      width: 100%;
      height: 45px;
      margin-top: 22px;
      background-color: #0a8df2;
      border-radius: 2px;
      border: none;
      font-size: 16px;
      color: #ffffff;
    }

      .split-line {
        margin-top: 20px;
        width: 100%;
        height: 19px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .line {
          width: 100px;
          height: 0.5px;
          background-color: #9B9A9A;
        }

        .line-desc {
          font-size: 14px;
          line-height: 19px;
          color: #9B9A9A;
        }
      }

      .switch-to-wx-btn {
        width: 100%;
        height: 45px;
        border-radius: 5px;
        background-color: #FFF;
        border: solid 1px #E4E4E4;
        cursor: pointer;
        margin-top: 22px;

        .inner {
          display: flex;
          align-items: center;
          justify-content: center;

          .wx {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .login-box {
    // margin: 0 auto;
    // float: left;
    width: 423px;
    height: 400px;
    margin: calc(50vh - 200px) auto;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    // align-items: center;
    box-shadow: 0 8px 28px 0 rgba(115, 133, 169, 0.13);
    border-radius: 8px;
    display: none;

    .logo {
      margin-left: 167px;
      width: 89px;
      height: 89px;
      border-radius: 10px;
      position: relative;
      margin-top: -45px;
    }

    .title {
      // width: 162px;
      height: 28px;
      font-size: 20px;
      margin-top: 20px;
      text-align: center;
    }

    .form-item {
      display: flex;
      width: 338px;
      // align-items: center;
      height: 45px;
      border: 1px solid #e4e4e4;
      border-radius: 5px;
      background: #ffffff;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;

      .mobile-prefix {
        font-size: 14px;
        margin-left: 10px;
        margin-right: 10px;
        line-height: 45px;
        width: 28px;
        height: 45px;
        float: left;
      }

      .deli {
        width: 1px;
        height: 45px;
        background: #e4e4e4;
        margin-right: 10px;
        float: left;
      }

      input {
        flex: 1;
        border: none;
        font-size: 14px;
        float: left;
        height: 20px;
        margin-top: 11.5px;
      }

      .code-input {
        padding-left: 10px;
        width: 228px;
        float: left;
      }

      .code-btn {
        width: 80px;
        height: 20px;
        font-size: 14px;
        color: #0a8df2;
        margin-top: 11.5px;
        margin-left: 0px;
        margin-right: 10px;
        cursor: pointer;
        float: left;
        text-align: right;
      }
    }


    .login-btn-disabled {
      width: 338px;
      height: 45px;
      margin-top: 22px;
      background-color: #0a8df2;
      border-radius: 2px;
      opacity: 0.3;
      border: none;
      font-size: 16px;
      color: #ffffff;
      margin-left: 42.5px;
    }

    .login-btn {
      width: 338px;
      height: 45px;
      margin-top: 22px;
      background-color: #0a8df2;
      border-radius: 2px;
      border: none;
      font-size: 16px;
      color: #ffffff;
      margin-left: 42.5px;
    }

    .weixin-box {
      display: flex;
      flex-direction: column;
      // align-items: center;
      margin-top: 20px;

      img {
        width: 41px;
        height: 41px;
        margin-left: 191px;
        margin-right: 191px;
      }

      div {
        width: 49px;
        height: 17px;
        font-size: 12px;
        color: #707070;
        margin-top: 8px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
</style>
