<template>
  <div class="about-inner">
    <div class="first-row">
      <img class="about-bg" src="../../../img/mobile/banner_guanyuwome.png">
      <span>公司介绍</span>
      <div class="company-desc">工作蜂是由北京自由视觉科技有限公司研发出品，公司简称“自由视觉”于 2020 年 6 月成立，是一家面向智能化的软件服务平台。旗下业务覆盖工具、商务办公等领域，核心产品包括工作蜂、工程水印相机等。
        <br/>自由视觉的核心战略是专注“智能化”。获得过中关村高新技术企业认证。我们的使命是“以技术为基础，为工作赋能，提高效率”。我们旨在为4亿蓝领用户提供数字化办公服务，成为一个中国互联网界极具特色的科技企业。</div>
      <span>权威认证</span>
      <div class="desc">
        <div>
          <img src="../../../img/pc/home/gongzheng_pic.png"/>
          <p>公证处公正</p>
          <p>时间无法修改</p>
        </div>
        <div>
          <img src="../../../img/pc/home/zhongugancun.png"/>
          <p>中关村高新技术企业认证</p>
          <p>技术创新</p>
        </div>
        <div>
          <img src="../../../img/pc/home/guojiabanquanju.png"/>
          <p>国家版权局</p>
          <p>软件著作权登记证书</p>
        </div>
        <div>
          <img src="../../../img/pc/home/zhuanli.png"/>
          <p>国家知识产权局</p>
          <p>实用新型专利证书</p>
        </div>
      </div>
    </div>
    <div class="second-row">
      <div class="second-sub-row">
        <div class="subtitle">联系我们</div>
        <div class="intro-list">
          <div>
            <img class="about-icon" src="../../../img/pc/home/about_wx.png" />
            <div>
              <p>微信号：</p>
              <p>dakaxiangji01</p>
            </div>
            <img class="about-qr" src="../../../img/pc/home/about_qr.png" />
          </div>
          <div style="margin-top:11px;">
            <img class="about-icon" src="../../../img/pc/home/about_email.png" />
            <div>
              <p>邮箱号：</p>
              <p>dakaxiangji@126.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PubSub from 'pubsub-js'

export default {
  name: "About",
  mounted () {
    PubSub.publish('changeHeaderIndex', 3)
    PubSub.publish('menuName', '关于我们')
  }
}

</script>

<style scoped lang="scss">

.about-inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  .first-row {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 17px;
    box-sizing: border-box;

    .about-bg {
      width: 100%;
      margin-bottom: 22px;
    }

    span {
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      color: #000000;
      line-height: 26px;
    }

    .company-desc {
      text-indent: 2em;
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      line-height: 20px;
      margin-top: 15px;
      margin-bottom: 54px;
    }

    .desc {
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-align: center;
      padding: 30px 30px 50px 20px;
      box-sizing: border-box;
      grid-row-gap: 30px;
      grid-column-gap: 10px;

      img {
        margin-bottom: 13px;
        width: 100%;
      }

      p {
        margin: 0;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        color: #000000;
        line-height: 16px;
      }
    }
  }

  .second-row {
    background-color: #f4f4f4;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0 30px 0;
    box-sizing: border-box;
    margin-bottom: 75px;

    .second-sub-row {
      display: flex;
      flex-direction: column;
      width: 100%;

      .subtitle {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        line-height: 26px;
      }

      .intro-list {
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 17px;
        box-sizing: border-box;

        div {
          display: flex;
          background: #ffffff;
          border-radius: 3px;
          align-items: center;
          height: 84px;
          width: 100%;

          div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          }

          .about-icon {
            width: 50px;
            height: 50px;
            margin-left: 17px;
            margin-right: 15px;
          }

          .about-qr {
            width: 73px;
            height: 73px;
            margin-right: 7px;
            margin-left: auto;
            flex-shrink: 0;
          }

          p {
            margin: 0;
            text-align: left;
          }

          p:nth-of-type(1) {
            font-size: 15px;
            font-weight: 500;
            line-height: 21px;
          }

          p:nth-of-type(2) {
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
          }
        }
      }
    }
  }
}

</style>
