<template>
  <div class="header">
    <img class="logo" @click="$router.push('/')" src="../assets/images/logo.png" alt="Logo">
    <div class="breadcrumb">
      <span class="item">工作蜂</span>
      <span class="deli">|</span>
      <span class="item">{{ nav }}</span>
    </div>
    <el-dropdown @command="handleCommand">
      <div class="account">
        <img class="avatar" :src="userInfo.portrait" alt="头像">
        <span class="name">{{ userInfo.nickname }}</span>
        <img class="down" src="../assets/images/down.png" alt="下拉">
      </div>
      <el-dropdown-menu slot="dropdown" style="text-align: right">
        <el-dropdown-item v-show="showSwitchButton" command="exchange" style="display: flex;align-items: center;font-size: 14px"><img
            src="../assets/images/exchange.png" style="width: 14px;margin-right: 10px" alt="切换">切换团队
        </el-dropdown-item>
        <el-dropdown-item command="logout" style="display: flex;align-items: center;font-size: 14px"><img
            src="../assets/images/logout.png" style="width: 14px;margin-right: 10px" alt="退出">退出登录
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import {postApi} from '@/common'
import logo from '@/assets/images/logo.png'

export default {
  name: "Headers",
  props: ["nav"],
  data() {
    return {
      userInfo: {},
      showSwitchButton: true
    }
  },
  created() {
    if (this.$route.path === '/teams') {
      this.showSwitchButton = false
    } else {
      this.showSwitchButton = true
    }
    let userInfo = localStorage.getItem('camera_admin_user_info');
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
      console.log('当前登录的用户信息：', userInfo)
      if (!userInfo.portrait) {
        userInfo.portrait = logo
      }
      this.userInfo = userInfo
    }
  },
  methods: {
    handleCommand(command) {
      if (command === 'logout') {
        this.logout();
      } else if (command === 'exchange') {
        this.$router.push({
          path: '/teams'
        })
      }
    },
    logout() {
      const that = this
      console.log('用户点击退出系统')
      this.$confirm('您确认要退出系统?', '退出确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.removeItem('camera_admin_user_info')
        localStorage.removeItem('camera_admin_user_token')
        localStorage.removeItem('camera_admin_user_expire_at')
        postApi(this.apiBase + '/camera/admin/signout', {
          token: this.userInfo.token
        }, function () {
          that.$router.push({
            path: '/login'
          })
        })
      });
    }
  }
}
</script>

<style scoped lang="scss">

.header {
  width: 100%;
  height: 62px;
  // position: fixed;
  top: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.07);
  border: none;
  z-index: 1000;

  .logo {
    height: 30px;
    margin-left: 24px;
    border-radius: 3px;
  }

  .breadcrumb {
    display: flex;
    flex: 1;
    font-size: 20px;
    color: #1f1f1f;
    margin-left: 10px;
    align-items: center;

    .item {
      margin-right: 10px;
    }

    .deli {
      margin-right: 10px;
      font-size: 16px;
    }
  }
}


.account {
  display: flex;
  align-items: center;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .name {
    font-size: 14px;
    color: #1f1f1f;
    margin-right: 10px;
  }

  .down {
    width: 10px;
    margin-right: 24px;
  }
}
</style>
