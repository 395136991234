<template>
  <div class="user-manage-inner">
    <div class="header">
      <div class="title">成员管理</div>
      <div class="subtitle">删减成员，设置管理员</div>
    </div>
    <div class="content">
      <div class="search-bar">
        <input v-model="searchText" placeholder="请输入姓名或者手机号搜索" />
        <button @click="searchUser()">搜索</button>
      </div>
      <div class="searchResult">
        <div class="tableHeader">
            <div style="width:25%;">姓名</div><div style="width:25%;">手机号</div><div style="width:25%;">类型</div><div style="width:25%;">操作</div>
        </div>
        <div class="empty-data-info" v-if="showEmpty">未找到用户信息！</div>
        <div v-for="(item) in data" :key="item.id" class="row">
            <div class="logo">
                <img v-if="item.portrait" class="logo-image" :src="item.portrait + '?x-oss-process=image/resize,m_lfit,h_25,w_25'" />
                <!-- <div v-if="!item.logoUrl" class="logo-image" :style="{ backgroundColor: item.logoColour}" ></div> -->
                <div class="logo-name">{{ item.nickname }}</div>
            </div>
            <div class="people-number">{{item.mobile}}</div>
            <div class="team-code">{{convertUserRole(item.userRole)}}</div>
            <div class="detail" v-if="item.userRole !== 2">
              <button class="delete-user-btn" @click="onDeleteUser(item.id)">删除</button>
              <button class="set-admin-btn" v-if="item.userRole === 0" @click="onSetAdmin(item.id)">设为管理员</button>
              <button class="cancel-admin-btn" v-if="item.userRole === 1" @click="onCancelAdmin(item.id)">取消管理员</button>
            </div>
        </div>
        <el-pagination background layout="prev, pager, next" :total="totalNumber" @current-change="onPageIndexChange" hide-on-single-page></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>

import {getApi, postApi} from "@/common"

export default {
  name: "user-manage",
  data() {
    return {
      searchText: '',
      teamId: null,
      pageSize: 10,
      pageNum: 1,
      totalNumber: 0,
      data: [],
      showEmpty: false
    }
  },
  mounted() {
    var team = JSON.parse(localStorage.getItem('camera_admin_team'))
    this.teamId = team.id
    this.searchUser()
  },
  methods: {
    searchUser () {
      // if (this.searchText.length === 0) {
      //   this.$message.error('请输入姓名或者手机号搜索')
      //   return
      // }
      var self = this
      self.$store.commit('showLoading')
      getApi(this.apiBase + '/camera/admin/users?teamId=' + this.teamId + '&pageSize=' + this.pageSize + '&pageNum=' + this.pageNum + '&param=' + this.searchText, res => {
        self.$store.commit('hideLoading')
        if (res.success === '1') {
          self.data = res.content.list
          self.totalNumber = res.content.total
          if (res.content.total === 0) {
            self.showEmpty = true
          } else {
            self.showEmpty = false
          }
        } else {
          self.$message.error(res.message);
        }
      })
    },
    convertUserRole (role) {
      switch(role) {
        case 0:
          return '成员'
        case 1:
          return '管理员'
        case 2:
          return '群主'
      }
      return ''
    },
    onPageIndexChange (val) {
      this.pageNum = val
      this.searchText()
    },
    onDeleteUser (userId) {
      const that = this;
      this.$confirm("将成员移出团队？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        postApi(
            this.apiBase + "/camera/team/deleteOne",
            {
              teamId: this.teamId,
              userId: userId
            },
            function (res) {
              if (res.success === "1") {
                that.searchUser()
                that.$message.info("删除成员成功");
              } else {
                that.$message.error(res.message);
              }
            }
        )
      })
    },
    onSetAdmin (userId) {
      const that = this;
      this.$confirm("设置为团队管理员？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        postApi(
            this.apiBase + "/camera/team/admin",
            {
              teamId: this.teamId,
              userId: userId
            },
            function (res) {
              if (res.success === "1") {
                that.searchUser()
                that.$message.info("设置管理员成功");
              } else {
                that.$message.error(res.message);
              }
            }
        )
      })
    },
    onCancelAdmin (userId) {
      const that = this;
      this.$confirm("取消团队管理员资格？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        postApi(
            this.apiBase + "/camera/team/cancelAdmin",
            {
              teamId: this.teamId,
              userId: userId
            },
            function (res) {
              if (res.success === "1") {
                that.searchUser()
                that.$message.info("取消团队管理员资格成功");
              } else {
                that.$message.error(res.message);
              }
            }
        )
      })
    }
  }
}
</script>

<style scoped lang="scss">

.user-manage-inner {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    height: 80px;
    padding: 0 20px;
    background-color: #F7F7F7;

    .title {
      font-size: 16px;
      line-height: 21px;
      font-weight: 700;
      margin-top: 23px;
    }

    .subtitle {
      font-size: 14px;
      line-height: 19px;
      color: #666;
      margin-top: 7px;
    }
  }

  .content {
    flex-grow: 1;
    
    .search-bar {
      height: 32px;
      margin-top: 17px;
      margin-left: 20px;
      font-size: 14px;
      line-height: 19px;
    }

    .search-bar input {
      width: 357px;
      height: 32px;
      box-sizing: border-box;
      border: solid 1px #B9B9B9;
      padding: 0 12px;
    }

    .search-bar button {
      width: 80px;
      height: 32px;
      background-color: #0A8DF2;
      border-radius: 2px;
      box-sizing: border-box;
      border: solid 1px #0A8DF2;
      color: #FFF;
      margin-left: 20px;
      cursor: pointer;
    }

    .searchResult {
        font-size: 14px;
        margin: 20px 0;

        .tableHeader {
            height: 40px;
            color: #888888;
            background-color: #FFF;
            display: flex;
            align-items: center;
            padding: 0px 20px;
            margin-top: 11px;
        }

        .empty-data-info {
          font-size: 14px;
          line-height: 18px;
          font-weight: 700;
          margin-left: 20px;
          margin-top: 20px;
        }

        .row {
            height: 40px;
            color: #6a6a6a;
            background-color: #FFF;
            border-top: solid 1px #F2F5FB;
            display: flex;
            align-items: center;
            padding: 0px 20px;

            .logo {
                display: flex;
                flex-direction: row;
                // justify-content: center;
                align-items: center;
                line-height: 40px;
                width: 25%;

                .logo-image {
                    width: 25px;
                    height: 25px;
                    background-color: #D8D8D8;
                }

                .logo-name {
                    margin-left: 12px;
                    margin-right: auto;
                    height: 40px;
                    line-height: 40px;
                }
            }

            .people-number {
                width: 25%;
                // color: #5FA9FF;
                // text-decoration:underline;
            }

            .team-code {
                width: 25%;
            }

            .detail {
                width: 25%;
                font-size: 14px;
                line-height: 19px;
                span {
                    color: #5FA9FF;
                    cursor: pointer;
                }

                .delete-user-btn {
                  width: 50px;
                  height: 30px;
                  color: #FFF;
                  background-color: #FF6668;
                  border-radius: 2px;
                  box-sizing: border-box;
                  border: solid 1px #FF6668;
                  cursor: pointer;
                  margin-right: 20px;
                }

                .set-admin-btn {
                  width: 100px;
                  height: 30px;
                  color: #FFF;
                  background-color: #0A8DF2;
                  border-radius: 2px;
                  box-sizing: border-box;
                  border: solid 1px #0A8DF2;
                  cursor: pointer;
                }

                .cancel-admin-btn {
                  width: 100px;
                  height: 30px;
                  color: #FFF;
                  background-color: #747474;
                  border-radius: 2px;
                  box-sizing: border-box;
                  border: solid 1px #747474;
                  cursor: pointer;
                }
            }
        }
    }
  }
}

</style>
