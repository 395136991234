<template>
  <div></div>
</template>

<script>

// import {getApi} from "@/common"

export default {
  name: "wxlogin",
  data() {
    return {
    }
  },
  mounted() {
    if (this.$route.query.code) {
      this.$router.push({ name: 'login', params: { state: this.$route.query.state, code: this.$route.query.code } })
    } else {
      this.$router.push({ name: 'login'})
    }
  },
}
</script>

<style scoped lang="scss">

</style>
