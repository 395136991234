<template>
  <div class="case-page-inner">
    <div class="third-row">
      <div class="customer-case">
        <div class="case" style="background: #f4f4f4;">
          <img src="../../../img/pc/home/kehu_01.png"/>
          <div class="intro">
            <p>物业管理 | 某某物业服务</p>
            <p>用工作蜂管理1000人的团队</p>
            <p>以前团队考勤打卡拍照发微信群没办法实时汇总，工作内容也没有合理的监督审查，团队效率低、协作慢、工作不到位，导致物业服务得不到业主的认可。<br/>使用工作蜂之后，拍照实时打卡实时统计；员工实时到岗，工作内容和过程也得到了监督，提升了团队效率和协作，业主的认可度也提高了。</p>
          </div>
        </div>
        <div class="case">
          <img src="../../../img/pc/home/kehu_02.png"/>
          <div class="intro">
            <p>制造业 | 某某制造厂</p>
            <p>用工作蜂协助企业安全生产，更高效！</p>
            <p>制造厂在之前出现了好几次安全事故，导致生产停顿，影响了厂子的效益，员工日常安全检查时，记录不明， 安全问题没有得到及时解决。<br/>使用工作蜂之后，员工检查时遇到问题拍照标注上传， 时间、位置、问题都显示的很详细，上报并很快能定位问题原因，排除隐患，降低了企业安全事故率。</p>
          </div>
        </div>
        <div class="case" style="background: #f4f4f4;">
          <img src="../../../img/pc/home/kehu_03.png"/>
          <div class="intro">
            <p>家装服务 | 某某装饰公司</p>
            <p>打造数字化透明工程，让业主放心！</p>
            <p>装饰公司以前一直被业主投诉质疑，装修材料是不是造假了，故意拖延装修时间，导致业主们对公司的评价不好，收益下降。<br/>使用工作蜂之后，装修从设计到施工收房，每天施工环节员工都拍照拍视频上传至工作蜂与业主同步，业主可以通过小程序实时查看；装修的透明化受到了业主肯定和认可。</p>
          </div>
        </div>
        <div class="case">
          <img src="../../../img/pc/home/kehu_04.png"/>
          <div class="intro">
            <p>生态农业 | 某某农产品销售</p>
            <p>透明化生产过程，消费者更放心购买！</p>
            <p>90后创始人小新大学毕业，从事农产品生产。但是农产品一直不被消费者认可，被认定为不放心产品。<br/>使用工作蜂之后，农产品从生长到生产包装上市，每个环节都拍照记录，消费者通过查看码可以随时随地查看了解生产环境、生产过程等情况，快速的提高了品牌认可度，农产品的销量也大幅度提升。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PubSub from 'pubsub-js'

export default {
  name: "CustomerCase",
  mounted () {
    PubSub.publish('changeHeaderIndex', 2)
    PubSub.publish('menuName', '客户案例')
  }
}

</script>

<style scoped lang="scss">

.case-page-inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  .third-row {
    display: flex;
    flex-direction: column;
    align-items: center;

    .customer-case {
      display: flex;
      flex-direction: column;
      width: 100%;

      .case {
        display: flex;
        flex-direction: column;
        padding: 17px;
        box-sizing: border-box;

        img {
          width: 100%;
          margin-bottom: 14px;
        }

        .intro {
          display: flex;
          flex-direction: column;

          p {
            margin: 0px;
          }

          p:nth-of-type(1) {
            font-size: 15px;
            font-weight: 300;
            line-height: 21px;
          }

          p:nth-of-type(2) {
            font-size: 17px;
            font-weight: 600;
            line-height: 23px;
            margin-top: 7px;
            margin-bottom: 7px;
          }

          p:nth-of-type(3) {
            font-size: 13px;
            font-weight: 300;
            line-height: 19px;
          }
        }
      }
    }
  }
}

</style>
