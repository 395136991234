<template>
  <div class="picture-list">
    <div class="row-header">
      <el-checkbox v-model="checkAll" @change="handleCheckAll">
        {{
          $store.state.checkedPictureTotal > 0
              ? "已选中" + $store.state.checkedPictureTotal + "张照片"
              : "全选"
        }}
      </el-checkbox>
    </div>


    <div class="row-pics" @mouseenter="handleHover($event,-1)" style="margin-bottom:60px;"  v-if="$store.state.pictures.length > 0">
      <div
          @mouseenter="handleHover($event,pindexs)" @mouseout="handleHover($event,pindexs)"
          @click="handleClickItem($event, pindexs)"
          :class="pic.checked ? 'pic checked' : 'pic'"
          v-for="(pic, pindexs) in $store.state.pictures"
          :key="pic.id"
      >
        <div class="checked" v-show="pic.checked">
            <img id="correct" style="user-select: none;" src="../../assets/images/unchecked.png"/>
          </div>
          <div class="unchecked" v-show="!pic.checked && hoverIndex === pindexs" @mouseenter="handleHover($event,pindexs)"
               @mouseout="handleHover($event,pindexs)">
          </div>
        <el-image class="image" :src="pic.url+'?x-oss-process=image/resize,m_fill,w_120,h_160'"  lazy
              @click="viewPicList(pindexs)">
               <div slot="error" class="image-slot">
              <img style="height: 160px;width: 120px;" src="../../assets/images/default_gongzuofeng.png" @click="gop()"/>
            </div>
              </el-image>
        <div class="nickname" @mouseenter="handleHover($event,pindexs)" @mouseout="handleHover($event,pindexs)">
          {{ pic.nickname }}
        </div>
        <div class="time" @mouseenter="handleHover($event,pindexs)" @mouseout="handleHover($event,pindexs)">
          {{ new Date(pic.imageTime).format("yyyy-MM-dd hh:mm") }}
        </div>
        <div class="position" @mouseenter="handleHover($event,pindexs)" @mouseout="handleHover($event,pindexs)">
          {{ pic.position }}
        </div>
      </div>

      <el-image-viewer v-if="isShowPicList" :initialIndex="picStartIndex" :on-close="onClosePicList" :url-list="picList" />

    </div>



  </div>
</template>

<script>

import PubSub from 'pubsub-js'

export default {
  name: "PictureThumbList",
  components: {
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  data() {
    return {
      checkAll: false,
      hoverIndex: -1,
      isShowPicList: true,
      picList: [],
      picStartIndex: 0,
    };
  },
  watch: {
    "$store.state.pictures": function () {
      this.checkAll = false
    },
  },
  mounted () {
    var self = this
    this.isShowPicList = false
    PubSub.subscribe('closePicList', ()=>{
      self.isShowPicList = false
      self.$store.commit("hideBottomBar")
    })
  },
  methods: {
    handleHover(evt, index) {
      this.hoverIndex = index
    },
    handleCheckAll(check) {
      console.log("全选状态：", check);
      this.$store.commit("checkAllPictures", check);
      this.calcCheckedStatus();
    },
    handleClickItem(evt, index) {
      const that = this
      const targetNode = evt.target.nodeName;
      console.log("点击了图片Item：", index, evt.target.nodeName);
      if (targetNode !== "IMG" || (evt.target.id && evt.target.id === 'correct')) {
        this.$store.commit("checkOnePicture", index);
        this.calcCheckedStatus();
      } else {
        this.$store.commit('showLoading')
        this.$store.commit("showBottomBar");
        setTimeout(function () {
          that.$store.commit('hideLoading')
        }, 1000)
      }
    },
    calcCheckedStatus() {
      //全选状态
      let checkAll = true;

      this.$store.state.pictures.forEach((pic) => {
        if (!pic.checked) {
          checkAll = false;
        }
      });
      this.checkAll = checkAll;
    },
    gop(e) {
      let that = this
      e && e.stopPropagation()
      window.open('/img/default_gongzuofeng.e507ad12.png')
      setTimeout(()=>{
        that.isShowPicList = false
        that.$store.commit("hideBottomBar");
      },1000)
    },
    onPreview () {
      var self = this
      var index = 0
      self.$store.commit("syncPicIndex", index)
      this.$nextTick(()=>{
        let domImageMask = document.querySelector(".el-image-viewer__mask")
        let closeButton = document.querySelector(".el-image-viewer__close")
        let canvas = document.querySelector('.el-image-viewer__canvas img')
        let nextButton = document.querySelector('.el-image-viewer__next')
        let prevButton = document.querySelector('.el-image-viewer__prev')
        if (!domImageMask || !closeButton) {
          return
        }
        domImageMask.addEventListener("click", () => {
            document.querySelector(".el-image-viewer__close").click();
            self.$store.commit("hideBottomBar")
        })
        closeButton.addEventListener("click", () => {
            self.$store.commit("hideBottomBar")
        })

        if (nextButton) {
        nextButton.addEventListener("click", () => {
            var canvas1 = document.querySelector('.el-image-viewer__canvas img')
            canvas1.src = canvas1.src.replace('?x-oss-process=style/style150', '')
        })
        }

        if (prevButton){
                  prevButton.addEventListener("click", () => {
                    var canvas1 = document.querySelector('.el-image-viewer__canvas img')
                    canvas1.src = canvas1.src.replace('?x-oss-process=style/style150', '')
                })
        }

        canvas.src = canvas.src.replace('?x-oss-process=style/style150', '')
      })
    },
    viewPicList (photoIndex) {
      var self = this
      this.picList = this.$store.state.pictures.map(t => t.url + '?x-oss-process=image/resize,m_lfit,h_160,w_120')
      this.picStartIndex = photoIndex
      this.isShowPicList = true
      this.$nextTick(()=>{
        let domImageMask = document.querySelector(".el-image-viewer__mask")
        let closeButton = document.querySelector(".el-image-viewer__close")
        let canvas = document.querySelector('.el-image-viewer__canvas img')
        let nextButton = document.querySelector('.el-image-viewer__next')
        let prevButton = document.querySelector('.el-image-viewer__prev')
        if (!domImageMask || !closeButton) {
          return
        }
        domImageMask.addEventListener("click", () => {
            document.querySelector(".el-image-viewer__close").click();
            self.$store.commit("hideBottomBar")
        })
        closeButton.addEventListener("click", () => {
            self.$store.commit("hideBottomBar")
            // self.isShowPicList = false
        })

        if (nextButton) {
        nextButton.addEventListener("click", () => {
            var canvas1 = document.querySelector('.el-image-viewer__canvas img')
            canvas1.src = canvas1.src.replace('?x-oss-process=image/resize,m_lfit,h_160,w_120', '')
        })
        }

        if (prevButton){
                  prevButton.addEventListener("click", () => {
                    var canvas1 = document.querySelector('.el-image-viewer__canvas img')
                    canvas1.src = canvas1.src.replace('?x-oss-process=image/resize,m_lfit,h_160,w_120', '')
                })
        }

        canvas.src = canvas.src.replace('?x-oss-process=image/resize,m_lfit,h_160,w_120', '')
      })
    },
    onClosePicList () {
      this.isShowPicList = false
    }
  },
};
</script>

<style scoped lang="scss">
.picture-list {
  margin-left: 25px;

  .row-header {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #6a6a6a;
    height: 40px;
    width: 100%;
  }

  .row-pics {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #6a6a6a;
    width: 100%;
    flex-wrap: wrap;
    margin-top: -10px;

    .pic.checked {
      background: #f1f5fb;
    }

    .pic.checked:hover {
      background: #f1f5fb;
    }

    .pic:hover {
      background: #f5f5f5;
    }

    .pic {
      display: flex;
      flex-direction: column;
      width: 180px;
      margin-left: 10px;
      align-items: center;
      position: relative;
      margin-top: 20px;
      padding: 15px 10px;
      cursor: pointer;

      .unchecked {
        width: 15px;
        height: 15px;
        background: #fff;
        border-radius: 50%;
        border: 1px solid #949494;
        display: flex;
        align-items: center;
        padding: 3px;
        position: absolute;
        right: 0px;
        top: 10px;
      }

      .checked {
        width: 15px;
        height: 15px;
        background: #2298f3;
        border-radius: 50%;
        display: flex;
        align-items: center;
        padding: 3px;
        position: absolute;
        right: 0px;
        top: 10px;

        img {
          width: 10px;
          height: 10px;
          margin: 0;
          padding: 0;
        }
      }

      .search {
        position: absolute;
        right: 40px;
        top: 170px;

        img {
          width: 18px;
          height: 18px;
          margin: 0;
          padding: 0;
          cursor: pointer;
        }
      }

      .image {
        border-radius: 2px;
        width: 120px;
        height: 160px;
        object-fit: none;
      }

      .image:hover {
        cursor: url("../../assets/images/search.ico"), auto;
      }

      // img:hover {
      //   cursor: url("../../assets/images/search.ico"), auto;
      // }

      img {
        flex: 1;
        display: flex;
        align-items: center;
        margin-right: 10px;
        margin-left: 10px;
        height: 180px;
        width:180px;
        border-radius: 3px;
      }

      .nickname {
        text-align: center;
        margin-top: 5px;
        color: #666;
      }

      .time {
        text-align: center;
        font-size: 13px;
        color: #999;
      }

      .position {
        text-align: center;
        font-size: 13px;
        color: #999;
      }
    }
  }
}
</style>
