<template>
  <div class="body">
    <div class="actions">
      <img
          @click="switchType"
          class="type"
          v-if="$store.state.listType === 'item'"
          src="../assets/images/thumbs.png"
          title="展示缩略图"
          alt="缩略图"
      />
      <img
          @click="switchType"
          class="type"
          v-if="$store.state.listType === 'thumb'"
          src="../assets/images/items.png"
          title="展示列表"
          alt="列表"
      />
      <div
          class="nav"
          style="line-height:32px;"
          v-if="
          $store.state.folderLevel === 1 && $store.state.listLevel === 'folder'
        "
      >
        全部文件
      </div>
      <div class="search-bar" v-if="$store.state.category !== 'time' && $store.state.listLevel === 'folder'">
        <el-input v-model="searchText" placeholder="请输入名称搜索" style="margin-left: 15px"></el-input>
        <el-button type="primary" @click="searchPics()" size="small" style="margin-left: 15px">搜索</el-button>
      </div>
      <!-- <div
          class="nav"
          v-if="
          $store.state.folderLevel === 2 && $store.state.listLevel === 'folder'
        "
      >
        <div class="back" @click="gotoRootFolders">返回上一级</div>
        <span class="deli">|</span>
        <div class="all" @click="gotoRootFolders">全部文件</div>
        <div class="narrow">&gt;</div>
        <div class="current">{{ $store.state.previousFolder[0].name }}</div>
      </div> -->
      <div
          class="nav"
          v-if="
          $store.state.category === 'work' &&
          $store.state.listLevel === 'picture'
        "
      >
        <div class="back" @click="gotoParentFolders">返回上一级</div>
        <span class="deli">|</span>
        <div class="all" @click="gotoRootFolders">全部文件</div>
        <div class="narrow" v-if="$store.state.folderLevel === 2">&gt;</div>
        <div
            class="all"
            v-if="$store.state.folderLevel === 2"
            @click="gotoParentFolders"
        >
          {{ $store.state.previousFolder[0].name }}
        </div>
        <div class="narrow" v-if="$store.state.folderLevel === 2">&gt;</div>
        <div class="current" v-if="$store.state.folderLevel === 2">
          {{
            $store.state.previousFolder[$store.state.previousFolder.length - 1]
                .name
          }}
        </div>
      </div>
      <div
          class="nav"
          v-if="
          $store.state.category === 'member' &&
          $store.state.listLevel === 'picture'
        ">
        <div class="back" @click="gotoParentFolders">返回上一级</div>
        <span class="deli">|</span>
        <div class="all" @click="gotoParentFolders">全部文件</div>
        <div class="narrow">&gt;</div>
        <div class="current">
          {{
            $store.state.previousFolder[0]
                ? $store.state.previousFolder[0].nickname
                : ""
          }}
        </div>
      </div>
      <!-- 时间分类相关-->
      <span
          v-if="$store.state.category === 'time'"
          class="label"
      >选择日期：</span>
      <div style="margin-left: 15px" class="range" v-if="$store.state.category === 'time' || ($store.state.category !== 'time' && $store.state.listLevel === 'picture')">
        <date-picker size="small" ref="dp" v-model="dateRange" @pick="handlePickDate" @change="handleRangeChange"
                     @focus="dateRangeOpen = true" :open="dateRangeOpen" @open="handleOpenRange"
                     range placeholder="起始日期    ~    结束日期" :disabled-date="disabledDate">
          <template v-slot:footer="{emit}">
            <div class="date-bottom">
              <div class="shortcuts">
                <div class="shortcut" @click="selectLatest7Days(emit)">过去7天</div>
                <div class="shortcut" @click="selectLatest30Days(emit)">过去30天</div>
                <div v-for="m in latest4Month" :key="m" class="shortcut" @click="selectMonthDate(emit,m)">{{ m }}月</div>
                <div class="close" @click="dateRangeOpen = false">
                  <div>&times;</div>
                </div>
              </div>
              <div class="tip">
                因文件大小限制，仅支持导出30天以内的照片
              </div>
            </div>
          </template>
        </date-picker>
      </div>
      <div class="search-bar" v-if="$store.state.category !== 'time' && $store.state.listLevel === 'picture'">
        <el-button type="primary" @click="onSearchPictureInFolder()" size="small" style="margin-left: 15px">搜索</el-button>
      </div>
      <el-button
          v-if="$store.state.category === 'time'"
          @click="searchTimePics"
          type="primary"
          size="small"
          style="margin-left: 15px"
      >搜索
      </el-button>
      <div
          v-if="
          $store.state.checkedPictureTotal > 0 &&
          $store.state.listLevel === 'picture'
        "
          class="checked-btns"
      >
        <el-button
            @click="deletePictures"
            type="danger"
            size="small"
            style="margin-left: 15px"
        >删除所选
        </el-button>
        <el-button
            @click="downloadPictures"
            type="primary"
            size="small"
            style="margin-left: 15px"
        >
          下载所选
        </el-button>
      </div>
      <div
          v-if="
          $store.state.checkedFolderTotal > 0 &&
          $store.state.listLevel === 'folder'
        "
          class="checked-btns"
      >
        <!-- <el-button
            @click="deleteFolders"
            type="danger"
            size="small"
            style="margin-left: 15px"
        >删除所选2
        </el-button> -->
        <el-button
            @click="downloadFolders"
            type="primary"
            size="small"
            style="margin-left: 15px"
            v-if="$store.state.category === 'work'"
        >
          下载所选
        </el-button>
      </div>
      <span
          v-if="
          !$store.state.checkedPictureTotal > 0 &&
          $store.state.listLevel === 'picture'
        "
          class="label-total"
      >已全部加载，共{{ $store.state.pictureTotal }}张照片</span
      >
      <span
          v-if="
          !$store.state.checkedFolderTotal > 0 &&
          $store.state.listLevel === 'folder'
        "
          class="label-total"
      >已全部加载，共{{ $store.state.folderTotal }}个文件夹</span
      >
    </div>
    <div class="list-box" @scroll="handleListScroll">
      <picture-item-list
          v-if="
          $store.state.listType === 'item' &&
          $store.state.listLevel === 'picture'
        "
      ></picture-item-list>
      <picture-date-thumb-list
          v-if="
          $store.state.listType === 'thumb' &&
          $store.state.category === 'time' &&
          $store.state.listLevel === 'picture'
        "
      ></picture-date-thumb-list>
      <picture-thumb-list
          v-if="
          $store.state.listType === 'thumb' &&
          $store.state.category !== 'time' &&
          $store.state.listLevel === 'picture'
        "
      ></picture-thumb-list>
      <folder-item-list
          v-if="
          $store.state.listType === 'item' &&
          $store.state.listLevel === 'folder'
        "
      ></folder-item-list>
      <folder-thumb-list
          v-if="
          $store.state.listType === 'thumb' &&
          $store.state.listLevel === 'folder'
        "
      ></folder-thumb-list>
      <div class="empty-tips" v-show="$store.state.total === 0 && $store.state.listLevel === 'picture'">未发现照片，请选择其他月份！</div>
      <el-pagination style="margin-bottom: 66px;margin-top: auto;"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[30, 60, 120, 200, 400, 500]"
        :page-size="pageSize"
        :current-page.sync="pageNum"
        :total="total"
        layout="sizes, total, prev, pager, next"
        background
        >
      </el-pagination>
    </div> 
    <!-- <div class="preview-btns" v-if="$store.state.showBottomBar">
      <button class="del-btn" @click="handleDeleteOne">删除</button>
      <button class="download-btn" @click="handleDownloadOne">下载</button>
    </div> -->
  </div>
</template>

<script>
import $ from 'jquery'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';
import {getApi, postApi} from "@/common";
import PictureItemList from "@/components/picture/PictureItemList";
import PictureDateThumbList from "@/components/picture/PictureDateThumbList";
import PictureThumbList from "@/components/picture/PictureThumbList";
import FolderThumbList from "@/components/folder/FolderThumbList";
import FolderItemList from "@/components/folder/FolderItemList";
import PubSub from 'pubsub-js'

export default {
  name: "Pictures",
  components: {
    FolderItemList,
    FolderThumbList,
    PictureThumbList,
    PictureDateThumbList,
    PictureItemList,
    DatePicker
  },
  data() {
    return {
      dateRange: [],
      initDate: 0,
      pickedDate: null,
      dateRangeOpen: false,
      latest4Month: [],
      pageSize: 30,
      pageNum: 1,
      total: 0,
      searchText: ''
    };
  },
  watch: {
    "$store.state.category": function (val) {
      this.searchText = ''
      this.pageSize = 30
      this.pageNum = 1
      if (val === 'time') {
        const end = new Date();
        const start = new Date();
        start.setDate(1)
        // start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
        this.dateRange = [start, end];
      }
    },
    "$store.state.listLevel": function () {
      this.pageSize = 30
      this.pageNum = 1
      const end = new Date();
      const start = new Date();
      start.setDate(1)
      // start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
      this.dateRange = [start, end];
    },
    "$store.state.total": function () {
      this.$nextTick(()=> {
        this.total = this.$store.state.total
      })
    }
  },
  created() {
    const end = new Date();
    const start = new Date();
    start.setDate(1)
    // start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
    this.dateRange = [start, end];
    for (var i = 12; i > 0; --i) {
      this.latest4Month.push(i)
    }
    this.$store.commit("hideBottomBar")
    this.searchPics();
  },
  mounted () {
    const that = this
    this.globalClick((e) => {
      const targetNameAttr = $(e.target).attr('name')
      if (!targetNameAttr || targetNameAttr !== 'date') {
        that.dateRangeOpen = false
      }
    });
    PubSub.subscribe('reloadPic', ()=>{
      that.searchPics()
    })
  },
  methods: {
    handleListScroll() {
      let _this = this
      let read = _this.$el.querySelector('.list-box')
      let rowHeaderEls = _this.$el.querySelectorAll('.row-header')
      let pinEl = null;
      rowHeaderEls.forEach((el) => {
        $(el).removeClass('fixed-date')
        if (read.scrollTop > (el.offsetTop - 60)) {
          pinEl = el
        }
      })
      $(pinEl).addClass('fixed-date')
    },
    handleOpenRange() {
      this.pickedDate = null
      if (!this.dateRange || this.dateRange.length < 2) {
        const end = new Date();
        const start = new Date();
        start.setDate(1)
        // start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
        this.dateRange = [start, end];
      }
    },
    disabledDate(date) {
      if (date.getTime() > new Date().getTime()) {
        return true;
      }
      if (this.pickedDate) {
        const startTime = this.pickedDate.getTime()
        const availStartTime = new Date(startTime - 3600 * 1000 * 24 * 29)
        const availEndTime = new Date(startTime + 3600 * 1000 * 24 * 29)
        return date.getTime() < availStartTime || date.getTime() > availEndTime
      }
      return false
    },
    handlePickDate(date) {
      this.pickedDate = date
    },
    handleRangeChange() {
      const startTime = this.dateRange[0] ? this.dateRange[0].getTime() : 0
      const endTime = this.dateRange[1] ? this.dateRange[1].getTime() : 0
      const days = (endTime - startTime) / (24 * 60 * 60 * 1000);
      if (days <= 30) {
        this.dateRangeOpen = false
      } else {
        this.dateRange = []
        this.dateRangeOpen = true
      }
    },
    selectLatest7Days(emit) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
      const date = [start, end];
      emit(date);
    },
    selectLatest30Days(emit) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
      const date = [start, end];
      emit(date);
    },
    selectMonthDate(emit, month) {
      const start = new Date();
      start.setDate(1)
      start.setMonth(month - 1)
      const end = new Date(start.getFullYear(), month, 0);
      const date = [start, end];
      emit(date);
    },
    gotoParentFolders() {
      this.$store.commit("gotoParentFolders");
    },
    gotoRootFolders() {
      this.$store.commit("gotoRootFolders");
    },
    deletePictures() {
      const that = this;
      this.$confirm("此操作将永久删除选中的图片, 是否继续?", "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const imageIds = [];
        this.$store.state.checkedPictures.forEach((pic) => {
          imageIds.push(pic.id);
        });
        postApi(
            this.apiBase + "/camera/admin/delete",
            {
              teamId: this.$store.state.teamId,
              imageId: imageIds.join(","),
            },
            function (res) {
              if (res.success === "1") {
                that.$store.commit("syncCheckedPictures", []);
                that.searchPics();
                that.$message.info("删除成功！");
              } else {
                that.$message.error(res.message);
              }
            }
        );
      });
    },
    deleteFolders() {
      const that = this;
      this.$confirm("此操作将永久删除选中的文件夹, 是否继续?", "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const labelIds = [];
        this.$store.state.checkedFolders.forEach((folder) => {
          labelIds.push(folder.id);
        });
        postApi(
            this.apiBase + "/camera/admin/delete",
            {
              teamId: this.$store.state.teamId,
              labelId: labelIds.join(","),
            },
            function (res) {
              if (res.success === "1") {
                that.$store.commit("syncCheckedFolders", []);
                that.search();
                that.$message.info("文件夹删除成功");
              } else {
                that.$message.error(res.message);
              }
            }
        );
      });
    },
    downloadPictures() {
      const that = this

      if (that.dateRange[0] === undefined 
      || that.dateRange[1] === undefined
      || that.dateRange[0] === null
      || that.dateRange[1] === null) {
        this.$message.error('日期不可为空!')
        return
      }

      if (that.$store.state.checkedPictures.length >= 500){

        that.$alert('下载数量不能超过500张', '下载限制', {
          confirmButtonText: '确定'
        });

        return
      }

      that.$confirm("确认要下载选中的图片?", "确认下载", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const picIds = [];
        that.$store.state.checkedPictures.forEach((pic) => {
          picIds.push(pic.id);
        });
        const token = localStorage.getItem('camera_admin_user_token')
        if (picIds.length < 2) {
          //单张的时候，server不会打zip包，所以直接下载
          $('<form method="post" action="' + that.apiBase + '/camera/image/download' + '" target="_self"> \
          <input type="hidden" name="imageId" value="' + picIds.join(',') + '"> \
          <input type="hidden" name="token" value="' + token + '"> \
          </form>').appendTo('body').submit().remove()

        } else {
          that.$loading({ fullscreen: true, text: '正在下载中，请稍后...' })
          this.$axios({
            method: 'get',
            url: this.apiBase + '/camera/image/v2/download',
            params: {
              imageId: picIds.join(','),
              start: this.dateRange[0] ? this.dateRange[0].format("yyyy-MM-dd") : '',
              end: that.dateRange[1] ? that.dateRange[1].format("yyyy-MM-dd") : '',
              token: token
            },
            responseType: "blob"
          })
          .then(function (response) {
            if(response.data.type == "application/octet-stream") {
              // const filename = response.headers["content-disposition"];
              const blob = new Blob([response.data]);
              var downloadElement = document.createElement("a");
              var href = window.URL.createObjectURL(blob);
              downloadElement.href = href;
              // downloadElement.download = filename.split("filename=")[1];
              downloadElement.download = new Date().format('yyyy-MM-dd') + '.zip'
              document.body.appendChild(downloadElement);
              downloadElement.click();
              document.body.removeChild(downloadElement);
              window.URL.revokeObjectURL(href);
            }
          })
          .catch(function (error) {
            console.log(error);
          })
          .finally(() => {
            that.$loading({ fullscreen: true }).close()
          })
          }

      });
    },
    downloadFolders() {
      const that = this
      this.$confirm("确认要下载选中的文件夹?", "确认下载", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let count = 0
        that.$store.state.checkedFolders.forEach((folder) => {
          count += folder.imageNum
        });
        if (count >= 500){
          that.$alert('下载数量不能超过500张', '下载限制', {
            confirmButtonText: '确定'
          });
          return
        }
        that.$loading({ fullscreen: true, text: '正在下载中，请稍后...' })
        const labelIds = [];
        that.$store.state.checkedFolders.forEach((folder) => {
          labelIds.push(folder.id);
        });
        const token = localStorage.getItem('camera_admin_user_token')
        // window.open(that.apiBase + "/camera/image/downloads?type=" + type + "&team_id=" + this.$store.state.teamId + "&values=" + labelIds.join(",") + '&token=' + token)
        // $('<form method="post" action="' + that.apiBase + '/camera/image/downloads' + '" target="_self"> \
        //   <input type="hidden" name="type" value="' + type + '"> \
        //   <input type="hidden" name="team_id" value="' + this.$store.state.teamId + '"> \
        //   <input type="hidden" name="values" value="' + labelIds.join(",") + '"> \
        //   <input type="hidden" name="token" value="' + token + '"> \
        //   </form>').appendTo('body').submit().remove()
        this.$axios({
          method: 'get',
          url: this.apiBase + '/camera/image/v2/downloadByLabel',
          params: {
            teamId: this.$store.state.teamId,
            values: labelIds.join(","),
            token: token
          },
          responseType: "blob"
        })
        .then(function (response) {
          if(response.data.type == "application/octet-stream") {
            // const filename = response.headers["content-disposition"];
            const blob = new Blob([response.data]);
            var downloadElement = document.createElement("a");
            var href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            // downloadElement.download = filename.split("filename=")[1];
            downloadElement.download = new Date().format('yyyy-MM-dd') + '.zip'
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
            window.URL.revokeObjectURL(href);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          that.$loading({ fullscreen: true }).close()
        })
      });
    },
    switchType() {
      this.$store.commit("switchListType");
    },
    searchPics() {
      const cate = this.$store.state.category;
      let currentFolder = this.$store.state.previousFolder[0]
      if (cate === "time") {
        this.searchTimePics();
      } else if (cate === "member") {
        if (currentFolder !== undefined ) {
          this.searchMemberPicsInFolder();
        } else {
          this.searchMemberPics();
        }
      } else {
        if (currentFolder !== undefined ) {
          this.searchWorkPicsInFolder();
        } else {
          this.searchWorkPics();
        }
      }
    },
    searchWorkPics() {
      if (this.dateRange[0] === undefined 
      || this.dateRange[1] === undefined
      || this.dateRange[0] === null
      || this.dateRange[1] === null) {
        this.$message.error('日期不可为空!')
        return
      }
      const that = this;
      getApi(
          this.apiBase +
          "/camera/admin/v2/labels?pageSize=" + this.pageSize
          + "&pageNum=" + this.pageNum
          + "&teamId=" + this.$store.state.teamId
          + (that.searchText.length === 0 ? '' : '&param=' + that.searchText),
          function (res) {
            if (res.success === "1") {
              that.$store.commit("syncFolders", {
                folders: res.content.list,
                total: res.content.total,
                folderLevel: 1,
              });
            } else {
              that.$message.error(res.message);
            }
          }
      );
    },
    searchWorkPicsInFolder() {
      if (this.dateRange[0] === undefined 
      || this.dateRange[1] === undefined
      || this.dateRange[0] === null
      || this.dateRange[1] === null) {
        this.$message.error('日期不可为空!')
        return
      }
      const that = this;
      let currentFolder = this.$store.state.previousFolder[0]
      let startDate = "";
      let endDate = "";
      if (this.dateRange && this.dateRange.length === 2) {
        startDate = this.dateRange[0] ? this.dateRange[0].format("yyyy-MM-dd") : '';
        endDate = this.dateRange[1] ? this.dateRange[1].format("yyyy-MM-dd") : '';
      }
      const params =
            "teamId=" + currentFolder.teamId
            + "&start=" + startDate
            + "&end=" + endDate
            + "&pageSize=" + this.pageSize
            + "&pageNum=" + this.pageNum
            + '&labelId=' + currentFolder.id
      getApi(this.apiBase + "/camera/admin/v2/labelImages?" + params, function (res) {
        if (res.success === "1") {
          that.$store.commit("syncPictures", {
            pictures: res.content.list,
            total: res.content.total
          });
        } else {
          that.$message.error(res.message);
        }
      });

    },
    searchMemberPicsInFolder() {
      if (this.dateRange[0] === undefined 
      || this.dateRange[1] === undefined
      || this.dateRange[0] === null
      || this.dateRange[1] === null) {
        this.$message.error('日期不可为空!')
        return
      }
      const that = this;
      let currentFolder = this.$store.state.previousFolder[0]
      let startDate = "";
      let endDate = "";
      if (this.dateRange && this.dateRange.length === 2) {
        startDate = this.dateRange[0] ? this.dateRange[0].format("yyyy-MM-dd") : '';
        endDate = this.dateRange[1] ? this.dateRange[1].format("yyyy-MM-dd") : '';
      }
      const params =
            "teamId=" + currentFolder.teamId
            + "&start=" + startDate
            + "&end=" + endDate
            + "&pageSize=" + this.pageSize
            + "&pageNum=" + this.pageNum
            + '&userId=' + currentFolder.id
      getApi(this.apiBase + "/camera/admin/v2/userImages?" + params, function (res) {
        if (res.success === "1") {
          that.$store.commit("syncPictures", {
            pictures: res.content.list,
            total: res.content.total
          });
        } else {
          that.$message.error(res.message);
        }
      });
    },
    searchMemberPics() {
      const that = this;
      getApi(
          this.apiBase +
          "/camera/admin/v2/users?pageSize=" + this.pageSize
          + "&pageNum=" + this.pageNum
          + "&teamId=" + this.$store.state.teamId
          + (that.searchText.length === 0 ? '' : '&param=' + that.searchText),
          function (res) {
            if (res.success === "1") {
              that.$store.commit("syncFolders", {
                folders: res.content.list,
                total: res.content.total,
                folderLevel: 1,
              });
            } else {
              that.$message.error(res.message);
            }
          }
      );
    },
    searchTimePics() {
      if (this.dateRange[0] === undefined 
      || this.dateRange[1] === undefined
      || this.dateRange[0] === null
      || this.dateRange[1] === null) {
        this.$message.error('日期不可为空!')
        return
      }
      const that = this;
      let startDate = "";
      let endDate = "";
      if (this.dateRange && this.dateRange.length === 2) {
        startDate = this.dateRange[0] ? this.dateRange[0].format("yyyy-MM-dd") : '';
        endDate = this.dateRange[1] ? this.dateRange[1].format("yyyy-MM-dd") : '';
      }
      const params =
          "teamId=" +
          this.$store.state.teamId +
          "&start=" + startDate +
          "&end=" + endDate +
          '&pageSize=' + this.pageSize +
          '&pageNum=' + this.pageNum
      getApi(this.apiBase + "/camera/admin/v2/timeImages?" + params, function (
          res
      ) {
        if (res.success === "1") {
          that.$store.commit("syncPictures", {
            pictures: res.content.list,
            total: res.content.total
          });
          that.$forceUpdate();
        } else {
          that.$message.error(res.message);
        }
      });
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.searchPics()
    },
    handleCurrentChange (val) {
      this.pageNum = val
      this.searchPics()
    },
    onSearchPictureInFolder () {
      var that = this
      let currentFolder = this.$store.state.previousFolder[0]
      if (currentFolder !== undefined) {
        if (this.dateRange[0] === undefined 
        || this.dateRange[1] === undefined
        || this.dateRange[0] === null
        || this.dateRange[1] === null) {
          this.$message.error('日期不可为空!')
          return
        }
        let startDate = "";
        let endDate = "";
        if (this.dateRange && this.dateRange.length === 2) {
          startDate = this.dateRange[0] ? this.dateRange[0].format("yyyy-MM-dd") : '';
          endDate = this.dateRange[1] ? this.dateRange[1].format("yyyy-MM-dd") : '';
        }
        if (this.$store.state.category === 'work') {
          const params =
            "teamId=" + currentFolder.teamId
            + "&start=" + startDate
            + "&end=" + endDate
            + '&pageSize=30&pageNum=1'
            + '&labelId=' + currentFolder.id
          getApi(this.apiBase + "/camera/admin/v2/labelImages?" + params, function (res) {
            if (res.success === "1") {
              that.$store.commit("syncPictures", {
                pictures: res.content.list,
                total: res.content.total
              });
            } else {
              that.$message.error(res.message);
            }
          });
        } else {
          const params =
            "teamId=" + currentFolder.teamId
            + "&start=" + startDate
            + "&end=" + endDate
            + '&pageSize=30&pageNum=1'
            + '&userId=' + currentFolder.id
          getApi(this.apiBase + "/camera/admin/v2/userImages?" + params, function (res) {
            if (res.success === "1") {
              that.$store.commit("syncPictures", {
                pictures: res.content.list,
                total: res.content.total
              });
            } else {
              that.$message.error(res.message);
            }
          });
        }
      }
    }
  },
};
</script>

<style lang="scss">
.el-date-editor input,
.el-input__prefix,
.el-input__suffix {
  height: 32px;
  line-height: 32px;

  .el-input__icon {
    line-height: 32px;
  }
}
</style>
<style scoped lang="scss">
.body {
  display: flex;
  flex-direction: column;
  width: 100%;

  .actions {
    display: flex;
    align-items: center;
    height: 58px;
    width: 100%;
    background: #fff;

    .nav {
      font-size: 14px;
      color: #666666;
      display: flex;

      .back {
        color: #2298f3;
        cursor: pointer;
      }

      .narrow {
        margin-right: 10px;
        margin-left: 10px;
      }

      .deli {
        color: #2298f3;
      }

      .all {
        color: #2298f3;
        cursor: pointer;
      }

      .current {
      }
    }

    .type {
      width: 16px;
      margin-left: 25px;
      margin-right: 25px;
      cursor: pointer;
    }

    .label {
      font-size: 12px;
      color: #9b9b9b;
    }

    .deli {
      font-size: 12px;
      color: #9b9b9b;
      margin-right: 10px;
      margin-left: 10px;
    }

    .range {

    }

    .checked-btns {
      flex: 1;
      text-align: right;
      padding-right: 20px;
    }

    .label-total {
      flex: 1;
      font-size: 14px;
      color: #6d6d6d;
      text-align: right;
      padding-right: 20px;
    }
  }

  .list-box {
    flex: 1;
    width: 100%;
    background: #fff;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
}

.date-bottom {
  .shortcuts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 496px;

    .shortcut {
      background-color: #f3faff;
      border-radius: 2px;
      border: solid 1px #98d0fd;
      font-size: 12px;
      color: #2298f3;
      padding: 5px 10px;
      margin-right: 10px;
      cursor: pointer;
      margin-top: 5px;
    }

    .close {
      flex: 1 1 0%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 20px;

      div {
        cursor: pointer;
      }
    }
  }

  .tip {
    font-size: 13px;
    color: #f5a623;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

.search-bar {
  display: flex;
}

  .empty-tips {
    color: #9b9b9b;
    font-weight: 500;
    font-size: 18px;
    user-select: none;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: 45px;
  }
</style>
