<template>
  <div class="sub-footer-inner">
    <img class="logo-img" src="../../../img/pc/home/baiwan_pic.png">
    <div class="content">
      <span>百万用户，数万团队，都在用工作蜂</span>
      <div class="options">
        <div @click="$router.push({ path: 'download' })">立即下载</div>
        <div @click="$router.push({ path: 'case' })">查看详情</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Header"
}
</script>

<style scoped lang="scss">

.sub-footer-inner {
  width: 1130px;
  height: 348px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;

  img {
    width: 1130px;
    height: 348px;
  }

  .content {
    width: 1130px;
    height: 348px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;

    span:nth-of-type(1) {
      font-size: 40px;
      font-weight: 500;
      color: #000000;
      line-height: 56px;
      margin-top: 74px;
      text-shadow: 1px 0px #000;
    }

    .options {
      margin-top: 50px;
      display: flex;

      div:nth-child(1) {
        width: 150px;
        height: 50px;
        background: #0189ff;
        border-radius: 3px;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        line-height: 50px;
        color: #FFF;
        cursor: pointer;
        margin-right: 54px;
      }

      div:nth-child(2) {
        width: 150px;
        height: 50px;
        background: #ffffff;
        border: 2px solid #0189ff;
        border-radius: 3px;
        font-weight: 700;
        text-align: center;
        color: #0189ff;
        box-sizing: border-box;
        font-size: 16px;
        line-height: 43px;
        cursor: pointer;
        margin-left: auto;
      }
    }
  }
}

</style>
