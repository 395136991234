<template>
  <div class="header-inner">
    <div class="header-inner-content">
      <img class="logo-img" src="../../../img/pc/home/logo.png">
      <div class="nav">
        <div @click="onClickNav(0)" :class="[activeIndex === 0 ? 'selected' : 'normal']" style="margin-left:53px;">首页</div>
        <div @click="onClickNav(1)" :class="[activeIndex === 1 ? 'selected' : 'normal']">解决方案</div>
        <div @click="onClickNav(2)" :class="[activeIndex === 2 ? 'selected' : 'normal']">客户案例</div>
        <div @click="onClickNav(3)" :class="[activeIndex === 3 ? 'selected' : 'normal']">关于我们</div>
      </div>
      <div class="manage" @click="$router.push('/login')">管理后台</div>
      <div class="download" @click="$router.push({ path: 'download' })">下载产品</div>
    </div>
  </div>
</template>

<script>
import PubSub from 'pubsub-js'

export default {
  name: "Header",
  data () {
    return {
      activeIndex: 0
    }
  },
  mounted () {
    PubSub.subscribe('changeHeaderIndex', (name, index) => {
      this.activeIndex = index
    })
  },
  methods: {
    onClickNav (index) {
      if (this.activeIndex !== index) {
        switch(index) {
          case 1:
            this.$router.push({ path: '/solution' })
            break
          case 2:
            this.$router.push({ path: '/case' })
            break
          case 3:
            this.$router.push({ path: '/about' })
            break
          case 0:
            this.$router.push({ path: '/' })
            break
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

.header-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 94px;
  user-select: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: #FFF;
  z-index: 5000;
  width: 100%;

  .header-inner-content {
    width: 1130px;
    height: 94px;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    align-items: center;

    .logo-img {
      width: 136px;
      height: 45px;
      margin-left: 30px;
    }

    .nav {
      display: flex;
      flex-direction: row;

      .normal {
        margin-left: 42px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
      }

      .normal:hover {
        color: #0189ff;
      }

      .selected {
        margin-left: 42px;
        font-size: 16px;
        font-weight: 700;
        color: #0189ff;
        line-height: 21px;
        cursor: pointer;
      }
    }

    .manage {
      width: 124px;
      height: 47px;
      background: #ffffff;
      border: 2px solid #0189ff;
      border-radius: 3px;
      font-weight: 700;
      text-align: center;
      color: #0189ff;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 43px;
      cursor: pointer;
      margin-left: auto;
    }

    .download {
      width: 124px;
      height: 47px;
      background: #0189ff;
      border-radius: 3px;
      font-size: 16px;
      line-height: 47px;
      font-weight: 700;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      margin-left: 24px;
    }
  }
}

</style>
