<template>
  <div class="footer-inner">
    <!-- <div class="first-row font_20">
      <img class="logo-img" src="../../../img/pc/home/logo.png">
      <span @click="onClickNav(0)"  style="margin-left:93px">首页</span>
      <span @click="onClickNav(1)" >解决方案</span>
      <span @click="onClickNav(2)" >客户案例</span>
      <span @click="onClickNav(3)" >关于我们</span>
    </div> -->
    <div class="second-row">
      <span>产品下载：</span>
      <div class="download_rect" style="margin-left:23px;">
        <img class="download_img" src="../../../img/pc/home/download_iphone.png"/>
        <div class="qr">
          <img src="../../../img/pc/home/qr_iphone.png">
          <p>扫描二维码</p>
          <p>下载iPhone正式版</p>
        </div>
      </div>
      <div class="download_rect">
        <img class="download_img" src="../../../img/pc/home/download_android.png" />
        <div class="qr">
          <img src="../../../img/pc/home/qr_android.png">
          <p>扫描二维码</p>
          <p>下载Android正式版</p>
        </div>
      </div>
      <div class="download_rect">
        <img class="download_img" src="../../../img/pc/home/download_xiaochengxu.png" />
        <div class="qr">
          <img src="../../../img/pc/home/qr_mini.png">
          <p>扫描二维码</p>
          <p>访问工作蜂小程序</p>
        </div>
      </div>
      <span style="margin-left:81px;">联系我们：</span>
      <div class="download_circle" style="margin-left:30px;">
        <img class="contact_img" src="../../../img/pc/home/weixin.png"/>
        <div class="qr">
          <img src="../../../img/pc/home/qr_cm.png">
          <p>扫描二维码</p>
          <p>添加工作蜂微信客服</p>
        </div>
      </div>
      <div class="download_circle">
        <img class="contact_img" src="../../../img/pc/home/QQqun.png"/>
        <div class="qr">
          <img src="../../../img/pc/home/qr_qq.png">
          <p>扫描二维码</p>
          <p>添加工作蜂QQ群</p>
        </div>
      </div>
      <div class="download_circle">
        <img class="contact_img" src="../../../img/pc/home/douyin.png"/>
        <div class="qr">
          <img src="../../../img/pc/home/qr_dy.png">
          <p>扫描二维码</p>
          <p>关注工作蜂抖音号</p>
        </div>
      </div>
      <div class="download_circle">
        <img class="contact_img" src="../../../img/pc/home/gongzhonghao.png"/>
        <div class="qr">
          <img src="../../../img/pc/home/qr_gz.png">
          <p>扫描二维码</p>
          <p>关注工作蜂公众号</p>
        </div>
      </div>
    </div>
    <div class="third-row">
      <p>工作蜂Ⓒ版权公告© 2020-2021 北京自由视觉科技有限公司<router-link class="desc" style="margin-left:38px;" tag="a" target="_blank" :to="{name:'privacy-policy'}">隐私政策</router-link> | <router-link class="desc" tag="a" target="_blank" :to="{name:'user-license'}">用户协议</router-link></p>
      <p><a class="copyright" href="https://beian.miit.gov.cn/" target="_blank">京ICP备2020036458号</a></p>
    </div>
  </div>
</template>

<script>

export default {
  name: "Footer",
  methods: {
    onClickNav (index) {
      switch(index) {
        case 1:
          this.$router.push({ path: '/solution' })
          break
        case 2:
          this.$router.push({ path: '/case' })
          break
        case 3:
          this.$router.push({ path: '/about' })
          break
        case 0:
          this.$router.push({ path: '/' })
          break
      }
    }
  }
}
</script>

<style scoped lang="scss">

.footer-inner {
  height: 524px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f4f4f4;
  user-select: none;
  z-index: 5000;
  width: 100%;

  .first-row {
    display: flex;
    height:  140px;
    width: 1216px;
    align-items: center;
    border-bottom: solid 2px #D8D8D8;

    .logo-img {
      width: 136px;
      height: 45px;
      margin-left: 30px;
    }

    span {
      margin-left: 100px;
      font-weight: 500;
      cursor: pointer;
    }

    span:nth-child(1) {
      margin-left: 93px;
    }
  }

  .second-row {
    display: flex;
    align-items: center;
    margin-top: 46px;
    height: 60px;
    font-size: 20px;
    font-weight: 500;
    
    .download_rect {
      width: 140px;
      height: 60px;
      // padding: 0 10px 0 10px;
      // box-sizing: border-box;
      // font-weight: 500;
      // font-size: 16px;
      // line-height: 22px;
      // display: flex;
      // align-items: center;
      cursor: pointer;
      margin-left: 20px;
      position: relative;
      // border: 1px solid #979797;
      // border-radius: 5px;
      // color: #000;
      // background-color: #f4f4f4;
      
      .download_img {
        width: 140px;
        height: 60px;
      }
    }

    .qr {
      width: 140px;
      height: 182px;
      border-radius: 5px;
      box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.28);
      position:absolute;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 13px 15px 9px 15px;
      box-sizing: border-box;
      background-color: #FFF;
      display: none;
      z-index: 5001;

      img {
        width: 110px;
        height: 110px;
        margin-bottom: 9px;
      }

      p {
        margin: 0px;
        text-align: center;
      }

      p:nth-child(3) {
        font-size: 12px;
        line-height: 17px;
      }
    }

    .download_rect:nth-of-type(1) .download_img:hover {
      content: url('../../../img/pc/home/download_iphone_hover.png');
    }

    .download_img:hover + .qr {
      display: block;
    }

    // .download_rect:nth-of-type(1):hover::before{
    //   content:'';
    //   width:140px;
    //   height:182px;
    //   border-radius: 5px;
    //   box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.28); 
    //   background: url('../../../img/pc/home/qr_iphone_hover.png');
    //   background-size:100% auto;
    //   position:absolute;
    //   z-index:2;
    // }

    .download_rect:nth-of-type(2) img:hover {
      content: url('../../../img/pc/home/download_android_hover.png');
    }

    .download_rect:nth-of-type(3) img:hover {
      content: url('../../../img/pc/home/download_xiaochengxu_hover.png');
    }

    .download_circle {
      margin-left: 20px;
      // border: 1px solid #979797;
      // border-radius: 30px;
      cursor: pointer;
      width: 60px;
      height: 60px;
      position: relative;

      .contact_img {
        width: 60px;
        height: 60px;
      }
    }

    .download_circle:nth-of-type(4) img:hover {
      content: url('../../../img/pc/home/weixin_02.png');
    }

    .download_circle:nth-of-type(5) img:hover {
      content: url('../../../img/pc/home/QQqun_02.png');
    }

    .download_circle:nth-of-type(6) img:hover {
      content: url('../../../img/pc/home/douyin_02.png');
    }

    .download_circle:nth-of-type(7) img:hover {
      content: url('../../../img/pc/home/gongzhonghao_02.png');
    }

    .contact_img:hover + .qr {
      display: block;
      transform: translate(-25% , 0);
    }
  }

  .third-row {
    margin-top: 59px;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 158px;

    p {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    p:nth-child(2) {
      margin-top: 10px;
    }

    a {
      color: #0189ff;
      text-decoration: none;
    }

    .copyright {
      color: #000;
      text-decoration: none;
    }
  }
}

</style>
