<template>
  <div class="forward-inner">
    <div class="header">
      <div class="title">闪电转发</div>
      <div class="subtitle">设置拍照自动转发至钉钉群，注意：最多添加5个工作群！</div>
    </div>
    <div class="content">
      <div class="content-header">
        <button class="add-group-btn" @click="onAddGroup()">添加工作群</button>
      </div>
      <div class="group" v-for="(group) in groupList" :key="group.id">
        <el-switch class="group-switch" v-model="group.isOpen" active-color="#0A8DF2" inactive-color="#BBBBBC" @change="((val)=>{onRebotChange(val, group.id)})"></el-switch>
        <div class="group-desc">
          <div class="group-name">{{group.name}}</div>
          <div class="group-state">{{group.isOpen ? '已开启' : '已关闭'}}</div>
        </div>
        <span class="edit-btn" @click="onEditGroup(group)">删除</span>
      </div>
    </div>
  </div>
</template>

<script>

import {getApi, postApi} from "@/common"

export default {
  name: "forward-list",
  data() {
    return {
      teamId: null,
      groupList: []
    }
  },
  mounted() {
    var team = JSON.parse(localStorage.getItem('camera_admin_team'))
    this.teamId = team.id
    this.fetchGroupList()
  },
  methods: {
    fetchGroupList () {
      var self = this
      self.$store.commit('showLoading')
      getApi(this.apiBase + '/camera/forward/list?teamId=' + this.teamId, res => {
        self.$store.commit('hideLoading')
        if (res.success === '1') {
          res.content.forEach(item => {
            if (item.isOpen === 1) {
              item.isOpen = true
            } else {
              item.isOpen = false
            }
          })
          self.groupList = res.content
        } else {
          self.$message.error(res.message);
        }
      })
    },
    onAddGroup () {
      this.$router.push({ name: 'forward-detail' })
    },
    onEditGroup (group) {
      // this.$router.push({ name: 'forward-detail' , params: { group: group }})
      const self = this;
      this.$confirm("确认删除该工作群？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        postApi(
            this.apiBase + "/camera/forward/delete",
            {
              teamId: this.teamId,
              robotId: group.id
            },
            function (res) {
              if (res.success === "1") {
                self.$message.info("删除工作群成功")
                self.fetchGroupList()
              } else {
                self.$message.error(res.message)
              }
            }
        )
      })
    },
    onRebotChange (val, id) {
      if(val) {
        this.onOpenRebot(id)
      } else {
        this.onCloseRebot(id)
      }
    },
    onCloseRebot (id) {
      var self = this
      postApi(this.apiBase + '/camera/forward/close', { teamId: this.teamId, robotId: id }, 
        function (data) {
          if (data.success !== "1") {
            self.$message.error(data.message)
            this.fetchGroupList()
          }
      })
    },
    onOpenRebot (id) {
      var self = this
      postApi(this.apiBase + '/camera/forward/open', { teamId: this.teamId, robotId: id }, 
        function (data) {
          if (data.success !== "1") {
            self.$message.error(data.message)
            this.fetchGroupList()
          }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.forward-inner {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    height: 80px;
    padding: 0 20px;
    background-color: #F7F7F7;

    .title {
      font-size: 16px;
      line-height: 21px;
      font-weight: 700;
      margin-top: 23px;
    }

    .subtitle {
      font-size: 14px;
      line-height: 19px;
      color: #666;
      margin-top: 7px;
    }
  }

  .content {
    flex-grow: 1;

    .content-header {
      height: 32px;
      margin-top: 17px;
      margin-left: 20px;
      font-size: 14px;
      line-height: 19px;

      .add-group-btn {
        width: 160px;
        height: 32px;
        background-color: #0A8DF2;
        border-radius: 2px;
        box-sizing: border-box;
        border: solid 1px #0A8DF2;
        color: #FFF;
        cursor: pointer;
      }
    }

    .group {
      width: 350px;
      height: 70px;
      margin-left: 20px;
      margin-top: 20px;
      box-sizing: border-box;
      border: solid 1px #D6D4D4;
      display: flex;
      align-items: center;

      .group-switch {
        margin-left: 15px;
        width: 50px;
        height: 25px;
      }

      .group-desc {
        display: flex;
        flex-direction: column;
        // margin: 0 14px;
        margin-left: 14px;
        width: 200px;
        justify-content: center;

        .group-name {
          font-size: 14px;
          line-height: 18px;
          font-weight: 700;
        }

        .group-state {
          font-size: 12px;
          line-height: 16px;
          color: #666;
          margin-top: 5px;
        }
      }

      .edit-btn {
        font-size: 14px;
        line-height: 19px;
        // margin: 0px 27.5px 0px auto;
        margin-right: 27.5px;
        margin-left: auto;
        color: #FF6668;
        cursor: pointer;

      }
    }
  }
}
</style>
