<template>
    <div class="inner" v-loading="$store.state.loading">
        <div class="version-panel">
            <div class="row-header">照片解封:</div>
            <div class="row">
                <!-- <div class="version-cell">
                    <span class="version-title">团队号：</span>
                    <input v-model="teamCode" class="version-input" placeholder="请输入团队号" />
                </div> -->
                <div class="version-cell1" style="margin-left:20px;">
                    <span class="version-title">选择日期：</span>
                    <el-date-picker v-model="times" value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </div>
                <div class="version-cell" style="margin-left:20px;">
                    <span class="version-title">解封时间：</span>
                    <input type="number" min="1" max="365" v-model="days" class="version-input" placeholder="请输入解封时间" />
                </div>
                <div class="version-cell" style="margin-left:20px;">
                    <span class="version-title">解封速度：</span>
                    <el-select v-model="type" placeholder="请选择解封速度">
                        <el-option v-for="item in typeArray" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <button class="btn-submit" @click="submitreStore()">提交</button>
            </div>
        </div>
    </div>
</template>

<script>
import { postApi } from "@/common"

export default {
    name: "restore",
    data() {
        return {
            teamCode: this.$store.state.teamId,
            times: '',
            startTime: '',
            endTime: '',
            days: '1',
            type: 'EXPEDITED',
            typeArray: [
                {
                    value: 'EXPEDITED',
                    label: '12小时',
                },
                {
                    value: 'NORMAL',
                    label: '48小时',
                }
            ]

        }
    },
    mounted() {
    },
    methods: {

        submitreStore () {
            if (this.teamCode.trim().length === 0) {
                this.$message.error('请输入团队号')
                return
            }
            if (this.times === '') {
                this.$message.error('请选择日期')
                return
            }
            if (this.days.trim().length === 0) {
                this.$message.error('请输入解封时间')
                return
            }
            if (this.days > 365) {
                this.$message.error('解封时间最长为365天')
                this.days = 0
                return
            }
            var self = this
            self.$store.commit('showLoading')
            postApi(this.apiBase + '/camera/admin/v2/restore', { teamId: self.teamCode, start: self.times[0], end: self.times[1], days: self.days, type: self.type }, res => {
                self.$store.commit('hideLoading')
                if (res.success === '1') {
                    self.$message.success('提交成功')
                    self.loadVersion()
                } else {
                    self.$message.error(res.message)
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
.inner {
    // padding:16px 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    .version-header {
        font-size: 14px;
        line-height: 19px;
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 10px;
    }

    .version-panel {
        display: flex;
        flex-direction: column;
        background-color: #FFF;
        padding: 7px 35px;
        flex: 1;

        .row-header {
            font-size: 14px;
            line-height: 19px;
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: bold;
        }

        .row {
            display: flex;

            .version-cell {
                display: flex;
                width: 180px;
                flex-direction: column;
                margin-top: 31px;

                .version-title {
                    font-size: 15px;
                    line-height: 21px;
                    height: 34px;
                    color: #525252;
                }

                .version-data {
                    font-size: 32px;
                    line-height: 43px;
                    height: 60px;
                }

                .version-input {
                    width: 153px;
                    height: 34px !important;
                    padding: 0 12px;
                    border: solid 1px #D9D9D9;
                    border-radius: 4px;
                }
            }

            .version-cell1 {
                display: flex;
                width: 360px;
                flex-direction: column;
                margin-top: 31px;

                .version-title {
                    font-size: 15px;
                    line-height: 21px;
                    height: 34px;
                    color: #525252;
                }

                .version-data {
                    font-size: 32px;
                    line-height: 43px;
                    height: 60px;
                }

                .version-input {
                    width: 153px;
                    height: 34px !important;
                    padding: 0 12px;
                    border: solid 1px #D9D9D9;
                    border-radius: 4px;
                }
            }

            .btn-submit {
                background-color: #0A8DF2;
                border: solid 1px #0A8DF2;
                border-radius: 2px;
                width: 130px;
                height: 32px;
                color: #FFF;
                font-size: 14px;
                margin-left: 16px;
                cursor: pointer;
                margin-bottom: 2px;
                margin-top: auto;
            }
        }
    }
}
</style>
