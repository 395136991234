<template>
  <div class="home-inner">
      <div class="pc-body">
        <PC></PC>
      </div>
      <div class="mobile-body">
        <Mobile></Mobile>
      </div>
  </div>
</template>

<script>

import PC from './views/PC.vue'
import Mobile from './views/Mobile.vue'

export default {
  name: "Home",
  components: {
    PC,
    Mobile
  }
}
</script>

<style scoped lang="scss">

.home-inner {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

@media (min-width: 768px) {
    .pc-body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .mobile-body {
        display: none;
    }
}

@media (max-width: 768px) {
    .pc-body {
        display: none;
    }

    .mobile-body {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
}
</style>
