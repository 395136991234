<template>
  <div class="user-license-inner">
      <div class="content">
        <div class="header1">用户协议</div>
        一般条款–所有用户
        <div class="header2">1.您与我们的关系</div>
        <span>欢迎使用由北京自由视觉科技有限公司（“公司”，“我们”或“我们”）提供的工作蜂水印相机，简称工作蜂（“平台 ”）。我们是在中国注册的公司。<br/>
        您正在阅读您与我们之间的协议中的服务条款（以下简称“条款”），并阐明了可以访问和使用我们的网站，服务，应用程序，产品和内容（包括但不限于此）的条款和条件平台）（统称为“服务”）。我们提供的服务仅供私人，非商业用途。就本条款而言，“您”和“您的”是指您作为服务的用户。<br/>
        本条款构成您与我们之间具有法律约束力的协议。请花时间仔细阅读它们。</span>
        
        <div class="header2">2.接受条款</div>
        <span>通过访问或使用我们的服务，您确认您可以与本公司签订具有约束力的合同，您接受这些条款并同意遵守这些条款。您对我们服务的访问和使用还受我们的隐私政策和任何其他政策的约束，其条款可以直接在平台上找到，或者可以在移动设备的适用应用商店上下载平台的地方找到，并且通过引用并入本文。<br/>
        如果您代表一个企业或实体访问或使用服务，则（a）“您”和“您的”包括您以及该企业或实体，（b）您代表并保证您是该实体的授权代表。有权约束实体遵守这些条款的业务或实体，并且您代表实体同意这些条款，并且（c）您的业务或实体对您访问或使用服务以及在法律和财务上负有法律责任供与您实体关联的其他人（包括任何员工，代理商或承包商）访问或使用您的帐户。<br/>
        您可以通过访问或使用我们的服务接受条款。您理解并同意，从那时起，我们将您对服务的访问或使用视为对条款的接受。<br/>
        您应打印或保留本条款的本地副本以供记录。</span>

        <div class="header2">3.条款变更</div>
        <span>我们会不时修改这些条款，例如，当我们更新服务功能或法规发生变化时。我们将通过商业上合理的努力，将这些条款的任何重大更改通知所有用户，例如通过我们平台上的通知，但是，您应定期查看条款以检查此类更改。我们还将在这些条款的顶部更新“最后更新”日期，以反映这些条款的生效日期。您在新条款日期之后继续访问或使用服务即表示您接受新条款。如果您不同意新条款，则必须停止访问或使用服务。</span>

        <div class="header2">4.您在我们的账户</div>
        <span>您无需创建帐户即可访问或使用我们的某些服务。如创建账户可在工作蜂里使用团队相关的服务。</span>
        
        <div class="header2">5.您对我们服务的访问和使用</div>
        <span>您对服务的访问和使用受这些条款和所有适用法律和法规的约束。你不可以：</span>
        <ul>
          <li>如果您没有完全能力和法律上的能力同意这些条款，请访问或使用服务；</li>
          <li>基于服务修改，改编，翻译，反向工程，反汇编，反编译或创建任何派生作品，包括任何文件，表或文档（或其任何部分），或确定或尝试确定任何源代码，算法，方法或技术体现平台或其任何衍生作品；</li>
          <li>分发，许可，转让或全部或部分出售任何服务或其任何衍生作品；</li>
          <li>收费出售，出租或租赁服务，或使用平台做广告或进行任何商业宣传；</li>
          <li>干扰或试图干扰服务的正常运行，破坏我们的网站或与服务连接的任何网络，或绕过我们可能用来阻止或限制对服务访问的任何措施；</li>
          <li>将平台或其任何部分合并到任何其他程序或产品中。在这种情况下，我们保留自行决定拒绝提供服务或限制访问服务的权利；</li>
          <li>使用自动化脚本从服务中收集信息或与服务进行交互；</li>
          <li>恐吓或骚扰他人，或煽动基于种族，性别，宗教，国籍，残障，性取向或年龄的露骨色情，暴力或歧视；</li>
          <li>使用服务以任何方式上传，传输，分发，存储或以其他方式提供：</li>
          <ul>
            <li>包含病毒，特洛伊木马，蠕虫，逻辑炸弹或其他恶意或技术有害材料的文件；</li>
            <li>任何未经请求或未经授权的广告，招揽，促销材料，“垃圾邮件”，“垃圾邮件”，“连锁信”，“金字塔计划”或任何其他禁止的招揽形式；</li>
            <li>任何第三方的任何私人信息，包括地址，电话号码，电子邮件地址，个人身份证明文件中的号码和功能（例如，国民保险号码，护照号码）或信用卡号码；</li>
            <li>任何侵犯或可能侵犯他人版权，商标或其他知识产权或隐私权的材料；</li>
            <li>任何诽谤他人，淫秽，令人反感，可恨或具有煽动性的材料；</li>
            <li>构成，鼓励或提供刑事犯罪，危险活动或自我伤害的任何材料；</li>
            <li>故意设计用来挑衅或激怒人，特别是巨魔的材料，或意图骚扰，恐吓，困扰，使人难堪或沮丧的任何材料；</li>
            <li>包含任何威胁，包括人身暴力威胁的任何材料；</li>
            <li>任何种族主义或歧视性的材料，包括基于种族，宗教，年龄，性别，残疾或性行为的歧视；</li>
            <li>根据公司的判断，是令人反感的或限制或禁止任何其他人使用服务的材料，或可能使公司，服务或其用户遭受任何形式的伤害或责任的材料。</li>
          </ul>
        </ul>
        <div class="header2">6.知识产权</div>
        <span>我们尊重知识产权，并要求您也这样做。作为访问和使用服务的条件，您同意不使用服务侵犯任何知识产权。对于任何侵犯或被指称侵犯任何版权或其他知识产权的用户，我们保留随时酌情决定在不通知的情况下通知或不通知的权利。</span>

        <div class="header2">7.内容</div>
        <div class="header3">A.公司内容</div>
        <span>在您与公司之间，服务的所有内容，软件，图像，文本，图形，插图，徽标，专利，商标，服务标记，版权，照片，音频，视频，音乐和“外观”都将与与公司相关的所有知识产权（“公司内容”）均由公司拥有或许可，可以理解为您或许可人将拥有您通过服务上传或传输的任何用户内容（定义见下文）。严格禁止将本公司内容或服务上的材料用于本条款未明确允许的任何目的。未经我们或（在适用情况下）任何目的，不得出于任何目的下载，复制，复制，分发，传播，传播，显示，出售，许可或以其他方式利用此类内容 我们许可人的事先书面同意。我们和许可人保留其内容中未明确授予的所有权利。 <br/>
        根据条款的条款和条件，特此授予您非专有，有限，不可转让，不可再许可，可撤销的访问和使用服务的许可，包括在许可的设备上下载平台以及通过使用服务访问公司的内容。公司保留本服务和公司内容中未明确授予的所有权利。您承认并同意，公司可以随时出于任何原因或没有理由终止本许可。</span>

        <div class="header3">B.用户生成的内容</div>
        <span>可能允许用户通过服务生成内容（“用户内容”）。用户还可以通过服务将音乐，图形，贴纸和公司提供的其他元素（“公司的元素”）叠加到此用户内容和此用户内容上。用户内容中的信息和材料，包括包含公司要素的用户内容，均未经我们验证或批准。其他用户在我们平台上表达的观点并不代表我们的观点或价值观。<br/>
        您可以在第三方托管的网站或平台上共享，上传或传输您的用户内容，包括包含公司元素的用户内容。如果您决定这样做，则必须遵守其内容指南以及上面“您对我们服务的访问和使用”中列出的标准。<br/>
        您保证任何此类供款均符合这些标准，并且您将对我们承担责任，并对任何违反该保证的行为向我们作出赔偿。这意味着您将对由于违反保修规定而遭受的任何损失或损害负责。<br/>
        您保证您的用户内容不会淫秽，威胁，骚扰，诽谤，欺骗，欺诈，侵犯他人隐私，冒犯他人，诽谤他人或非法。您保证您的用户内容不会侵犯任何一方或个人的任何专利，商标，商业秘密，版权或其他知识产权或专有权或隐私权。<br/>
        我们还有权向任何声称您发布或上传到我们服务的任何用户内容构成侵犯其知识产权或隐私权的第三方披露您的身份。<br/></span>

        <div class="header2">8.赔偿</div>
        <span>您同意为公司，其母公司，子公司和关联公司以及其各自的高级管理人员，董事，雇员，代理人和顾问各自的任何，所有索赔，责任，成本和费用进行辩护，赔偿并使他们免受损害，包括：但不限于因您或您的任何用户违反本条款而产生的律师费用和支出，或因违反您根据本条款承担的义务，陈述和保证而引起的律师费用。</span>

        <div class="header2">9.保修范围</div>
        <span>这些条款中的任何内容均不会影响您无法立约同意更改或放弃的合法权利，并且应始终作为消费者使用。<br/>
        这些服务均按“原样”提供，对于它们，我们不做任何担保或陈述。特别是，我们不代表或保证您：</span>
        <ul>
          <li>您对服务的使用将满足您的要求；</li>
          <li>您对服务的使用将不受干扰，及时，安全或没有错误；</li>
          <li>您因使用服务而获得的任何信息都是准确或可靠的；</li>
          <li>将纠正作为服务一部分提供给您的任何软件的操作或功能方面的缺陷。</li>
        </ul>
        <span>除在条款中明确列出的服务外，没有任何条件，担保或其他条款（包括关于满意质量，目的适用性或与描述相符的任何默示条款）。我们可能会随时更改，暂停，撤回或限制我们平台的全部或任何部分的可用性，以用于业务和运营原因，恕不另行通知</span>

        <div class="header2">10.责任限制</div>
        <span>本条款中的任何内容均不排除或限制我们对适用法律可能不合法排除或限制的损失的责任。这包括因我们的疏忽或雇员，代理商或分包商的疏忽造成的死亡或人身伤害以及欺诈或欺诈性陈述。<br/>
        根据以上条款，我们对以下事项不承担任何责任：</span>
        <ul>
          <li>（I）任何利润损失（无论是直接还是间接引起的）；（II）商誉的任何损失；（III）任何机会的损失；（IV）您所遭受的任何数据丢失；或（V）您可能引起的任何间接或间接损失。</li>
          <li>由于以下原因，您可能造成的任何损失或损坏：</li>
          <ul>
            <li>我们可能对服务进行的任何更改，或因提供服务而永久或暂时停止（或服务中的任何功能）；</li>
            <li><span>通过使用服务维护或传输的任何内容和其他通信数据的删除，损坏或存储失败；<br/>
            请注意，我们仅提供供个人使用的平台。您同意不对任何商业或商业目的使用我们的平台，并且我们对任何利润损失，商业损失，商誉或商业声誉损失，商业中断或商业机会损失概不负责。<br/>
            无论我们是否已被告知或应该知道任何此类损失的可能性，都应适用于我们对您的责任的这些限制。<br/>
            您对可能适用于您使用我们的服务的任何移动费用负责，包括文本消息和数据费用。如果不确定这些费用是多少，则在使用服务之前，应先咨询服务提供商。</span></li>
          </ul>
        </ul>
        <div class="header2">11.其他条款</div>
        <ul>
          <li>(a）<span class="contentBold">适用法律和司法管辖区。</span>（这些条款，其主题和构成受这些条款引起的或与之相关的中华人民共和国法律（包括与这些条款的存在，有效性或终止有关的任何问题，应移交给中国任何主管法院。如果您所居住的国家/地区的法院不会将中国法律适用于某些类型的纠纷，则在不适用中国法律的情况下，贵国法律将适用于与这些条款相关的此类纠纷。如果您所在国家/地区的法律或法院不允许您同意该争端的管辖权，则您当地的管辖权和地点将适用于与这些条款相关的此类争端。</li>
          <li>（b）<span class="contentBold">整个协议。</span>这些条款构成您与公司之间的全部法律协议，并管辖您对服务的使用，并完全取代您与公司之间有关服务的任何先前协议。</li>
          <li>（c）<span class="contentBold">没有豁免。</span>我们未能坚持或执行这些条款的任何规定，不应解释为放弃任何规定或权利。</li>
          <li>（d）<span class="contentBold">安全。</span>我们不保证我们的平台将是安全的或没有漏洞或病毒。您负责配置您的信息技术，计算机程序和平台以访问我们的平台。您应该使用自己的病毒防护软件。</li>
          <li>（e）<span class="contentBold">年龄限制。</span>该平台仅适用于13岁以上的人（除非该平台仅适用于韩国14岁以上的人），除非获得父母或法定监护人的同意。通过使用平台，您确认您已经超过上述指定的年龄。如果我们发现上述指定年龄以下的某人正在使用平台，我们将终止该用户的帐户。此外，如果您未达到上述指定年龄，则表示您确认您已获得父母或监护人的合法许可才能访问或使用平台，并且完全有能力并有能力签署，遵守并遵守本条款。</li>
          <li>（f）<span class="contentBold">可分割性。</span>如果有管辖权对此事做出裁决的任何法院裁定本条款的任何规定无效，则该条款将从本条款中删除，而不会影响本条款的其余部分，并且本条款的其余规定将继续是有效和可执行的。</li>
        </ul>
        <span><span class="contentBold">任何问题？</span>通过（dakaxiangji@126.com）与我们联系</span>
        <div class="header2">12.补充条款</div>
        <span>在适用法律允许的范围内，以下补充条款应适用：</span>
        <div class="header3">苹果应用商店（App Store）</div>
        <span>通过Apple公司（“Apple”）制造的设备访问平台，即表示您明确承认并同意：</span>
        <ul>
          <li>公司与您之间的这些条款；Apple不是这些条款的一方。</li>
          <li>根据本协议授予您的许可仅限于个人，有限，非排他，不可转让的权利，以便在您拥有或控制用于个人，非商业用途的Apple授权的Apple设备上安装平台，遵守Apple App Store服务条款中规定的使用规则。</li>
          <li>Apple对平台或其内容不承担任何责任，也没有义务为平台提供任何维护或支持服务。</li>
          <li>如果平台未能遵守任何适用的保证，则您可以通知Apple，Apple会将平台的购买价格（如有）退还给您。在适用法律允许的最大范围内，Apple对于平台不承担任何其他保修义务。</li>
          <li>Apple不负责处理您或第三方有关平台或您拥有或使用平台的任何索赔，包括但不限于（a）产品责任索赔；（b）关于平台不符合任何适用法律或法规要求的任何主张；（c）根据消费者保护或类似法律提出的索赔。</li>
          <li>如果有任何第三方声称平台或您对平台的拥有和使用侵犯了该第三方的知识产权，则Apple不负责调查，辩护，解决或解除此类知识产权侵权的索赔。</li>
          <li>您声明并保证（a）您不在美国政府禁运的国家或美国政府指定为“恐怖主义支持”国家的国家；（b）您未列入任何美国政府的禁止或限制方名单。</li>
          <li>Apple及其子公司是这些条款的第三方受益人，一旦您接受这些条款的条款和条件，Apple将有权（并且将被视为已接受权利）对您作为第三方执行这些条款受益人。</li>
          <li>公司明确授权多个用户通过家庭共享或Apple提供的任何类似功能使用平台。</li>
        </ul>
        <div class="header3">Google Play</div>
        <span>通过从Google，Inc.或其关联公司之一（“Google”）运营的Google Play（或其继任者）下载平台，您明确承认并同意：</span>
        <ul>
          <li>（a）《Google Play服务条款》与《Google Play商业和计划政策》或Google指定为Google Play的默认最终用户许可条款的其他条款（统称为“Google Play条款”）和（b）这些条款中的其他条款和条件，对于您对从Google Play下载的平台的使用，Google Play条款应适用；</li>
          <li>您特此确认，对于本公司或您（或任何其他用户）根据这些条款或Google Play条款的合规性或不合规性，Google不承担任何责任。</li>
        </ul>
      <div class="header2">13.联系我们</div>
      <span>欢迎对此政策提出疑问，意见和要求，并应解决以下问题：</span>
      
      <br/><span class = "contentSmall">邮箱： dakaxiangji@126.com</span>
      <br/><span class = "contentSmall">微信号：dakaxiangji01</span>
      <br/><span class = "contentSmall">QQ群：720844363</span>
      </div>
  </div>
</template>

<script>

// import {getApi} from "@/common"

export default {
  name: "user-license",
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">

.user-license-inner {
  width: 100%;
  display: flex;
  justify-content: center;

  .content {
    width: 1000px;
    margin-top: 100px;
    margin-bottom: 100px;
    font-size: 15px;
    line-height: 30px;
    font-weight: 100;
    padding: 0 20px;
    box-sizing: border-box;

    .header1 {
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 8px;
    }

    .header2 {
      font-weight: 700;
      margin-top: 30px;
    }

    .header3 {
      font-size: 16px;
      line-height: 28px;
      font-weight: 700;
      margin-top: 15px;
    }

    .contentBold {
      font-size: 15px;
      line-height: 24px;
      font-weight: 600;
    }
  }
}

</style>
