import 'babel-polyfill';
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from "vue-router";
import store from './store'
import router from './router'
import VueLazyload from 'vue-lazyload'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
import axios from 'axios'
Vue.prototype.$axios = axios

Vue.use(VueLazyload)

const options = {
  fullscreenEl: false, //关闭全屏按钮,
  pinchToClose: false,
  escKey: false,
  closeOnScroll: false,
  zoomEl: false
}
Vue.use(preview, options)

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
Vue.use(ElementUI)
Vue.config.productionTip = false

// Vue.prototype.apiBase = process.env.VUE_APP_API_BASE_URL


var env = process.env
if(process.env.VUE_APP_MODE==='development'){
  //开发环境下的执行操作
  env.VUE_APP_MODE = 'development'
}else if(process.env.VUE_APP_MODE==='test'){
  //测试环境下的执行操作
  env.VUE_APP_MODE = 'test'
}else{
  //生产环境下的执行操作
  env.VUE_APP_MODE = 'production'
}
Vue.prototype.apiBase = env.VUE_APP_API_BASE_URL
console.log('环境变量', process.env);


Vue.prototype.picIndex = -1

// 定义全局点击函数
Vue.prototype.globalClick = function (callback) {
  document.getElementById('app').onclick = function (e) {
    callback(e);
  };
};

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
