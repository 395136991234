<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
html,
body,
#app {
  margin: 0;
  padding: 0;
  font-size: 16px;
  height: 100%;
  width: 100%;
  font-family: "微软雅黑", serif;
  // min-width: 1440px;
  background: #fff !important;
}

input,
button {
  outline: none;
}
</style>

<style lang="scss">

@import './web/css/common.scss';

.pc-body {
  .el-carousel__indicators--horizontal {
    bottom: 38px !important;
    left: 67px !important;
    transform: none !important;

    .el-carousel__button {
      width: 10px !important;
      height: 10px !important;
      border-radius: 5px !important;
    }
  }

  .el-carousel__indicators--horizontal .is-active .el-carousel__button {
    width: 72px !important;
  }
}

.mobile-body {

  .el-carousel__indicators--horizontal {
    bottom: 12px !important;

    .el-carousel__button {
      width: 10px !important;
      height: 10px !important;
      border-radius: 5px !important;
    }
  }

  .el-carousel__indicators--horizontal .is-active .el-carousel__button {
    width: 72px !important;
  }
}

.pswp {
  height: calc(100% - 60px) !important;
}

.pswp__bg {
  opacity: 0.6 !important;
}

.pswp__caption__center {
  max-width: 100% !important;
  width: 100%;
  background: #000;
  padding: 0 !important;
  display: flex;
  justify-content: center;
}

.pswp__button--arrow--left:before {
  height: 57px !important;
  width: 57px !important;
  background: url("assets/images/previous.png") !important;
  background-size: 57px 57px !important;
  background-repeat: no-repeat !important;
  left: 50px !important;
}

.pswp__button--arrow--right:before {
  height: 57px !important;
  width: 57px !important;
  background: url("assets/images/next.png") !important;
  background-size: 57px 57px !important;
  background-repeat: no-repeat !important;
  right: 50px !important;
}

.preview-btns {
  z-index: 3000;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #000;
  position: fixed;
  left: 0;
  bottom: 0;

  button {
    border: none;
    color: #ffffff;
    font-size: 12px;
    cursor: pointer;
  }

  .del-btn {
    width: 76px;
    height: 32px;
    background-color: #fe4d4f;
    border-radius: 4px;
    margin-right: 50px;
  }

  .download-btn {
    width: 76px;
    height: 32px;
    background-color: #2298f3;
    border-radius: 4px;
  }
}
</style>
<style lang="css">

.el-range-editor.el-input__inner {
  padding: 0 5px !important;
  border-color: #eeeeee !important;
}

.el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}

.el-date-range-picker {
  left: 130px !important;
}

.el-image-viewer__actions {
  display: none !important;
}

.el-image-viewer__img {
  max-height: calc(100vh - 60px) !important;
  margin-bottom: 60px !important;
}

.el-icon-circle-close {
  color: #FFF !important;
}

.el-image__inner--center {
  top: 0px !important;
  left: 0px !important;
  transform: none !important;
}

.el-menu {
  border-right-width: 0px !important;
}

.el-menu-item.is-active {
  background-color: #FFF !important;
  font-weight: 700;
}

.el-pagination {
  text-align: center !important;
}

.el-textarea__inner {
  min-height: 100% !important;
}

/* .el-icon-menu-picture{
  background: url("./assets/images/icon-menu-picture.svg") center no-repeat;
}

.el-icon-menu-picture:before{
  content: "\8d3a";
  font-size: 18px;
  visibility: hidden;
}

.el-icon-menu-permission{
  background: url("./assets/images/icon-menu-permission.svg") center no-repeat;
}

.el-icon-menu-permission:before{
  content: "\8d3a";
  font-size: 18px;
  visibility: hidden;
} */

/* .el-icon-menu-cm{
  background: url("./assets/images/menu/客服01.svg") center no-repeat;
}

.el-icon-menu-cm:before{
  content: "\8d3a";
  font-size: 18px;
  visibility: hidden;
} */

.el-message .el-icon-info {
  color: #1acb66 !important;
}

.el-message--info {
  background-color: #E2FFF1 !important;
}

.el-message--info > .el-message__content {
  color: #1acb66 !important;
}

.el-submenu__title {
  font-weight: 700 !important;
}

.el-menu>li:nth-child(3) {
  font-weight: 700 !important;
}
.el-menu>li:nth-child(4) {
  font-weight: 700 !important;
}
.el-menu>li:nth-child(5) {
  font-weight: 700 !important;
}

</style>
<style>
.mx-datepicker-range {
  width: 240px !important;
}

.fixed-date {
  position: fixed;
  z-index: 999;
  background: #ffffff;
  top: 120px;
}

.user-selected-menu {
  color: #0A8DF2 !important;
  font-weight: 700;
}

.user-selected-menu i:nth-child(1) {
  color: #0A8DF2;
}

.menu-image {
  margin-right: 10px;
}
</style>
