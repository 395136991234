<template>
  <div class="header-inner">
    <div class="header-inner-content">
      <img class="logo-img" src="../../../img/pc/home/logo.png">
      <div v-show="menu.length > 0" class="logo-divider"></div>
      <div class="menu-text">{{menu}}</div>
      <img v-show="!isShowMenu" @click="onShowMenu" class="nav-menu" src="../../../img/mobile/nav_menu.png">
      <img v-show="isShowMenu" @click="onCloseMenu" class="nav-menu-close" src="../../../img/mobile/nav_menu_close.png">
    </div>
    <div class="header-menu" v-show="isShowMenu">
      <div @click="onClickNav(0)">首页</div>
      <div @click="onClickNav(1)">解决方案</div>
      <div @click="onClickNav(2)">客户案例</div>
      <div @click="onClickNav(3)">关于我们</div>
    </div>
  </div>
</template>

<script>
import PubSub from 'pubsub-js'

export default {
  name: "Header",
  data () {
    return {
      isShowMenu: false,
      menu: ''
    }
  },
  mounted () {
    PubSub.subscribe('menuName', (routerName, menuName) => {
      this.menu = menuName
    })
  },
  methods: {
    onClickNav (index) {
      if (this.activeIndex !== index) {
        this.onCloseMenu()
        switch(index) {
          case 1:
            this.$router.push({ path: '/solution' })
            break
          case 2:
            this.$router.push({ path: '/case' })
            break
          case 3:
            this.$router.push({ path: '/about' })
            break
          case 0:
            this.$router.push({ path: '/' })
            break
        }
      }
    },
    onShowMenu () {
      this.isShowMenu = true
    },
    onCloseMenu () {
      this.isShowMenu = false
    }
  }
}
</script>

<style scoped lang="scss">

.header-inner {
  display: flex;
  flex-direction: column;
  user-select: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 5002;
  width: 100%;

  .header-inner-content {
    background-color: #FFF;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .logo-img {
      width: 100px;
      object-fit: contain;
      margin-left: 16px;
    }

    .logo-divider {
      width: 1px;
      height: 17px;
      margin-left: 13px;
      margin-right: 12px;
      background-color: #8C8C8C;
    }

    .menu-text {
      font-size: 17px;
      color: #000000;
      line-height: 22px;
    }

    .nav-menu {
      width: 26px;
      height: 20px;
      margin-right: 25px;
      margin-left: auto;
    }

    .nav-menu-close {
      width: 18px;
      height: 18px;
      margin-right: 29px;
      margin-left: auto;
    }
  }

  .header-menu {
    height: calc(100vh - 60px);
    font-size: 17px;
    font-weight: bold;
    line-height: 22px;
    background-color: rgba($color: #000000, $alpha: 0.67);
    
    
    div {
      background-color: #FFF;
      text-align: center;
      height: 50px;
      line-height: 50px;
    }

    div:nth-of-type(4) {
      padding-bottom: 25px;
    }
  }
}

</style>
