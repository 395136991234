<template>
  <div class="body" style="display: flex; flex-direction: column" v-loading.body="$store.state.loading" element-loading-text="正在加载..." element-loading-background="transparent">
    <headers nav="管理后台"></headers>
    <div class="content" :style="{height:$store.state.showBottomBar?'calc(100% - 122px)':'calc(100% - 62px)'}">
      <div class="left">
        <div class="name-box">
          <img class="short-name-image" v-if="logoUrl" :src="logoUrl">
          <div class="short-name-box" v-else>
            <div class="short-name" >{{ shortName }}</div>
          </div>
          <div class="long-name">{{ name }}</div>
        </div>
        <div class="cate-box">
          <div class="cate-list">
            <el-menu ref="mainMenu" background-color="#F7F7F7" active-text-color="#0A8DF2" text-color="#6A6A6A" default-active="1-1" @select="onSelectMenu" unique-opened>
              <el-submenu index="1">
                <template slot="title">
                  <!-- <i class="el-icon-picture"></i> -->
                  <img v-show="!(selectedIndex === '1-1' || selectedIndex === '1-2' || selectedIndex === '1-3')" class="menu-image" src="../assets/images/menu/照片01.svg" />
                  <img v-show="(selectedIndex === '1-1' || selectedIndex === '1-2' || selectedIndex === '1-3')" class="menu-image" src="../assets/images/menu/照片02.svg" />
                  <span>照片管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="1-1">时间分类</el-menu-item>
                  <el-menu-item index="1-2">工作分类</el-menu-item>
                  <el-menu-item index="1-3">成员分类</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-submenu index="2">
                <template slot="title">
                  <!-- <i class="el-icon-user"></i> -->
                  <img v-show="!(selectedIndex === '2-1' || selectedIndex === '2-2')" class="menu-image" src="../assets/images/menu/团队01.svg" />
                  <img v-show="(selectedIndex === '2-1' || selectedIndex === '2-2') " class="menu-image" src="../assets/images/menu/团队02.svg" />
                  <span>团队管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item index="2-1">成员管理</el-menu-item>
                  <el-menu-item index="2-2">权限设置</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item index="3">
                <!-- <i class="el-icon-share"></i> -->
                <img v-show="selectedIndex !== '3'" class="menu-image" src="../assets/images/menu/闪电01.svg" />
                <img v-show="selectedIndex === '3'" class="menu-image" src="../assets/images/menu/闪电02.svg" />
                <span slot="title">闪电转发</span>
              </el-menu-item>
              <el-menu-item index="4">
                <img v-show="selectedIndex !== '4'" class="menu-image" src="../assets/images/menu/取信01.svg" />
                <img v-show="selectedIndex === '4'" class="menu-image" src="../assets/images/menu/取信02.svg" />
                <span slot="title">取信于客</span>
              </el-menu-item>
              <el-menu-item index="5">
                <img v-show="selectedIndex !== '5'" class="menu-image" src="../assets/images/menu/客服01.svg" />
                <img v-show="selectedIndex === '5'" class="menu-image" src="../assets/images/menu/客服02.svg" />
                <span slot="title">联系客服</span>
              </el-menu-item>
              <el-menu-item index="6">
                <img v-show="selectedIndex !== '6'" class="menu-image" src="../assets/images/menu/照片01.svg" />
                <img v-show="selectedIndex === '6'" class="menu-image" src="../assets/images/menu/照片02.svg" />
                <span slot="title">照片解封</span>
              </el-menu-item>
            </el-menu>
          </div>
        </div>
        <!-- <div class="cate-box">
          <div class="title">照片管理</div>
          <div class="cate-list">
            <div
                :class="activeCate === 'time' ? 'cate-item active' : 'cate-item'"
                @mouseenter="hoverActive('time')"
                @mouseout="hoverInactive"
                @click="selectCate('time')"
            >
              <img :src="timeIcon" alt="时间"/>
              <span>时间分类</span>
            </div>
            <div
                v-if="type === 'project'"
                :class="activeCate === 'work' ? 'cate-item active' : 'cate-item'"
                @mouseenter="hoverActive('work')"
                @mouseout="hoverInactive"
                @click="selectCate('work')"
            >
              <img :src="workIcon" alt="工作"/>
              <span>工作分类</span>
            </div>
            <div
                :class="
                activeCate === 'member' ? 'cate-item active' : 'cate-item'
              "
                @mouseenter="hoverActive('member')"
                @mouseout="hoverInactive"
                @click="selectCate('member')"
            >
              <img :src="memberIcon" alt="成员"/>
              <span>成员分类</span>
            </div>
          </div>
        </div> -->
      </div>
      <div class="main">
        <pictures v-if="showPictures"></pictures>
        <router-view v-if="!showPictures"></router-view>
      </div>
    </div>
    <div class="preview-btns" v-if="$store.state.showBottomBar">
      <button class="del-btn" @click="handleDeleteOne">删除</button>
      <button class="download-btn" @click="handleDownloadOne">下载</button>
    </div>
  </div>
</template>

<script>
import timeIconNormal from "../assets/images/calendar.png";
import timeIconActive from "../assets/images/calendar-active.png";
import workIconNormal from "../assets/images/tag.png";
import workIconActive from "../assets/images/tag-active.png";
import memberIconNormal from "../assets/images/member.png";
import memberIconActive from "../assets/images/member-active.png";
import Headers from "@/components/Headers";
import Pictures from "@/components/Pictures";
import {getApi, postApi} from "@/common";
import $ from 'jquery';
import PubSub from 'pubsub-js'

export default {
  name: "Team",
  components: {
    Pictures,
    Headers,
  },
  data() {
    return {
      showPictures: true,
      id: "",
      name: "",
      type: "",
      shortName: "",
      activeCate: "time",
      hoverActiveCate: "",
      logoUrl:"",
      selectedIndex: '1-1',
      pageSize: 30,
      pageNum: 0,
      pages: 0,
      total: 0
    };
  },
  computed: {
    timeIcon() {
      return this.activeCate === "time" || this.hoverActiveCate === "time"
          ? timeIconActive
          : timeIconNormal;
    },
    workIcon() {
      return this.activeCate === "work" || this.hoverActiveCate === "work"
          ? workIconActive
          : workIconNormal;
    },
    memberIcon() {
      return this.activeCate === "member" || this.hoverActiveCate === "member"
          ? memberIconActive
          : memberIconNormal;
    },
  },
  created() {
    const that = this
    // console.log("当前的Team：", this.$route.query);
    var query = JSON.parse(localStorage.getItem('camera_admin_team'))
    this.id = query.id;
    this.name = query.name;
    this.type = query.type;
    this.shortName = query.short_name;
    this.logoUrl = query.logo_url;
    this.activeCate = this.$store.state.category;
    
    this.$preview.on("close", function () {
      console.log("隐藏底部操作栏");
      that.$store.commit("hideBottomBar");
    });
  },
  mounted () {
    this.onSelectMenu('1-1')
  },
  methods: {
    handleDeleteOne() {
      const that = this;
      const picIndex = this.getCurrentIndex()
      const pic = this.$store.state.pictures[picIndex];
      console.log("当前的图片：", pic);
      this.$confirm("此操作将永久删除当前图片, 是否继续?", "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const deleteApi = this.apiBase + "/camera/admin/delete";
        postApi(
            deleteApi,
            {
              teamId: this.$store.state.teamId,
              imageId: pic.id,
            },
            function (res) {
              console.log("删除图片结果：", res);
              if (res.success === "1") {
                //1、从当前的图片中删除对应的图片
                that.$store.commit("deleteOnePicture", picIndex);
                //2、重新计算选中的图片
                const checkedPics = [];
                that.$store.state.pictures.forEach((p) => {
                  if (p.checked) {
                    checkedPics.push(p);
                  }
                });
                that.$store.commit("syncCheckedPictures", checkedPics);
                PubSub.publish('closePicList')
                PubSub.publish('reloadPic')
                that.$message.info("图片删除成功");
              } else {
                that.$message.error(res.message);
              }
            }
        );
      });
    },
    handleDownloadOne() {
      const that = this;
      const picIndex = that.getCurrentIndex()
      console.log("当前的预览图片索引：", picIndex);
      const pic = this.$store.state.pictures[picIndex];
      console.log("当前的图片：", pic);
      const token = localStorage.getItem('camera_admin_user_token')
      // window.open(this.apiBase + '/camera/image/download?imageId=' + pic.id + '&token=' + token)

      $('<form method="post" action="' + this.apiBase + '/camera/image/download' + '" target="_self"> \
          <input type="hidden" name="imageId" value="' + pic.id + '"> \
          <input type="hidden" name="token" value="' + token + '"> \
          </form>').appendTo('body').submit().remove()
      
    },
    hoverActive(cate) {
      this.hoverActiveCate = cate;
    },
    hoverInactive() {
      this.hoverActiveCate = "";
    },
    selectCate(cate) {
      this.activeCate = cate;
      this.$store.commit("syncCategory", cate);
      if (cate === "time") {
        this.searchTimePics();
      } else if (cate === "member") {
        this.searchMemberPics();
      } else {
        this.searchWorkPics();
      }
    },
    searchWorkPics() {
      const that = this;
      getApi(
          this.apiBase +
          "/camera/admin/v2/labels?pageSize=30&pageNum=1&teamId="
          + this.$store.state.teamId,
          function (res) {
            if (res.success === "1") {
              that.$store.commit("syncFolders", {
                folders: res.content.list,
                total: res.content.total,
                folderLevel: 1,
              });
            } else {
              that.$message.error(res.message);
            }
          }
      );
    },
    searchMemberPics() {
      const that = this;
      getApi(
          this.apiBase +
          "/camera/admin/v2/users?pageSize=30&pageNum=1&teamId=" +
          this.$store.state.teamId,
          function (res) {
            if (res.success === "1") {
              that.$store.commit("syncFolders", {
                folders: res.content.list,
                total: res.content.total,
                folderLevel: 1,
              });
            } else {
              that.$message.error(res.message);
            }
          }
      );
    },
    searchTimePics() {
      const that = this;
      const end = new Date();
      const start = new Date();
      start.setDate(1)
      const startDate = start.format("yyyy-MM-dd")
      const endDate = end.format("yyyy-MM-dd")
      const params =
          "teamId=" +
          this.$store.state.teamId +
          "&start=" + startDate +
          "&end=" + endDate +
          '&pageSize=30&pageNum=1'
      getApi(this.apiBase + "/camera/admin/v2/timeImages?" + params, function (
          res
      ) {
        console.log("查询结果：", res);
        if (res.success === "1") {
          that.$store.commit("syncPictures", {
            pictures: res.content.list,
            total: res.content.total
          });
        } else {
          that.$message.error(res.message);
        }
      });
    },
    getCurrentIndex() {
      var canvas = document.querySelector('.el-image-viewer__canvas')
      if (!canvas) {
        return -1
      }
      var arr = canvas.innerHTML.split('<!')
      for (var index = 0; index < arr.length; index++) {
        if (arr[index].length > 10) {
          return this.$store.state.picIndex + index
        }
      }

      return -1
    },
    onSelectMenu (index) {
      this.selectedIndex = index
      this.$nextTick(()=>{
        $('.el-submenu__title.user-selected-menu').removeClass('user-selected-menu')
        $('.el-submenu.is-active.is-opened .el-submenu__title').addClass('user-selected-menu')
      })
      switch(index) {
        case '1-1':
          this.showPictures = true
          this.selectCate('time')
          return
        case '1-2':
          this.showPictures = true
          this.selectCate('work')
          return
        case '1-3':
          this.showPictures = true
          this.selectCate('member')
          return
        case '2-1':
          this.showPictures = false
          this.$router.push({ name: 'user-manage'})
          return
        case '2-2':
          this.showPictures = false
          this.$router.push({ name: 'auth-setting' })
          return
        case '3':
          this.$refs.mainMenu.close('1')
          this.$refs.mainMenu.close('2')
          this.showPictures = false
          this.$router.push({ name: 'forward-list' })
          return
        case '4':
          this.$refs.mainMenu.close('1')
          this.$refs.mainMenu.close('2')
          this.showPictures = false
          this.$router.push({ name: 'preview-permission' })
          return
        case '5':
          this.$refs.mainMenu.close('1')
          this.$refs.mainMenu.close('2')
          this.showPictures = false
          this.$router.push({ name: 'customer-service' })
          return
        case '6':
        this.$refs.mainMenu.close('1')
        this.$refs.mainMenu.close('2')
        this.showPictures = false
        this.$router.push({ name: 'restore' })
        return
      }
    }
  },
};
</script>

<style scoped lang="scss">
.body {
  height: 100%;
  display: flex;
  align-items: center;

  .content {
    display: flex;
    // margin-top: 62px;
    width: 100%;

    .left {
      width: 200px;
      height: 100%;
      background-color: #f7f7f7;

      .name-box {
        margin-left: 24px;
        padding-top: 24px;
        border-radius: 3px;

        .short-name-box {
          height: 56px;
          width: 56px;
          background: #3ca641;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;

          .short-name {
            width: 45px;
            height: 45px;
            font-size: 21px;
            line-height: 22px;
            color: #ffffff;
            text-align: center;
          }

        }
        .short-name-image {
          height: 56px;
          width: 56px;
          border-radius: 1px;
          object-fit:cover
        }

        .long-name {
          font-size: 14px;
          color: #6a6a6a;
          margin-top: 15px;
        }
      }

      .cate-box {
        .title {
          padding-top: 30px;
          font-size: 12px;
          color: #b5b4bb;
          padding-left: 24px;
          padding-bottom: 20px;
        }

        .cate-list {
          display: flex;
          flex-direction: column;

          .cate-item {
            padding-left: 24px;
            height: 50px;
            background-color: #f7f7f7;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
              width: 16px;
              margin-right: 10px;
              pointer-events: none;
            }

            span {
              flex: 1;
              font-size: 14px;
              color: #6a6a6a;
              pointer-events: none;
            }
          }

          .cate-item.active {
            background: #ffffff;

            span {
              color: #0a8df2;
            }
          }

          .cate-item:hover {
            background: #ffffff;

            span {
              color: #0a8df2;
            }
          }
        }
      }
    }

    .main {
      flex: 1;
    }
  }
}
</style>
