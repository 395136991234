<template>
  <div class="banner-detail-3-inner">
    <div class="first-row">
      <img src="../../../img/mobile/shandianzhuanfa.png">
      <div class="content">
        <div class="title">闪电转发</div>
        <div class="desc">拍照自动转发，支持钉钉群、企业微信、企业内部管理系统。</div>
      </div>
    </div>
    <div class="third-row">
      <div class="customer-case">
        <div class="case">
          <div class="intro">
            <p>拍照自动转发钉钉群</p>
            <p>手动转发照片太繁琐太多，不如创建团队使用闪电转发，拍照自动转发至钉钉群；即拍即传，高效及快捷，减少其他操作。</p>
          </div>
          <img src="../../../img/pc/home/dingding.png"/>
        </div>
        <div class="case" style="background: #f4f4f4;">
          <div class="intro">
            <p>拍照自动转发企业微信群</p>
            <p>每日汇报工作或考勤打卡时，手动转发照片太繁琐，使用闪电转发，拍照自动转发至企业微信群；即拍即传，高效及便捷，减少其他操作。</p>
          </div>
          <img src="../../../img/pc/home/qiyeweixin.png"/>
        </div>
        <div class="case">
          <div class="intro">
            <p>拍照自动转发企业内部管理系统</p>
            <p>工作拍照记录存储在云端，并会自动同步到企业内部管理系统。新成员加入群组时可随时随地查看历史记录，快速融入团队和项目，降低企业沟通成本，提升上手效率。</p>
          </div>
          <img src="../../../img/pc/home/qiyeguanlixitong.png"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PubSub from 'pubsub-js'

export default {
  name: "BannerDetail3",
  mounted () {
    PubSub.publish('changeHeaderIndex', 103)
    PubSub.publish('menuName', '')
  }
}

</script>

<style scoped lang="scss">

.banner-detail-3-inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  .first-row {
    // display: flex;
    // justify-content: center;
    // overflow: hidden;
    position: relative;
    padding: 0 17px;
    box-sizing: border-box;

    img {
      width: 100%;
    }

    .content {
      display: flex;
      flex-direction: column;
      color: #FFF;
      position: absolute;

      top: 27px;
      left: 40px;

      .title {
        font-size: 28px;
        text-align: left;
        line-height: 37px;
      }

      .desc {
        width: 220px;
        font-size: 13px;
        font-weight: 500;
        line-height: 17px;
        margin-top: 6px;
      }
  }
  }

  .third-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .customer-case {
      display: flex;
      flex-direction: column;
      width: 100%;

      .case {
        display: flex;
        flex-direction: column;
        padding: 34px 17px 40px 17px;
        box-sizing: border-box;

        img {
          max-width: 70%;
          align-self: center;
          margin-top: 30px;
        }

        .intro {
          display: flex;
          flex-direction: column;

          p {
            margin: 0px;
          }

          p:nth-of-type(1) {
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            text-align: center;
          }

          p:nth-of-type(2) {
            font-size: 15px;
            font-weight: 300;
            line-height: 21px;
            margin-top: 15px;
          }
        }
      }
    }
  }
}

</style>
