<template>
  <div class="about-inner">
    <div class="first-row">
      <img class="about-bg" src="../../../img/pc/home/guanyu_pic.png">
      <span>公司介绍</span>
      <div class="company-desc">工作蜂是由北京自由视觉科技有限公司研发出品，公司简称“自由视觉”于 2020 年 6 月成立，是一家面向智能化的软件服务平台。旗下业务覆盖工具、商务办公等领域，核心产品包括工作蜂、工程水印相机等。
        <br/>自由视觉的核心战略是专注“智能化”。获得过中关村高新技术企业认证。我们的使命是“以技术为基础，为工作赋能，提高效率”。我们旨在为4亿蓝领用户提供数字化办公服务，成为一个中国互联网界极具特色的科技企业。</div>
      <span>权威认证</span>
      <div class="desc">
        <div>
          <img src="../../../img/pc/home/gongzheng_pic.png"/>
          <p>公证处公正</p>
          <p>时间无法修改</p>
        </div>
        <div>
          <img src="../../../img/pc/home/zhongugancun.png"/>
          <p>中关村高新技术企业认证</p>
          <p>技术创新</p>
        </div>
        <div>
          <img src="../../../img/pc/home/guojiabanquanju.png"/>
          <p>国家版权局</p>
          <p>软件著作权登记证书</p>
        </div>
        <div>
          <img src="../../../img/pc/home/zhuanli.png"/>
          <p>国家知识产权局</p>
          <p>实用新型专利证书</p>
        </div>
      </div>
    </div>
    <div class="second-row">
      <div class="second-sub-row">
        <div class="subtitle">商务合作</div>
        <div class="intro-list">
          <div style="margin-right:30px;">
            <img class="about-icon" src="../../../img/pc/home/about_wx.png" />
            <div>
              <p>微信号：</p>
              <p>dakaxiangji01</p>
            </div>
            <img class="about-qr" src="../../../img/pc/home/about_qr.png" />
          </div>
          <div>
            <img class="about-icon" src="../../../img/pc/home/about_email.png" />
            <div>
              <p>邮箱号：</p>
              <p>dakaxiangji@126.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PubSub from 'pubsub-js'

export default {
  name: "About",
  mounted () {
    PubSub.publish('changeHeaderIndex', 3)
    PubSub.publish('menuName', '关于我们')
  }
}

</script>

<style scoped lang="scss">

.about-inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  .first-row {
    display: flex;
    align-items: center;
    flex-direction: column;

    .about-bg {
      width: 1424px;
      height: 460px;
      margin-bottom: 43px;
    }

    span {
      font-size: 40px;
      font-weight: 500;
      text-align: center;
      color: #000000;
      line-height: 52px;
      text-shadow: 1px 0px #000;
    }

    .company-desc {
      text-indent: 2em;
      width: 1174px;
      font-size: 20px;
      font-weight: 300;
      line-height: 28px;
      color: #000000;
      margin-top: 30px;
      margin-bottom: 63px;
    }

    .desc {
      display: flex;
      width: 1020px;
      justify-content: space-between;
      margin-top: 55px;
      margin-bottom: 54px;
      text-align: center;

      img {
        margin-bottom: 20px;
        // width: 100%;
        height: 80px;
      }

      p {
        margin: 0;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        color: #000000;
        line-height: 28px;
      }
    }
  }

  .second-row {
    background-color: #f4f4f4;
    width: 100%;
    height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 52px 0 88px 0;
    box-sizing: border-box;
    margin-bottom: 64px;

    .second-sub-row {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 25px;
        font-weight: 600;
        text-align: center;
        color: #000000;
        line-height: 36px;
      }

      .subtitle {
        font-size: 40px;
        font-weight: 500;
        text-align: center;
        color: #000000;
        line-height: 52px;
        margin-top: 7px;
      }

      .intro-list {
        margin-top: 88px;
        display: flex;

        div {
          display: flex;
          width: 547px;
          height: 140px;
          background: #ffffff;
          border-radius: 10px;
          align-items: center;

          div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          }

          .about-icon {
            width: 80px;
            height: 80px;
            margin-left: 30px;
            margin-right: 25px;
          }

          .about-qr {
            width: 120px;
            height: 120px;
            margin-right: 10px;
            margin-left: auto;
          }

          p {
            margin: 0;
            text-align: left;
          }

          p:nth-of-type(1) {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
          }

          p:nth-of-type(2) {
            font-size: 30px;
            font-weight: 500;
            line-height: 42px;
          }
        }
      }
    }
  }
}

</style>
