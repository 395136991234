<template>
  <div class="footer-inner">
    <div class="third-row">
      <p><router-link class="desc" tag="a" target="_blank" :to="{name:'privacy-policy'}">隐私政策</router-link> | <router-link class="desc" tag="a" target="_blank" :to="{name:'user-license'}">用户协议</router-link></p>
      <p>工作蜂Ⓒ版权公告© 2020-2021 北京自由视觉科技有限公司</p>
      <p><a class="copyright" href="https://beian.miit.gov.cn/" target="_blank">京ICP备2020036458号</a></p>
    </div>
  </div>
</template>

<script>

export default {
  name: "Footer",
  methods: {
    onClickNav (index) {
      switch(index) {
        case 1:
          this.$router.push({ path: '/solution' })
          break
        case 2:
          this.$router.push({ path: '/case' })
          break
        case 3:
          this.$router.push({ path: '/about' })
          break
        case 0:
          this.$router.push({ path: '/' })
          break
      }
    }
  }
}
</script>

<style scoped lang="scss">

.footer-inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f4f4f4;
  user-select: none;
  z-index: 5000;
  width: 100%;
  flex-shrink: 0;

  span:nth-child(1) {
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    margin-top: 19px;
    margin-bottom: 17px;
  }

  .third-row {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    margin-bottom: 89px;
    background-color: #f4f4f4;

    p {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    p:nth-child(1) {
      margin-top: 26px;
      margin-bottom: 18px;
    }

    p:nth-child(2) {
      margin-bottom: 8px;
    }

    a {
      color: #0189ff;
      text-decoration: none;
    }

    .copyright {
      color: #000;
      text-decoration: none;
    }
  }
}

</style>
