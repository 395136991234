<template>
  <div class="body">
    <headers nav="管理后台"></headers>
    <div class="team-box">
      <div class="title">我的团队</div>
      <div class="subtitle">仅显示您拥有管理权限的团队</div>
      <div class="line"></div>
      <div class="team-list">
        <div v-for="(t,index) in $store.state.teams" :key="t.id" :class="t.active ? 'team-item active':'team-item'"
             @mouseenter.stop="hoverTeam(index)" @mouseout.stop="hoverTeam(index)"
             @click="viewTeam(index)">
          <div class="name-box" :style="{background:t.logoColour}">
            <div v-if="!t.logoUrl" class="short-name">{{ t.shortName }}</div>
            <img v-if="t.logoUrl" class="logo-image" :src="t.logoUrl + '?x-oss-process=image/resize,m_lfit,h_70,w_70'">
          </div>
          <div class="long-name">{{ t.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {isLogin} from "@/common";
import Headers from "@/components/Headers";

export default {
  name: "Teams",
  created() {
    localStorage.removeItem('camera_admin_team')
    console.log('当前的团队列表：', this.$store.state.teams)
    const islogin = isLogin()
    if (!islogin) {
      this.$router.push('/login');
    }
  },
  components: {
    Headers,
  },
  methods: {
    hoverTeam(index) {
      const newTeam = this.$store.state.teams[index]
      newTeam.active = !newTeam.active
      this.$store.commit('activeTeam', {
        index,
        team: newTeam
      })
    },
    viewTeam(index) {
      const team = this.$store.state.teams[index]
      console.log('当前选择的团队：', team)
      localStorage.setItem('camera_admin_team', JSON.stringify({
          id: team.id,
          type: team.type,
          name: team.name,
          short_name: team.shortName,
          logo_url:team.logoUrl
        }));
      this.$router.push({
        path: '/team',
        // query: {
        //   id: team.id,
        //   type: team.type,
        //   name: team.name,
        //   short_name: team.shortName,
        //   logo_url:team.logoUrl
        // }
      });
    }
  }
}
</script>

<style scoped lang="scss">

.body {
  height: 100%;
  display: flex;
  flex-direction: column;

  .team-box {
    margin: 0 calc(216px + (100% - 1440px) / 2);
    flex-grow: 1;
    // width: 574px;
    // background: #FAFAFB;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // box-shadow: 0 8px 28px 0 rgba(115, 133, 169, 0.13);
    // border-radius: 8px;

    .title {
      line-height: 40px;
      font-size: 28px;
      margin-top: 100px;
      font-weight: 700;
    }

    .subtitle {
      font-size: 15px;
      line-height: 21px;
      margin-top: 9px;
    }

    .line {
      height: 1px;
      background-color: #D8D8D8;
      margin-top: 27px;
    }

    .team-list::-webkit-scrollbar {
      display: none;
    }

    .team-list {
      display: flex;
      height: calc(100vh - 300px);
      overflow-y: auto;
      flex-wrap: wrap;
      margin-top: 5px;
      margin-bottom: 20px;
      align-content: flex-start;

      .team-item:hover>.long-name {
        // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.21);
        color: #2298F3;
      }

      .team-item {
        display: flex;
        width: calc(100% / 3 - 10px);
        align-items: center;
        height: 80px;
        border-radius: 6px;
        background: #ffffff;
        margin-top: 20px;
        cursor: pointer;

        .name-box {
          height: 70px;
          width: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          // margin-left: 16px;
          margin-right: 16px;
          border-radius: 10px;
          pointer-events: none;

          .logo-image {
            width: 70px;
            height: 70px;
            border-radius: 10px;
            background-color: #D8D8D8;
          }

          .short-name {
            // height: 80px;
            width: 50px;
            // font-weight: 40px;
            // line-height: 40px;
            color: #ffffff;
            font-size: 20px;
            padding: 0;
            text-align: center;
            font-weight: 700;
            pointer-events: none;
          }
        }

        .long-name {
          // flex: 1;
          font-size: 20px;
          font-weight: 700;
          word-break: break-all;
          pointer-events: none;
          flex-shrink: 1;
          max-width: 170px;
        }
      }
      .team-item:not(:nth-child(3n)) {
        margin-right: 15px;
      }
    }
  }
}
</style>
