<template>
  <div class="mobile-inner">
    <Header></Header>
    <router-view class="router-view" name="mobile"></router-view>
    <Footer></Footer>
    <div class="download_tip">
      <img src="../../img/mobile/download_icon.png"/>
      <div class="desc">
        <p>工作蜂</p>
        <p>工作拍照，真实无修改</p>
      </div>
      <div class="download_btn" @click="onClickDownload">立即下载</div>
    </div>
  </div>
</template>

<script>

import Header from './mobile/Header.vue'
import Footer from './mobile/Footer.vue'

export default {
  name: "Mobile",
  components: {
    Header,
    Footer
  },
  mounted () {
  },
  methods: {
    onClickDownload () {
      window.open('https://a.app.qq.com/o/simple.jsp?pkgname=com.camera.dakaxiangji', '_blank');
    }
  }
}
</script>

<style scoped lang="scss">

.mobile-inner {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
  min-width: min-content;
  position: relative;

  .router-view {
    // margin-top:94px;
    flex-grow:1;
    padding: 60px 0 0 0;
    box-sizing: border-box;
    flex-shrink: 0;
  }

  .download_tip {
    display: flex;
    align-items: center;
    height: 68px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 5001;
    background-color: #FFF;
    width: 100%;

    img {
      width: 50px;
      height: 50px;
      margin-left: 10px;
      margin-right: 12px;
    }

    .desc {
      display: flex;
      flex-direction: column;

      p {
        margin: 0;
      }

      p:nth-of-type(1) {
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
      }

      p:nth-of-type(2) {
        font-size: 13px;
        font-weight: 500;
        color: #656565;
        line-height: 19px;
      }
    }

    .download_btn {
      width: 100px;
      height: 34px;
      background: #0189FF;
      border-radius: 3px;
      font-size: 15px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 34px;
      text-align: center;
      margin-right: 18px;
      margin-left: auto;
    }
  }
}

</style>
