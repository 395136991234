<template>
  <div class="forward-guide-inner">
    <img src="../assets/images/webhook-guide.png" class="guide-image"/>
  </div>
</template>

<script>

// import {getApi} from "@/common"

export default {
  name: "forward-guide",
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">

.forward-guide-inner {
  width: 100%;
  display: flex;
  justify-content: center;

  .guide-image {
    margin: 40px 0;
  }
}

</style>
