<template>
  <div class="banner-detail-1-inner">
    <div class="first-row">
      <img src="../../../img/mobile/gongzuozhenshipaizhao.png">
      <div class="content">
        <div class="title">工作真是拍照</div>
        <div class="desc">百万用户、数万团队，都用工作蜂数字化办公平台</div>
      </div>
    </div>
    <div class="third-row">
      <div class="customer-case">
        <div class="case">
          <div class="intro">
            <p>水印真实拍照，自动上传</p>
            <p>自动通过网络校准时间，无滤镜、无美颜，真实拍照，时间地点防修改。</p>
          </div>
          <img src="../../../img/pc/home/shuiyinpaizhao.png"/>
        </div>
        <div class="case" style="background: #f4f4f4;">
          <div class="intro">
            <p>自定义分类存储，无限空间</p>
            <p>拍照自动上传至团队，自定义相册分类存储无限存储空间，长期保存归档方便查看，支持批量导出。</p>
          </div>
          <img src="../../../img/pc/home/zidingyifenlei1.png"/>
        </div>
        <div class="case">
          <div class="intro">
            <p>实时考勤统计</p>
            <p>拍照自动生成考勤数据，可查看团队实时、周月统计数据。</p>
          </div>
          <img src="../../../img/pc/home/kaoqintongji.png"/>
        </div>
        <div class="case" style="background: #f4f4f4;">
          <div class="intro">
            <p>拍照自动转发</p>
            <p>支持钉钉群、企业微信群、企业内部管理系统，无需更换平台即可完成照片同步。</p>
          </div>
          <img src="../../../img/pc/home/shandianzhuanfa.png"/>
        </div>
        <div class="case">
          <div class="intro">
            <p>多平台协作</p>
            <p>工作蜂APP、工作蜂小程序、工作蜂管理后台，实时同步，提高团队协作效率。</p>
          </div>
          <img src="../../../img/pc/home/duopingtai.png"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PubSub from 'pubsub-js'

export default {
  name: "BannerDetail1",
  mounted () {
    PubSub.publish('changeHeaderIndex', 101)
    PubSub.publish('menuName', '')
  }
}

</script>

<style scoped lang="scss">

.banner-detail-1-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .first-row {
    // display: flex;
    // justify-content: center;
    position: relative;
    padding: 0px 17px;
    box-sizing: border-box;

    img {
      width: 100%;
    }

    .content {
      display: flex;
      flex-direction: column;
      color: #FFF;
      position: absolute;
      top: 27px;
      left: 40px;

      .title {
        font-size: 28px;
        text-align: left;
        line-height: 37px;
      }

      .desc {
        width: 220px;
        font-size: 13px;
        font-weight: 500;
        line-height: 17px;
        margin-top: 6px;
      }
    }
  }

  .third-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .customer-case {
      display: flex;
      flex-direction: column;
      width: 100%;

      .case {
        display: flex;
        flex-direction: column;
        padding: 34px 17px 40px 17px;
        box-sizing: border-box;

        img {
          max-width: 100%;
          margin-top: 30px;
        }

        .intro {
          display: flex;
          flex-direction: column;

          p {
            margin: 0px;
          }

          p:nth-of-type(1) {
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            text-align: center;
          }

          p:nth-of-type(2) {
            font-size: 15px;
            font-weight: 300;
            line-height: 21px;
            margin-top: 15px;
          }
        }
      }
    }
  }
}

</style>
