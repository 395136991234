<template>
  <div class="preview-permission-inner">
    <div v-if="!allow" class="reject-text">
      未获得该功能使用的权限，请联系官方客服！
    </div>
    <div v-if="allow" class="permission-panel">
      <div class="permission-operator">
        <div class="desc">
          <div class="header">团队拍照预览权限</div>
          <div class="content">允许非团队用户在微信小程序查看拍照</div>
        </div>
        <el-switch class="permission-switch" v-model="isOpen" active-color="#0A8DF2" inactive-color="#BBBBBC" @change="onChangePermission"></el-switch>
      </div>
      <div ref="qrcode" class="qrcode" v-if="isOpen" id="qrcode">
        <div class="name-box" :style="{background:teamInfo.logoColour}">
            <div v-if="!teamInfo.logoUrl" class="short-name">{{ teamInfo.shortName }}</div>
            <img v-if="teamInfo.logoUrl" class="logo-image" :src="teamInfo.logoUrl + '?x-oss-process=image/resize,m_lfit,h_60,w_60'">
          </div>
          <div class="long-name">{{ teamInfo.name }}</div>
        <img v-if="appletsUrl" class="qr-image" :src="appletsUrl + '?x-oss-process=image/resize,m_lfit,h_200,w_200' +'&'+new Date().getTime()" crossOrigin="anonymous"/>
        <img v-if="!appletsUrl" class="qr-image" />
        <div class="desc">打开微信扫描</div>
        <div class="desc">进入小程序浏览照片</div>
      </div>
      <button v-if="isOpen" class="download-btn" @click="downloadApplets()">下载小程序码</button>
    </div>
  </div>
</template>

<script>

import { postApi} from "@/common"
import html2canvas from 'html2canvas'

export default {
  name: "preview-permission",
  data() {
    return {
      isOpen: false,
      allow: false,
      teamId: null,
      appletsUrl: '',
      teamInfo: {
        logoColour: '#D8D8D8'
      }
    }
  },
  // computed: {
  //   isOpen () {
  //     return this.appletsUrl.length > 0
  //   }
  // },
  mounted() {
    var team = JSON.parse(localStorage.getItem('camera_admin_team'))
    this.teamId = team.id
    this.loadTeamInfo()
  },
  methods: {
    loadTeamInfo () {
      var self = this
      self.$store.commit('showLoading')
      postApi(this.apiBase + '/camera/admin/teamInfo', { teamId: this.teamId }  , res => {
        self.$store.commit('hideLoading')
        if (res.success === '1') {
          self.allow = res.content.isPublicSystem === 1
          self.appletsUrl = res.content.appletsUrl
          self.isOpen = res.content.isPublicApplets === 1
          self.teamInfo = res.content
        } else {
          self.$message.error(res.message);
        }
      })
    },
    onChangePermission (val) {
      var self = this
      self.$store.commit('showLoading')
      postApi(this.apiBase + '/camera/team/applets', { teamId: this.teamId, isPublic: val ? 'yes' : 'no' }, res => {
        self.$store.commit('hideLoading')
        if (res.success === '1') {
          if (res.content) {
            self.appletsUrl = res.content
          }
        } else {
          self.$message.error(res.message);
        }
      })
    },
    downloadApplets () {
      // var self = this
      if (this.appletsUrl) {
        html2canvas(document.getElementById('qrcode'), { useCORS: true}).then(function(canvas) {
          // let ctx = canvas.getContext('2d')
          // let img = new Image()
          // img.crossOrigin = 'Anonymous'
          // img.src = self.teamInfo.logoUrl + '?x-oss-process=image/resize,m_lfit,h_60,w_60'
          // img.onload = function() {
          //   ctx.drawImage(img, 138.5, 15, 60, 60)
          // }
          // let qrimg = new Image()
          // qrimg.crossOrigin = 'Anonymous'
          // qrimg.src = self.appletsUrl + '?x-oss-process=image/resize,m_lfit,h_200,w_200'
          // qrimg.onload = function() {
          //   ctx.drawImage(qrimg, 68.5, 117.5, 200, 200)
          // }
          let link = document.createElement('a')
          link.href = canvas.toDataURL('image/png')
          link.download = '小程序码.png'
          link.click()
        })
      } else {
        this.$message.error('无法下载小程序码')
      }
    },
    getUrlBase64(url) {
      return new Promise(resolve => {
        let canvas = document.createElement('canvas')
        let ctx = canvas.getContext('2d')
        let img = new Image()
        img.crossOrigin = 'Anonymous'
        img.src = url
        img.onload = function() {
          canvas.height = 430
          canvas.width = 430
          ctx.drawImage(img, 0, 0, 430, 430)
          let dataURL = canvas.toDataURL('image/png')
          canvas = null
          resolve(dataURL)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">

.preview-permission-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 19px;

  .reject-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    margin-top: 36px;
    margin-left: 40px;
  }

  .permission-panel {

    .permission-operator {
      margin-top: 20px;
      margin-left: 20px;
      width: 337px;
      height: 70px;
      box-sizing: border-box;
      border: solid 1px #D6D4D4;
      display: flex;
      align-items: center;

      .desc {
        display: flex;
        flex-direction: column;
        margin-left: 20px;

        .header {
          font-size: 14px;
          line-height: 18px;
          font-weight: 700;
        }

        .content {
          font-size: 12px;
          line-height: 16px;
          color: #666;
          margin-top: 5px;
        }
      }

      .permission-switch {
        margin-right: 10px;
        margin-left: auto;
      }
    }

    .qrcode {
      margin-top: 20px;
      margin-left: 20px;
      width: 337px;
      height: 398px;
      box-sizing: border-box;
      border: solid 1px #D6D4D4;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #666;

      .name-box {
          height: 60px;
          width: 60px;
          margin-top: 15px;
          display: flex;
          align-items: center;
          justify-content: center;

          .logo-image {
            width: 60px;
            height: 60px;
            border-radius: 2px;
            background-color: #D8D8D8;
          }

          .short-name {
            width: 50px;
            color: #ffffff;
            font-size: 20px;
            padding: 0;
            text-align: center;
            font-weight: 700;
            pointer-events: none;
          }
        }

        .long-name {
            margin-top: 5px;
            font-size: 14px;
            line-height: 18px;
          }
      
      .qr-image {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .desc {
        font-size: 16px;
        line-height: 21px;
      }
    }

    .download-btn {
      width: 200px;
      height: 32px;
      background-color: #0A8DF2;
      border-radius: 2px;
      box-sizing: border-box;
      border: solid 1px #0A8DF2;
      color: #FFF;
      cursor: pointer;
      margin-left: 88.5px;
      margin-top: 20px;
    }

  }
}

</style>
