<template>
  <div class="banner-detail-2-inner">
    <div class="first-row">
      <img src="../../../img/pc/home/banner_detail_2.png">
      <div class="content">
        <div class="title">多平台协作</div>
        <div class="desc">工作蜂APP、工作蜂小程序、工作蜂管理后台，实时同步，提高团队协作效率。</div>
      </div>
    </div>
    <div class="third-row">
      <div class="customer-case">
        <div class="case">
          <img style="margin-right:150px;" src="../../../img/pc/home/duopingtai_app.png"/>
          <div class="intro">
            <p>工作蜂(手机应用)</p>
            <p>员工：工作拍照、拍视频加水印，真实拍照时间地点无法修改。<br/>管理：创建团队，拍照自动上传至团队；还可以自动转发至钉钉群、企业微信、企业内部管理系统。实时、周月统计团队考勤，管理导出更方便。</p>
          </div>
        </div>
        <div class="case" style="background: #f4f4f4;">
          <div class="intro">
            <p>工作蜂(微信小程序)</p>
            <div>管理专用</div>
            <p>管理员无需安装APP，使用小程序即可实时查看团队工作日报、团队每日考勤数据和管理团队。发送团队的消息快捷必达。</p>
          </div>
          <img style="margin-left:150px;" src="../../../img/pc/home/duopingtai_xiaochengxu.png"/>
        </div>
        <div class="case">
          <img style="margin-right:150px;" src="../../../img/pc/home/duopingtai_guanlihoutai.png"/>
          <div class="intro">
            <p>工作蜂管理后台</p>
            <div>管理专用</div>
            <p>访问地址：www.dakaxiangji.cn。管理员可以通过电脑浏览器访问管理管理后台团队，可以批量导出工作拍照内容。</p>
          </div>
        </div>
      </div>
    </div>
    <sub-footer></sub-footer>
  </div>
</template>

<script>

import SubFooter from './SubFooter.vue'
import PubSub from 'pubsub-js'

export default {
  name: "BannerDetail2",
  components: {
    SubFooter
  },
  mounted () {
    PubSub.publish('changeHeaderIndex', 102)
    PubSub.publish('menuName', '')
  }
}

</script>

<style scoped lang="scss">

.banner-detail-2-inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  .first-row {
    position: relative;
    width: 1424px;
    display: flex;
    justify-content: center;
    overflow: hidden;

    img {
      width: 1424px;
      height: 460px;
    }

    .content {
      display: flex;
      flex-direction: column;
      color: #FFF;
      position: absolute;
      top: 95px;
      left: 120px;

      .title {
        font-size: 50px;
        text-align: left;
        line-height: 70px;
      }

      .desc {
        width: 345px;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        margin-top: 20px;
      }
  }
  }

  .third-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .customer-case {
      display: flex;
      flex-direction: column;
      width: 100%;

      .case {
        display: flex;
        height: 434px;
        justify-content: center;
        align-items: center;

        img {
          height: 400px;
        }

        .intro {
          display: flex;
          flex-direction: column;
          width: 450px;

          div {
            width: 94px;
            height: 32px;
            background: #f35426;
            border-radius: 5px;
            font-size: 20px;
            font-weight: 600;
            color: #ffffff;
            line-height: 28px;
            text-align: center;
            margin-bottom: 3px;
            margin-top: 15px;
          }

          p {
            margin: 0px;
          }

          p:nth-of-type(1) {
            font-size: 30px;
            font-weight: 500;
            line-height: 42px;
            text-shadow: 1px 0px #000;
          }

          p:nth-of-type(2) {
            font-size: 20px;
            font-weight: 300;
            line-height: 28px;
            margin-top: 15px;
          }
        }
      }
    }
  }
}

</style>
