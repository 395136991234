import $ from 'jquery'
import router from "@/router";

function postApi(api, data, callback) {
  const token = localStorage.getItem('camera_admin_user_token');
  $.ajax(api, {
    headers: {
      "token": token
    },
    method: 'POST',
    dataType: 'json',
    data: data,
    success: function (data) {
      if (data.success === "111111") {
        localStorage.removeItem('camera_admin_user_info')
        localStorage.removeItem('camera_admin_user_token')
        localStorage.removeItem('camera_admin_user_expire_at')
        router.push({
          path: '/login'
        })
      } else {
        if (callback) {
          callback(data)
        }
      }
    }
  })
}

function getApi(api, callback) {
  const token = localStorage.getItem('camera_admin_user_token');
  $.ajax(api, {
    headers: {
      "token": token
    },
    method: 'GET',
    dataType: 'json',
    success: function (data) {
      if (data.success === "111111") {
        localStorage.removeItem('camera_admin_user_info')
        localStorage.removeItem('camera_admin_user_token')
        localStorage.removeItem('camera_admin_user_expire_at')
        router.push({
          path: '/login'
        })
      } else {
        if (callback) {
          callback(data)
        }
      }
    }
  })
}

function isLogin() {
  //判断用户是否登录，未登录的话跳转到登录页面
  let isLogin = false
  let userInfo = localStorage.getItem('camera_admin_user_info');
  let token = localStorage.getItem('camera_admin_user_token');
  let expireAt = localStorage.getItem('camera_admin_user_expire_at');
  if (expireAt && token && userInfo) {
    let expireTime = parseInt(expireAt);
    if (new Date().getTime() < expireTime) {
      isLogin = true;
    }
  }
  return isLogin;
}

function toBigImgUrl(url){
  // xxxx?x-oss-process=style/
  var sepIndex = url.indexOf("?x-oss-process=")
  if (sepIndex <= 0){
    return url
  }
  return url.substring(0,sepIndex)

}

export {
  postApi,
  getApi,
  isLogin,
  toBigImgUrl
}
