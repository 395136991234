<template>
  <div class="pc-inner">
    <!-- <el-container>
      <el-header> -->
        <Header></Header>
      <!-- </el-header>
      <el-main> -->
        <router-view class="router-view" name="pc"></router-view>
      <!-- </el-main>
      <el-footer> -->
        <Footer></Footer>
      <!-- </el-footer>
    </el-container> -->
  </div>
</template>

<script>

import Header from './pc/Header.vue'
import Footer from './pc/Footer.vue'

export default {
  name: "PC",
  components: {
    Header,
    Footer
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">

.pc-inner {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
  min-width: min-content;

  .router-view {
    // margin-top:94px;
    flex-grow:1;
    padding: 94px 0 0 0;
    box-sizing: border-box;
  }
}

</style>
