<template>
  <div class="solution-inner">
    <div class="header">
      <img class="about-bg" src="../../../img/mobile/banner_jiejuefangan.png">
    </div>
    <div class="first-row">
      <div class="nav">
        <div @click="onClickIndex(index)" :class="[activeIndex === index ? 'selectedNav' : 'normalNav']" :key="index" v-for="(item, index) in navList">
          {{item}}
        </div>
      </div>
      <div class="panel">
        <div class="columns" v-for="(row, index) in navData[activeIndex].dot" :key="'colunm_' + index">
          <div class="row">
            <div class="dot_left"><img src="../../../img/pc/home/solution_icon_1.png"/>痛点</div>
            <div class="dot">{{row}}</div>
          </div>
          <div class="row">
            <div class="solution_left"><img src="../../../img/pc/home/solution_icon_2.png"/>解决方案</div>
            <div class="solution">
              <div class="solution-detail" v-for="(sub, subIndex) in navData[activeIndex].solution[index]" :key="'subIndex_' + subIndex">
                <div>{{sub.title}}</div>
                <div>{{sub.desc}}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="effect_left"><img src="../../../img/pc/home/solution_icon_3.png"/>效果</div>
            <div class="effect">{{navData[activeIndex].effect[index]}}</div>
          </div>
        <div class="row-divider"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PubSub from 'pubsub-js'

export default {
  name: "About",
  data () {
    return {
      activeIndex: 0,
      navList: [
        '物业管理',
        '建筑工程',
        '装饰装修',
        '销售行业',
        '餐饮、酒店',
        '生态农业',
        '通讯行业'
      ],
      navData: [
        {
          'dot': [
            '员工工作无法留痕留证，夜间值班无法实时得到监督，工作拍照手动上传至团队微信，太繁琐也没办法汇总。',
            '物业工作得不到业主的认可，影响物业费收取。',
            '内部管理系统无法满足拍照加水印功能，工作真实度无法保证。'
          ],
          'solution': [
            [
              {
                'title': '拍照、拍视频加水印：',
                'desc': '员工拍照时间地点无法修改、无美颜、无滤镜，真实拍照防作假。'
              },
              {
                'title': '拍照自动上传：',
                'desc': '创建团队群，统一员工拍照水印，拍照自动上传到团队，自定义分类存储，可以随时查看工作留证。'
              },
              {
                'title': '考勤统计：',
                'desc': '自动生成考勤数据，可查看团队实时、周月统计数据。'
              },
            ],
            [
              {
                'title': '生成日报、日报拼图功能：',
                'desc': '可以通过一键生成日报，或者日报拼图汇报工作，发送到业主群。'
              },
              {
                'title': '取信于客功能：',
                'desc': '业主可通过查看码查看每日物业工作情况。'
              }
            ],
            [
              {
                'title': '水印拍照SDK功能：',
                'desc': '公司内部系统可以接入工作蜂水印拍照SDK，可以继续使用内部管理系统，又可以拍照加水印。'
              },
              {
                'title': '闪电转发API功能：',
                'desc': '公司内部系统可以接入闪电转发API，使用工作蜂水印拍照自动上传至团队，自动转发至内部管理系统。'
              }
            ]
          ],
          'effect': [
            '员工的工作得到了监督，无法作假无法偷懒；保洁的工作完成质量和进度，物业经理无需到达现场即可完成工作审阅。',
            '业主可以实时监督和了解物业的工作进度、工作质量。',
            '不需要进行数据迁移，使用上了水印拍照功能，员工的工作拍照得到真实认证。'
          ]
        },
        {
          'dot': [
            '工作拍照过多，照片体积占用大量手机空间不好查找和归档。',
            '照片需要备注的工程信息多填写不方便。',
            '监理施工日志编写麻烦',
            '内部管理系统无法满足拍照加水印功能，工作真实度无法保证。'
          ],
          'solution': [
            [
              {
                'title': '自动上传云盘：',
                'desc': '拍照自动上传至云盘，不占用手机空间。'
              },
              {
                'title': '自定义分类存储：',
                'desc': '可以根据施工部位和位置自定义创建分类，可以批量导出。'
              }
            ],
            [
              {
                'title': '工程水印模板：',
                'desc': '高清水印照片拍摄，自带时间、地点、天气、经纬度、项目信息等工程水印。'
              }
            ],
            [
              {
                'title': '日报拼图功能：',
                'desc': '支持一键制作监理日志、施工汇报，并且能导出图片。自带时间、地点、天气、监理人员名称等信息，方便快捷。'
              }
            ],
            [
              {
                'title': '水印拍照SDK功能：',
                'desc': '公司内部系统可以接入工作蜂水印拍照SDK，可以继续使用内部管理系统，又可以拍照加水印。'
              },
              {
                'title': '闪电转发API功能：',
                'desc': '公司内部系统可以接入闪电转发API，使用工作蜂水印拍照自动上传至团队，自动转发至内部管理系统。'
              }
            ]
          ],
          'effect': [
            '云盘存储很方便，随时随地可以访问下载和共享。手机也不会经常提示已满。查找照片很方便，归档省事不费力。',
            '填写简单方便，可以随时复用，水印内容清晰，可根据自己的需求创建多个场景模板。',
            '填写简单方便，自动生成省时省力，提高了工作积极性。',
            '不需要进行数据迁移，使用上了水印拍照功能，员工的工作拍照得到真实认证。'
          ]
        },
        {
          'dot': [
            '员工工作无法留痕留证，工作完成质量得不到有效的监督，工作拍照手动上传至微信群，太繁琐也没办法汇总。',
            '客户担心装修公司工作敷衍，装修偷工减料，使用劣质的材料，拖长施工时间来提高装修费用。'
          ],
          'solution': [
            [
              {
                'title': '拍照、拍视频加水印：',
                'desc': '员工拍照时间地点无法修改、无美颜、无滤镜，真实拍照防作假。'
              },
              {
                'title': '拍照自动上传：',
                'desc': '创建团队群，统一员工拍照水印，拍照自动上传到团队，自定义分类存储，可以随时查看工作留证。'
              },
              {
                'title': '考勤统计：',
                'desc': '自动生成考勤数据，可查看团队实时、周月统计数据。'
              }
            ],
            [
              {
                'title': '取信于客功能：',
                'desc': '客户可通过查看码查看每日装修进度和每个装修环节的施工过程材料使用情况。'
              }
            ]
          ],
          'effect': [
            '员工的工作得到了监督，无法作假无法偷懒；施工人员的工作完成质量和进度，项目经理无需到达现场即可完成工作审阅。',
            '通过实施与客户同步每日装修情况，客户无需到达现场，对装修每个环节都了如指掌，更安心；公开透明得到了客户的认可。'
          ]
        },
        {
          'dot': [
            '外出工作人员管理难，员工偷懒或者作假，工作效率低，工作完成度差。',
            '外出工作考勤统计难'
          ],
          'solution': [
            [
              {
                'title': '水印拍照功能：',
                'desc': '拍照拍视频加水印，时间地点无法修改，真实拍照防修改。'
              },
              {
                'title': '拍照自动上传：',
                'desc': '创建团队，员工异地打卡拍照自动上传，管理员可实时查看拍照内容和地点，员工无法偷懒和作假。'
              },
              {
                'title': '位置导航功能：',
                'desc': '员工每日会自动生成拍照位置，每个员工每天的巡店位置地点清晰可见，可以随时查看具体时间在什么位置。'
              }
            ],
            [
              {
                'title': '实时考勤统计：',
                'desc': '拍照自动生成考勤信息，可以查看团队实时考勤统计、周月考勤统计支持设置班次和休息时间，动态调节。'
              }
            ]
          ],
          'effect': [
            '外勤工作得到了监督，员工不偷懒实时到岗了，工作效率和完成度都得到了提升。业绩提升明显。',
            '员工外出考勤得到了有效的统计，降低了考勤统计错误率，方便公司做工资结算，提高了员工的认可度。'
          ]
        },
        {
          'dot': [
            '员工工作无法留痕留证，夜间值班无法实时得到监督，工作拍照手动上传至微信群，太繁琐也没办法汇总。',
            '客户担心入住的房间物品没有更换，房间的卫生没有打扫等等，造成酒店入住率下降，品牌认可度下降。'
          ],
          'solution': [
            [
              {
                'title': '拍照、拍视频加水印：',
                'desc': '员工拍照时间地点无法修改、无美颜、无滤镜，真实拍照防作假。'
              },
              {
                'title': '拍照自动上传：',
                'desc': '创建团队群，统一员工拍照水印，拍照自动上传到团队，自定义分类存储，可以随时查看工作留证。'
              },
              {
                'title': '考勤统计：',
                'desc': '自动生成考勤数据，可查看团队实时、周月统计数据。'
              }
            ],
            [
              {
                'title': '取信于客功能：',
                'desc': '客户可通过查看码查看每日酒店卫生清理和物品更换情况。'
              }
            ]
          ],
          'effect': [
            '员工的工作得到了监督，无法作假无法偷懒；保洁的工作完成质量和进度，酒店经理无需到达现场即可完成工作审阅。',
            '酒店通过实施与客户同步房间的卫生清理和物品更换情况，让客户更放心入住酒店，提高品牌认可度。'
          ]
        },
        {
          'dot': [
            '外出工作人员管理难，员工偷懒或者作假，工作效率低，工作完成度差。',
            '农产品的照片分享到社交平台无法快速让消费者记住。',
            '客户担心农产品生产环境恶劣、生产过程掺假、过度使用农药等等， 导致农产品销量不行，品牌认可度下降。'
          ],
          'solution': [
            [
              {
                'title': '拍照、拍视频加水印：',
                'desc': '员工拍照时间地点无法修改、无美颜、无滤镜，真实拍照防作假。'
              },
              {
                'title': '拍照自动上传：',
                'desc': '创建团队群，统一员工拍照水印，拍照自动上传到团队，自定义分类存储，可以随时查看工作留证。'
              },
              {
                'title': '考勤统计：',
                'desc': '自动生成考勤数据，可查看团队实时、周月统计数据。'
              }
            ],
            [
              {
                'title': '品牌水印功能：',
                'desc': '使用专属的公司品牌使用拍照，每张照片都带有公司或者农产品品牌分享到社交平台，能快速让消费者看到和记住这个品牌。'
              }
            ],
            [
              {
                'title': '取信于客功能：',
                'desc': '客户可通过查看码查看农产品的生长过程、使用什么药物除虫害，生产过程的情况照片和视频。'
              }
            ]
          ],
          'effect': [
            '外勤工作得到了监督，员工不偷懒实时到岗了，工作效率和完成度都得到了提升。业绩提升明显。',
            '公司和商品品牌得到了宣传，商品的销量也提高了。',
            '公开生产环境，生产过程和使用的药物品牌，得到了消费者的认可，消费者也放心购买。'
          ]
        },
        {
          'dot': [
            '外出工作人员管理难，员工偷懒或者作假，工作效率低，工作完成度差。',
            '员工工作无法留痕留证，工作拍照太多，占用手机空间；手动上传至微信群，不能长期保存，不方便查看。'
          ],
          'solution': [
            [
              {
                'title': '水印拍照功能：',
                'desc': '拍照拍视频加水印，时间地点无法修改，真实拍照防修改。'
              },
              {
                'title': '拍照自动上传：',
                'desc': '创建团队，员工异地打卡拍照自动上传，管理员可实时查看拍照内容和地点，员工无法偷懒和作假。'
              },
              {
                'title': '位置导航功能：',
                'desc': '员工每日会自动生成拍照位置，每个员工每天的巡店位置地点清晰可见，可以随时查看具体时间在什么位置。'
              }
            ],
            [
              {
                'title': '水印拍照自动上传团队：',
                'desc': '时间地点无法修改，真实拍照防修改。无限储存空间，自定义分类储存，可以实时查看和批量导出。'
              }
            ]
          ],
          'effect': [
            '员工的工作得到了监督，无法作假无法偷懒；外出人员的工作完成质量和进度，管理人员无需到达现场即可完成工作审阅。',
            '工作拍照得到了长期保存，实时查询很方便，随时可以快速定位找出问题。'
          ]
        }
      ]
    }
  },
  mounted () {
    PubSub.publish('changeHeaderIndex', 1)
    PubSub.publish('menuName', '解决方案')
  },
  methods: {
    onClickIndex (index) {
      this.activeIndex = index
    }
  }
}

</script>

<style scoped lang="scss">

.solution-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .header {
    padding: 0 17px;
    box-sizing: border-box;

    .about-bg {
      width: 100%;
    }
  }

  .first-row {
    display: flex;
    flex-direction: column;
    padding: 14px 17px 0 17px;
    box-sizing: border-box;
    width: 100%;
    position: relative;

    .nav {
      display: flex;
      height: 27px;
      width: calc(100% - 34px);
      margin: 0;
      padding: 0;
      overflow-y: auto;
      position: absolute;

      .normalNav {
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
        height: 27px;
        white-space: nowrap;
      }

      .selectedNav {
        font-size: 15px;
        font-weight: 700;
        color: #0189ff;
        line-height: 20px;
        cursor: pointer;
        height: 27px;
        border-bottom: solid 2px #0189ff;
        box-sizing: border-box;
        white-space: nowrap;
      }

      div {
        margin-right: 18px;
      }

      div:last-of-type {
        margin-right: 0;
      }
    }

    .nav::-webkit-scrollbar {
      height: 0;
    }

    .panel {
      margin-top: 47px;
      margin-bottom: 37px;
      display: flex;
      flex-direction: column;

      .columns {
        display: flex;
        flex-direction: column;

        .row {
          display: flex;
          margin-bottom: 10px;

          img {
            width: 13px;
            height: 13px;
          }

          .dot_left {
            background-color: #fff8f0;
            margin-right: 10px;
            width: 69px;
            flex-shrink: 0;
            font-size: 13px;
            font-weight: bold;
            line-height: 17px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          .solution_left {
            background-color: #f3f9ff;
            margin-right: 10px;
            width: 69px;
            flex-shrink: 0;
            font-size: 13px;
            font-weight: bold;
            line-height: 17px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          .effect_left {
            background-color: #f2fbf6;
            margin-right: 10px;
            width: 69px;
            flex-shrink: 0;
            font-size: 13px;
            font-weight: bold;
            line-height: 17px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }

        .dot {
          font-size: 13px;
          line-height: 17px;
          background-color: #fff8f0;
          border-radius: 2px;
          padding: 10px 32px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          flex-grow: 1;
        }

        .solution {
          background-color: #f3f9ff;
          border-radius: 2px;
          padding: 10px 32px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-grow: 1;

          .solution-detail {
            margin-top: 15px;

            div:nth-child(1) {
              font-size: 13px;
              font-weight: 700;
              line-height: 17px;
            }

            div:nth-child(2) {
              font-size: 13px;
              font-weight: 400;
              line-height: 17px;
            }
          }

          .solution-detail:nth-of-type(1) {
            margin-top: 0;
          }
        }

        .effect {
          background-color: #f2fbf6;
          border-radius: 2px;
          padding: 10px 32px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          font-size: 13px;
          font-weight: 400;
          line-height: 17px;
          flex-grow: 1;
        }

        .row-divider {
          width: 100%;
          height: 1px;
          background: #D7D7D7;
          margin-bottom: 10px;
        }
      }

      .columns:last-of-type .row-divider {
        display: none;
      }
    }
  }
}

</style>
