<template>
  <div class="download-inner">
    <div class="first-row">
      <img style="margin-right:80px; height:60%;" src="../../../img/pc/home/duopingtai_app.png"/>
      <div class="intro">
        <div class="title">下载工作蜂客户端</div>
        <div class="desc">拥有iOS、Android、Web、小程序版本</div>
        <div class="links">
          <div>
            <img src="../../../img/pc/home/iphone_xiazai.png"/>
            <span>iOS客户端</span>
          </div>
          <div>
            <img src="../../../img/pc/home/android_xiazai.png"/>
            <span>Android客户端</span>
          </div>
          <div>
            <img src="../../../img/pc/home/xiaochengxu_xiazai.png"/>
            <span>工作蜂小程序</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PubSub from 'pubsub-js'

export default {
  name: "Download",
  mounted () {
    PubSub.publish('changeHeaderIndex', 5)
    PubSub.publish('menuName', '')
  }
}

</script>

<style scoped lang="scss">

.download-inner {
  height: 716px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .first-row {
    display: flex;
    align-items: center;

    .title {
      font-size: 35px;
      font-weight: 600;
      line-height: 49px;
    }

    .desc {
      font-size: 20px;
      font-weight: 300;
      line-height: 28px;
      margin-top: 15px;
      margin-bottom: 28px;
    }

    .links {
      display: flex;

      div {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        line-height: 22px;
        text-align: center;
        cursor: pointer;

        img {
          width: 140px;
          height: 140px;
          margin-bottom: 20px;
        }
      }

      div:nth-of-type(1):hover img {
        content: url('../../../img/pc/home/iphone_xiazai_erweima.png');
      }

      div:nth-of-type(2):hover img {
        content: url('../../../img/pc/home/android_xiazai_erweima.png');
      }

      div:nth-of-type(3):hover img {
        content: url('../../../img/pc/home/xiaochengxu_xiazai_erweima.png');
      }
    }
  }
}

</style>
