<template>
  <div class="auth-setting-inner">
    <div class="header">
      <div class="title">权限管理</div>
      <div class="subtitle">照片考勤查看权限</div>
    </div>
    <div class="content">
      <span style="margin-top:30px;">查看</span>
      <el-radio v-model="allow" label="yes" class="allow-view">允许所有成员查看照片和考勤统计</el-radio>
      <el-radio v-model="allow" label="no" class="reject-view">仅管理员可查看照片和考勤统计</el-radio>
      <button class="modify-btn" @click="onEditAuth()">完成</button>
    </div>
  </div>
</template>

<script>

import {postApi} from "@/common"

export default {
  name: "auth-setting",
  data() {
    return {
      allow: 'yes',
      teamId: null,
      teamInfo: null
    }
  },
  mounted() {
    var team = JSON.parse(localStorage.getItem('camera_admin_team'))
    this.teamId = team.id
    this.loadTeamInfo()
  },
  methods: {
    loadTeamInfo () {
      var self = this
      self.$store.commit('showLoading')
      postApi(this.apiBase + '/camera/admin/teamInfo', { teamId: this.teamId }  , res => {
        self.$store.commit('hideLoading')
        if (res.success === '1') {
          if (res.content.isPublic) {
            self.allow = 'yes'
          } else {
            self.allow = 'no'
          }
        } else {
          self.$message.error(res.message);
        }
      })
    },
    onEditAuth () {
      var self = this
      self.$store.commit('showLoading')
      postApi(this.apiBase + '/camera/team/public', { teamId: this.teamId, isPublic: self.allow }  , res => {
        self.$store.commit('hideLoading')
        if (res.success === '1') {
          self.$message.info("修改成功")
        } else {
          self.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.auth-setting-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 21px;

  .header {
    height: 80px;
    padding: 0 20px;
    background-color: #F7F7F7;

    .title {
      font-size: 16px;
      line-height: 21px;
      font-weight: 700;
      margin-top: 23px;
    }

    .subtitle {
      font-size: 14px;
      line-height: 19px;
      color: #666;
      margin-top: 7px;
    }
  }

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    .allow-view {
      margin-top: 23px;
    }

    .reject-view {
      margin-top: 23px;
    }

    .modify-btn {
      width: 100px;
      height: 32px;
      margin-top: 46px;
      background-color: #0A8DF2;
      border-radius: 2px;
      box-sizing: border-box;
      border: solid 1px #0A8DF2;
      color: #FFF;
      cursor: pointer;
    }
  }
}
</style>
