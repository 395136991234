<template>
  <div class="picture-list">
    <div class="row-header">
      <el-checkbox class="check" v-model="checkAll" @change="handleCheckAll"></el-checkbox>
      <div class="image">
        {{
          $store.state.checkedPictureTotal > 0
          ? "已选中" + $store.state.checkedPictureTotal + "张照片"
          : "拍照人姓名"
        }}
      </div>
      <div class="time">时间</div>
      <div class="position">地点</div>
    </div>
    <div :class="pic.checked ? 'row checked' : 'row'" v-for="(pic, pindex) in $store.state.pictures" :key="pindex">
      <el-checkbox class="check" v-model="pic.checked" @change="checkOnePicture($event, pindex)"></el-checkbox>
      <div class="data" @click="handleClickItem($event, pindex)">
        <div class="image">
          <!-- <img v-lazy="pic.url+'?x-oss-process=style/style150'" alt="照片" :large="pic.url" preview="1"/> -->
          <el-image :src="pic.url + '?x-oss-process=style/style150'"
            :preview-src-list="$store.state.pictures.map(t => t.url + '?x-oss-process=style/style150')" lazy
            @click="onPreview()">
            <div slot="error" class="image-slot">
              <img style="height: auto" src="../../assets/images/default_gongzuofeng.png" @click="gop()"/>
            </div>
          </el-image> <span>{{
            pic.nickname
          }}</span>
        </div>
        <div class="time">
          {{ new Date(pic.imageTime).format("yyyy-MM-dd hh:mm") }}
        </div>
        <div class="position">{{ pic.position }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: "PictureItemList",
  data() {
    return {
      checkAll: false,
    };
  },
  methods: {
    checkOnePicture(evt, index) {
      console.log("点击了图片Item：", index, this.$store.state.pictures[index].checked);
      this.$store.commit("checkOnePicture", -1);
      this.calcCheckedPics();
    },
    handleClickItem(evt, index) {
      const that = this
      const targetNode = evt.target.nodeName;
      console.log("点击了图片Item：", index, evt.target.nodeName);
      if (targetNode !== "IMG") {
        $(evt.target).find('img').click()
      }
      this.$store.commit('showLoading')
      this.$store.commit("showBottomBar");
      setTimeout(function () {
        that.$store.commit('hideLoading')
      }, 1000)
    },
    handleCheckAll(check) {
      console.log("全选状态：", check);
      this.$store.commit("checkAllPictures", check);
      this.calcCheckedPics();
    },
    calcCheckedPics() {
      //全选状态
      let checkAll = true;
      this.$store.state.pictures.forEach((pic) => {
        if (!pic.checked) {
          checkAll = false;
        }
      });
      this.checkAll = checkAll;
    },
    gop(e) {
      let that = this
      e && e.stopPropagation()
      window.open('/img/default_gongzuofeng.e507ad12.png')
      setTimeout(()=>{
        that.isShowPicList = false
        that.$store.commit("hideBottomBar");
      },1000)
    },
    onPreview() {
      var self = this
      var index = 0
      self.$store.commit("syncPicIndex", index)
      this.$nextTick(() => {
        let domImageMask = document.querySelector(".el-image-viewer__mask")
        let closeButton = document.querySelector(".el-image-viewer__close")
        let canvas = document.querySelector('.el-image-viewer__canvas img')
        let nextButton = document.querySelector('.el-image-viewer__next')
        let prevButton = document.querySelector('.el-image-viewer__prev')
        if (!domImageMask || !closeButton) {
          return
        }
        domImageMask.addEventListener("click", () => {
          document.querySelector(".el-image-viewer__close").click();
          self.$store.commit("hideBottomBar")
        })
        closeButton.addEventListener("click", () => {
          self.$store.commit("hideBottomBar")
        })

        if (nextButton) {
          nextButton.addEventListener("click", () => {
            var canvas1 = document.querySelector('.el-image-viewer__canvas img')
            canvas1.src = canvas1.src.replace('?x-oss-process=style/style150', '')
          })
        }

        if (prevButton) {
          prevButton.addEventListener("click", () => {
            var canvas1 = document.querySelector('.el-image-viewer__canvas img')
            canvas1.src = canvas1.src.replace('?x-oss-process=style/style150', '')
          })
        }

        canvas.src = canvas.src.replace('?x-oss-process=style/style150', '')
      })
    }
  },
};
</script>

<style scoped lang="scss">
.picture-list {
  margin-left: 25px;
  background: #fff;

  .row-header {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #6a6a6a;
    height: 50px;

    .check {
      margin-right: 10px;
    }

    .image {
      flex: 1;
    }

    .time {
      width: 200px;
      text-align: center;
    }

    .position {
      width: 200px;
      text-align: center;
      margin-right: 150px;
    }
  }

  .row.checked {
    .data {
      background: #f8fbff;
    }
  }

  .row {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #6a6a6a;
    height: 40px;
    border-bottom: 1px solid #f2f6fd;

    .check {
      margin-right: 10px;
    }

    .data:hover {
      background: #f8fbff;
    }

    .data {
      flex: 1;
      height: 40px;
      display: flex;
      align-items: center;

      .image {
        flex: 1;
        display: flex;
        align-items: center;

        .el-image {
          width: 22px !important;
          height: 26px !important;
          margin-right: 10px !important;
        }

        .el-image:hover {
          cursor: url("../../assets/images/search.ico"), auto !important;
        }
      }

      .time {
        width: 200px;
        text-align: center;
      }

      .position {
        width: 200px;
        text-align: center;
        margin-right: 150px;
      }
    }
  }
}
</style>
