import 'babel-polyfill';
import VueRouter from 'vue-router'
import Login from "@/views/Login";
import Teams from "@/views/Teams";
import Team from "@/views/Team";
// import Index from "@/views/Index";

import {isLogin} from '@/common'
// import qs from 'qs'

const routes = [
  {
    path: '/',
    component: require('@/web/page/Home').default,
    children: [
      // 默认首页
      {
        path: '/',
        components: {
          pc: require('@/web/page/views/pc/MainPage').default,
          mobile: require('@/web/page/views/mobile/MainPage').default
        }
      },
      // 关于
      {
        path: 'about',
        components: {
          pc: require('@/web/page/views/pc/About').default,
          mobile: require('@/web/page/views/mobile/About').default
        }
      },
      // 客户案例
      {
        path: 'case',
        components: {
          pc: require('@/web/page/views/pc/CustomerCase').default,
          mobile: require('@/web/page/views/mobile/CustomerCase').default
        }
      },
      // Banner详情
      {
        path: 'bannerdetail1',
        components: {
          pc: require('@/web/page/views/pc/BannerDetail1').default,
          mobile: require('@/web/page/views/mobile/BannerDetail1').default
        }
      },
      {
        path: 'bannerdetail2',
        components: {
          pc: require('@/web/page/views/pc/BannerDetail2').default,
          mobile: require('@/web/page/views/mobile/BannerDetail2').default
        }
      },
      {
        path: 'bannerdetail3',
        components: {
          pc: require('@/web/page/views/pc/BannerDetail3').default,
          mobile: require('@/web/page/views/mobile/BannerDetail3').default
        }
      },
      // 下载
      {
        path: 'download',
        components: {
          pc: require('@/web/page/views/pc/Download').default
        }
      },
      // 解决方案
      {
        path: 'solution',
        components: {
          pc: require('@/web/page/views/pc/Solution').default,
          mobile: require('@/web/page/views/mobile/Solution').default
        }
      }
    ]
  },
  {path: '/login', name: 'login', component: Login},
  {path: '/teams', component: Teams},
  {
    path: '/team', 
    component: Team,
    children: [
      {
        path:'auth-setting',
        name: 'auth-setting',
        component: require('@/views/team-manage/auth-setting').default
      },
      {
        path:'user-manage',
        name: 'user-manage',
        component: require('@/views/team-manage/user-manage').default
      },
      {
        path:'forward-list',
        name: 'forward-list',
        component: require('@/views/forward-list').default
      },
      {
        path:'forward-detail',
        name: 'forward-detail',
        component: require('@/views/forward-detail').default
      },
      {
        path:'preview-permission',
        name: 'preview-permission',
        component: require('@/views/preview-permission').default
      },
      {
        path:'customer-service',
        name: 'customer-service',
        component: require('@/views/customer-service').default
      },
      {
        path:'restore',
        name: 'restore',
        component: require('@/views/restore').default
      }
    ]
  },
  {
    path:'/forward-guide',
    name: 'forward-guide',
    component: require('@/views/forward-guide').default
  },
  {
    path:'/privacy-policy',
    name: 'privacy-policy',
    component: require('@/views/privacy-policy').default
  },
  {
    path:'/user-license',
    name: 'user-license',
    component: require('@/views/user-license').default
  },
  {
    path:'/wxlogin',
    name: 'wxlogin',
    component: require('@/views/wxlogin').default
  },
  {
    path:'/map',
    name: 'map',
    component: require('@/views/Map').default
  },
]


// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
})
router.onReady(function (to) {
  console.log('进入了路径监视：', to)
  //判断用户是否登录，未登录的话跳转到登录页面
  const islogin = isLogin()
  console.log('登录情况：', isLogin, to.path)
  if (islogin && to.path === '/login') {
    router.push({
      path: '/teams'
    })
  }
  // if (!islogin && (to.path !== '/login' || to.path !== '/')) {
  //   router.push({
  //     path: '/login'
  //   })
  // }
})

router.beforeEach((to, from, next) => {
  
  if (document.getElementsByClassName('pc-inner')[0] !== undefined) {
    document.getElementsByClassName('pc-inner')[0].children[1].scrollIntoView(true)
    // document.getElementsByClassName('pc-inner')[0].children[1].scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
  }
  if (document.getElementsByClassName('mobile-inner')[0] !== undefined) {
    document.getElementsByClassName('mobile-inner')[0].children[1].scrollIntoView(true)
    // document.getElementsByClassName('pc-inner')[0].children[1].scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
  }
  next()
})

export default router
