<template>
  <div class="main-page-inner">
    <div class="banner-row">
      <el-carousel class="banner" style="width:980px;" height="460px" arrow="never" :autoplay="true">
        <el-carousel-item>
          <img @click="onViewBannerDetail(0)" src="../../../img/pc/home/banner_1.png">
          <div class="content">
            <div class="title">工作蜂真实拍照</div>
            <div class="desc">时间地点无法修改、无滤镜、无美颜， 每张照片都是真实的。</div>
            <div class="options">
              <div class="btn-1">
                <span @click="$router.push({ path: 'download' })">立即体验</span>
              </div>
              <div class="btn-2" @click="onViewBannerDetail(0)">查看详情</div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <img @click="onViewBannerDetail(1)" src="../../../img/pc/home/banner_2.png">
          <div class="content">
            <div class="title">多平台协作</div>
            <div class="desc">工作蜂APP、工作蜂小程序、工作蜂管理后台，实时同步，提高团队协作效率。</div>
            <div class="options">
              <div class="btn-1" style="color:#0189FF;" @click="onViewBannerDetail(1)">查看详情</div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <img @click="onViewBannerDetail(2)" src="../../../img/pc/home/banner_3.png">
          <div class="content">
            <div class="title">闪电转发</div>
            <div class="desc">拍照自动转发，支持钉钉群、企业微信、企业内部管理系统。</div>
            <div class="options">
              <div class="btn-1" style="color:#2A5BBD;" @click="onViewBannerDetail(2)">查看详情</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="right">
        <div class="right-panel">
          <img class="bg-img" src="../../../img/pc/home/banner_right1.png">
          <div class="content">
            <div class="title">管理后台</div>
            <div class="desc">管理团队、批量导出照片</div>
            <div class="btn" style="color:#3B60CB;text-shadow: 0.5px 0px #3B60CB;" @click="$router.push('/login')">进入后台</div>
          </div>
        </div>
        <div class="right-panel">
          <img class="bg-img" src="../../../img/pc/home/banner_right2.png">
          <div class="content">
            <div class="title">微信小程序</div>
            <div class="desc">管理员无需安装APP，即可通过小程序管理 团队，消息必达！</div>
            <div class="btn" style="color:#32AA92;text-shadow: 0.5px 0px #32AA92;">立即访问
              <div class="qr">
                <img class="qr-img" src="../../../img/pc/home/qr_mini.png">
                <p>扫描二维码</p>
                <p>访问工作蜂小程序</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="first-row">
      <span>权威认证，安全更可靠</span>
      <div class="desc">
        <div>
          <img src="../../../img/pc/home/gongzheng_pic.png"/>
          <p>公证处公正</p>
          <p>时间无法修改</p>
        </div>
        <div>
          <img src="../../../img/pc/home/zhongugancun.png"/>
          <p>中关村高新技术企业认证</p>
          <p>技术创新</p>
        </div>
        <div>
          <img src="../../../img/pc/home/guojiabanquanju.png"/>
          <p>国家版权局</p>
          <p>软件著作权登记证书</p>
        </div>
        <div>
          <img src="../../../img/pc/home/zhuanli.png"/>
          <p>国家知识产权局</p>
          <p>实用新型专利证书</p>
        </div>
      </div>
    </div>
    <div class="second-row">
      <div class="second-sub-row">
        <div class="title">使用工作蜂</div>
        <div class="subtitle">员工的工作更高效规范</div>
        <div class="intro-list">
          <div>
            <img src="../../../img/pc/home/wuxiugai.png" />
            <p>工作真实拍照防修改</p>
            <p>时间地点无法修改、无滤镜、无美颜</p>
            <p>拍照真实</p>
          </div>
          <div>
            <img src="../../../img/pc/home/shangchuanzhuanfa.png" />
            <p>拍照自动上传，无需手动转发</p>
            <p>自动上传至团队，自动转发至钉钉、企业微信、</p>
            <p>企业内部系统</p>
          </div>
          <div>
            <img src="../../../img/pc/home/zidingyifenlei.png" />
            <p>无限空间，自定义分类存储</p>
            <p>批量导出照片、不占用手机空间</p>
          </div>
        </div>
      </div>
      <div class="second-sub-row" style="margin-top:70px;">
        <div class="title">使用工作蜂</div>
        <div class="subtitle">团队的管理更简单轻松</div>
        <div class="intro-list">
          <div>
            <img src="../../../img/pc/home/shuiyin.png" />
            <p>统一创建拍照水印</p>
            <p>一人创建多人共用，专业又规范</p>
          </div>
          <div>
            <img src="../../../img/pc/home/xiaochengxu_icon.png" />
            <p>多平台协作</p>
            <p>小程序管理团队，消息必达</p>
          </div>
          <div>
            <img src="../../../img/pc/home/kaoqin.png" />
            <p>实时考勤统计</p>
            <p>支持实时、周月考勤统计，一目了然</p>
          </div>
        </div>
      </div>
    </div>
    <div class="third-row">
      <div class="customer-case">
        <div class="title">客户案例</div>
        <div class="customer-case-list">
          <div>
            <img src="../../../img/pc/home/shouye_kehu01.png"/>
            <p>物业管理 | 某某物业服务</p>
            <p>用工作蜂管理1000人的团队</p>
            <p @click="$router.push({ path: '/case' })">查看更多</p>
          </div>
          <div>
            <img src="../../../img/pc/home/shouye_kehu02.png"/>
            <p>制造业 | 某某制造厂</p>
            <p>用工作蜂协助企业安全生产，更高效！</p>
            <p @click="$router.push({ path: '/case' })">查看更多</p>
          </div>
          <div>
            <img src="../../../img/pc/home/shouye_kehu03.png"/>
            <p>家装服务 | 某某装饰公司</p>
            <p>打造数字化透明工程，让业主放心！</p>
            <p @click="$router.push({ path: '/case' })">查看更多</p>
          </div>
        </div>
      </div>
      <div class="customer-case" style="margin-top:153px">
        <div class="title">各行各业都在用工作蜂</div>
        <div class="customer-list">
          <div><img src="../../../img/pc/home/kehu_lvcheng.png" /></div>
          <div><img src="../../../img/pc/home/kehu_gaonenghuanjing.png" /></div>
          <div><img src="../../../img/pc/home/kehu_xindazheng.png" /></div>
          <div><img src="../../../img/pc/home/kehu_shanhejianshe.png" /></div>
          <div><img src="../../../img/pc/home/kehu_yinzuojiudian.png" /></div>
          <div><img src="../../../img/pc/home/kehu_duoduomaicai.png" /></div>
          <div><img src="../../../img/pc/home/kehu_zhongguoyouzheng.png" /></div>
          <div><img src="../../../img/pc/home/kehu_shiliuwuye.png" /></div>
          <div><img src="../../../img/pc/home/kehu_changchengkuandai.png" /></div>
          <div><img src="../../../img/pc/home/kehu_yuncangpei.png" /></div>
          <div><img src="../../../img/pc/home/kehu_dawo.png" /></div>
          <div><img src="../../../img/pc/home/kehu_hengxinjituan.png" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PubSub from 'pubsub-js'

export default {
  name: "MainPage",
  mounted () {
    PubSub.publish('changeHeaderIndex', 0)
    PubSub.publish('menuName', '')
  },
  methods: {
    onViewBannerDetail (index) {
      if (index === 0) {
        this.$router.push({ path: 'bannerdetail1' })
      }

      if (index === 1) {
        this.$router.push({ path: 'bannerdetail2' })
      }

      if (index === 2) {
        this.$router.push({ path: 'bannerdetail3' })
      }
    }
  }
}

</script>

<style scoped lang="scss">
.main-page-inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  // .el-carousel__item {
  //   overflow-y: visible;
  // }

  .banner-row {
    display: flex;
    width: 1420px;
    height: 460px;

    .qr {
      width: 140px;
      height: 182px;
      border-radius: 5px;
      box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.28);
      position:absolute;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 13px 15px 9px 15px;
      box-sizing: border-box;
      background-color: #FFF;
      display: none;
      margin-top: 2px;
      color: #000;
      z-index: 300;

      .qr-img {
        width: 110px;
        height: 110px;
        margin-bottom: 9px;
      }

      p {
        margin: 0px;
        text-align: center;
      }

      p:nth-child(3) {
        font-size: 12px;
        line-height: 17px;
      }
    }

    .banner {
      width: 980px;
      // border-radius: 15px;
      // overflow: hidden;
      overflow: none !important;
      margin-right: 20px;

      img {
        width: 980px;
        height: 460px;
        cursor: pointer;
      }

      .content {
        display: flex;
        flex-direction: column;
        color: #FFF;
        width: 360px;
        position: absolute;
        top: 88px;
        left: 67px;

        .title {
          font-size: 50px;
          text-align: left;
          line-height: 70px;
          font-weight: 500;
          text-shadow:1px 0px #FFF;
          letter-spacing: 1px;
        }

        .desc {
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          margin-top: 9px;
        }

        .options {
          margin-top: 28px;
          display: flex;

          .btn-1 {
            width: 150px;
            height: 52px;
            background-color: #ffffff;
            border-radius: 3px;
            color: #0189ff;
            font-size: 20px;
            font-weight: 500;
            text-shadow: 0.5px 0px #0189ff;
            text-align: center;
            line-height: 52px;
            margin-right: 24px;
            cursor: pointer;
          }

          .btn-1:hover .qr {
            display: block;
          }

          .btn-2 {
            width: 150px;
            height: 52px;
            border: 2px solid #ffffff;
            border-radius: 3px;
            color: #FFF;
            font-size: 20px;
            font-weight: 500;
            text-shadow: 0.5px 0px #FFF;
            text-align: center;
            line-height: 46px;
            box-sizing: border-box;
            cursor: pointer;
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .right-panel {
        position: relative;
        width: 420px;
        height: 220px;
        
        .bg-img {
          width: 420px;
          height: 220px;
        }

        .content {
          display: flex;
          flex-direction: column;
          position: absolute;
          left: 30px;
          top: 25px;
          color: #FFF;

          .title {
            font-size: 25px;
            font-weight: 500;
            line-height: 36px;
            text-shadow: 0.5px 0px #FFF;
            letter-spacing: 1px;
          }

          .desc {
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            max-width: 303px;
          }

          .btn {
            width: 117px;
            height: 40px;
            background-color: #ffffff;
            border-radius: 3px;
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
            margin-top: 23px;
          }

          .btn:hover .qr {
            display: block;
          }
        }
      }
    }
  }

  .first-row {
    display: flex;
    flex-direction: column;

    span {
      font-size: 40px;
      font-weight: 500;
      text-align: center;
      color: #000000;
      line-height: 52px;
      margin-top: 43px;
      text-shadow: 0.5px 0px #000;
    }

    .desc {
      display: flex;
      width: 1020px;
      justify-content: space-between;
      margin-top: 55px;
      margin-bottom: 54px;
      text-align: center;

      img {
        margin-bottom: 20px;
        // width: 100%;
        height: 90px;
      }

      p {
        margin: 0;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        color: #000000;
        line-height: 28px;
      }
    }
  }

  .second-row {
    background-color: #f4f4f4;
    width: 100%;
    height: 845px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0 59px 0;
    box-sizing: border-box;

    .second-sub-row {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 25px;
        font-weight: 500;
        text-align: center;
        color: #000000;
        line-height: 36px;
        text-shadow: 0.5px 0px #000;
      }

      .subtitle {
        font-size: 40px;
        font-weight: 500;
        text-align: center;
        color: #000000;
        line-height: 52px;
        margin-top: 7px;
        text-shadow: 0.5px 0px #000;
      }

      .intro-list {
        margin-top: 60px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 1100px;
        height: 178px;

        div {
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 70px;
            height: 70px;
            margin-bottom: 22px;
          }

          p {
            margin: 0;
          }

          p:nth-of-type(1) {
            font-size: 25px;
            font-weight: 500;
            text-align: center;
            color: #000000;
            line-height: 36px;
            margin-bottom: 8px;
            text-shadow: 0.5px 0px #000;
          }

          p:nth-of-type(:not(1)) {
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            color: #6e6e6e;
            line-height: 22px;
          }
        }
      }
    }
  }

  .third-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 0 86px 0;
    box-sizing: border-box;

    .customer-case {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 40px;
        font-weight: 500;
        text-align: center;
        color: #000000;
        line-height: 52px;
        text-shadow: 0.5px 0px #000;
      }

      .customer-case-list {
        display: flex;
        width: 1184px;
        justify-content: space-between;
        margin-top: 56px;

        div {
          display: flex;
          flex-direction: column;

          img {
            width: 380px;
            height: 220px;
            margin-bottom: 15px;
          }

          p {
            margin: 0px;
          }

          p:nth-of-type(1) {
            font-size: 20px;
            font-weight: 300;
            line-height: 28px;
          }

          p:nth-of-type(2) {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            margin-bottom: 19px;
            margin-top: 7px;
            text-shadow: 0.5px 0px #000;
          }

          p:nth-of-type(3) {
            font-size: 20px;
            font-weight: 500;
            color: #0c8dff;
            line-height: 28px;
            cursor: pointer;
          }
        }
      }

      .customer-list {
        margin-top: 89px;
        width: 1190px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-row-gap: 20px;
        grid-column-gap: 20px;
        div {
          text-align: center;

          img {
            // width: 100%;
            height: 80px;
          }
        }
      }
    }
  }
}

</style>
