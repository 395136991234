<template>
  <div class="picture-list">
    <div class="row-header">
      <el-checkbox v-model="checkAll" @change="handleCheckAll">
        {{
          $store.state.checkedPictureTotal > 0
              ? "已选中" + $store.state.checkedPictureTotal + "张照片"
              : "全选"
        }}
      </el-checkbox>
    </div>
    <div v-for="(pics, date, index) in pictureGroups" :key="index">
      <!-- <div class="row-header">
        <el-checkbox
            v-model="pictureGroupChecks[date]"
            @change="handleCheckDate($event, date)"
        >{{ date }}
        </el-checkbox>
      </div> -->
      <div class="row-pics" @mouseenter="handleHover($event,-1)" style="margin-bottom:60px;">
        <div
            @mouseenter="handleHover($event,pindex)" @mouseout="handleHover($event,pindex)"
            @click="handleClickItem($event, date, pindex)"
            :class="pic.checked ? 'pic checked' : 'pic'"
            v-for="(pic, pindex) in pics"
            :key="pindex"
        >
          <div class="checked" v-show="pic.checked">
            <img id="correct" style="user-select: none;" src="../../assets/images/unchecked.png"/>
          </div>
          <div class="unchecked" v-show="!pic.checked && hoverIndex === pindex" @mouseenter="handleHover($event,pindex)"
               @mouseout="handleHover($event,pindex)">
          </div>
          <!-- <img class="image" v-lazy="pic.url+'?x-oss-process=style/style150'" alt="照片"
               @mouseenter="handleHover($event,pindex)"
               @mouseout="handleHover($event,pindex)"/> -->
            <el-image class="image" :src="pic.url+'?x-oss-process=image/resize,m_fill,w_120,h_160'"  @click="viewPicList(pics, pindex)">
               <div slot="error" class="image-slot">
                <img style="height: 160px;width: 120px;" src="../../assets/images/default_gongzuofeng.png" @click="gop()"/>
            </div>

              </el-image>
          <!-- <img class="image" v-lazy="pic.url + '?x-oss-process=image/resize,m_lfit,h_160,w_120'"  @click="viewPicList(pics, pindex)" /> -->
          <div class="nickname" @mouseenter="handleHover($event,pindex)" @mouseout="handleHover($event,pindex)">
            {{ pic.nickname }}
          </div>
          <div class="time" @mouseenter="handleHover($event,pindex)" @mouseout="handleHover($event,pindex)">
            {{ new Date(pic.imageTime).format("yyyy-MM-dd hh:mm") }}
          </div>
          <div class="position" @mouseenter="handleHover($event,pindex)" @mouseout="handleHover($event,pindex)">
            {{ pic.position }}
          </div>
        </div>
      </div>
      <el-image-viewer v-if="isShowPicList" :initialIndex="picStartIndex" :on-close="onClosePicList" :url-list="picList" />
    </div>
  </div>
</template>

<script>

// import CustomImageViewer from './test-image-viewer'
import PubSub from 'pubsub-js'

export default {
  name: "PictureDateThumbList",
  components: {
    'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  data() {
    return {
      pictureGroups: {},
      pictureGroupChecks: {},
      checkAll: false,
      hoverIndex: -1,
      previewStartIndex: -1,
      showPreview: false,
      previewPhotos: [],
      isShowPicList: true,
      picList: [],
      picStartIndex: 0,
    };
  },
  created() {
    this.groupByDate();
  },
  mounted () {
    var self = this
    this.isShowPicList = false
    PubSub.subscribe('closePicList', ()=>{
      self.isShowPicList = false
      self.$store.commit("hideBottomBar")
    })

  },
  watch: {
    "$store.state.pictures": function () {
      this.checkAll = false
      this.groupByDate();
    },
  },
  methods: {
    handleHover(evt, index) {
      this.hoverIndex = index
    },
    groupByDate() {
      const pictureGroups = {};
      const pictureGroupChecks = {};
      this.$store.state.pictures.forEach((pic) => {
        pic.checked = false;
        const date = new Date().format("yyyy-MM-dd");
        if (!pictureGroups[date]) {
          pictureGroups[date] = [];
          pictureGroupChecks[date] = false;
        }
        pictureGroups[date].push(pic);
      });
      this.pictureGroups = pictureGroups;
      this.pictureGroupChecks = pictureGroupChecks;

    },
    handleCheckAll(check) {
      for (let key in this.pictureGroups) {
        this.pictureGroupChecks[key] = check;
        this.pictureGroups[key].forEach((pic) => {
          pic.checked = check;
        });
      }
      this.calcCheckedStatus();
    },
    handleCheckDate(isChecked, date) {
      this.pictureGroupChecks[date] = isChecked;
      this.pictureGroups[date].forEach((pic) => {
        pic.checked = isChecked;
      });
      this.calcCheckedStatus();
    },
    handleClickItem(evt, date, index) {
      const that = this
      const targetNode = evt.target.nodeName;
      if (targetNode !== "IMG" || (evt.target.id && evt.target.id === 'correct')) {
        this.pictureGroups[date][index].checked = !this.pictureGroups[date][
            index
            ].checked;
        let checkDate = true;
        this.pictureGroups[date].forEach((pic) => {
          if (!pic.checked) {
            checkDate = false;
          }
        });
        this.pictureGroupChecks[date] = checkDate;
        this.calcCheckedStatus();
      } else {
        this.$store.commit('showLoading')
        this.$store.commit("showBottomBar");
        setTimeout(function () {
          that.$store.commit('hideLoading')
        }, 1000)
      }
    },
    calcCheckedStatus() {
      let checkAll = true;
      for (let key in this.pictureGroupChecks) {
        if (!this.pictureGroupChecks[key]) {
          checkAll = false;
          break;
        }
      }
      this.checkAll = checkAll;
      //强制刷新
      this.$forceUpdate();
      //整理出选中的图片
      const checkedPics = [];
      for (let date in this.pictureGroups) {
        const pics = this.pictureGroups[date];
        pics.forEach((pic) => {
          if (pic.checked) {
            checkedPics.push(pic);
          }
        });
      }
      this.$store.commit("syncCheckedPictures", checkedPics);
    },
    gop(e) {
      let that = this
      e && e.stopPropagation()
      window.open('/img/default_gongzuofeng.e507ad12.png')
      setTimeout(()=>{
        that.isShowPicList = false
        that.$store.commit("hideBottomBar");
      },1000)
    },
    onPreview (date) {
      var self = this
      var index = 0
      for (var key in this.pictureGroups) {
        if (key === date) {
          break
        } else {
          index += this.pictureGroups[key].length
        }
      }
      self.$store.commit("syncPicIndex", index)
      this.$nextTick(()=>{
        let domImageMask = document.querySelector(".el-image-viewer__mask")
        let closeButton = document.querySelector(".el-image-viewer__close")
        let canvas = document.querySelector('.el-image-viewer__canvas img')
        let nextButton = document.querySelector('.el-image-viewer__next')
        let prevButton = document.querySelector('.el-image-viewer__prev')
        if (!domImageMask || !closeButton) {
          return
        }
        domImageMask.addEventListener("click", () => {
            document.querySelector(".el-image-viewer__close").click();
            self.$store.commit("hideBottomBar")
        })
        closeButton.addEventListener("click", () => {
            self.$store.commit("hideBottomBar")
        })

        if (nextButton) {
        nextButton.addEventListener("click", () => {
            var canvas1 = document.querySelector('.el-image-viewer__canvas img')
            canvas1.src = canvas1.src.replace('?x-oss-process=style/style150', '')
        })
        }

        if (prevButton){
                  prevButton.addEventListener("click", () => {
                    var canvas1 = document.querySelector('.el-image-viewer__canvas img')
                    canvas1.src = canvas1.src.replace('?x-oss-process=style/style150', '')
                })
        }

        canvas.src = canvas.src.replace('?x-oss-process=style/style150', '')
      })
    },
    onClosePreview () {
      // console.log('关闭预览')
    },
    viewPicList (picValue, photoIndex) {
      var self = this
      this.picList = picValue.map(t => t.url + '?x-oss-process=image/resize,m_lfit,h_160,w_120')
      this.picStartIndex = photoIndex
      this.isShowPicList = true
      this.$nextTick(()=>{
        let domImageMask = document.querySelector(".el-image-viewer__mask")
        let closeButton = document.querySelector(".el-image-viewer__close")
        let canvas = document.querySelector('.el-image-viewer__canvas img')
        let nextButton = document.querySelector('.el-image-viewer__next')
        let prevButton = document.querySelector('.el-image-viewer__prev')
        if (!domImageMask || !closeButton) {
          return
        }
        domImageMask.addEventListener("click", () => {
            document.querySelector(".el-image-viewer__close").click();
            self.$store.commit("hideBottomBar")
        })
        closeButton.addEventListener("click", () => {
            self.$store.commit("hideBottomBar")
            // self.isShowPicList = false
        })

        if (nextButton) {
        nextButton.addEventListener("click", () => {
            var canvas1 = document.querySelector('.el-image-viewer__canvas img')
            canvas1.src = canvas1.src.replace('?x-oss-process=image/resize,m_lfit,h_160,w_120', '')
        })
        }

        if (prevButton){
                  prevButton.addEventListener("click", () => {
                    var canvas1 = document.querySelector('.el-image-viewer__canvas img')
                    canvas1.src = canvas1.src.replace('?x-oss-process=image/resize,m_lfit,h_160,w_120', '')
                })
        }

        canvas.src = canvas.src.replace('?x-oss-process=image/resize,m_lfit,h_160,w_120', '')
      })
    },
    onClosePicList () {
      this.isShowPicList = false
    }
  },
};
</script>

<style scoped lang="scss">
.picture-list {
  margin-left: 25px;

  .row-header {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #6a6a6a;
    height: 40px;
    width: 100%;

    .checked-total {
      margin-left: 10px;
    }

    .check-date {
      color: #222;
      font-weight: bold;
    }
  }

  .row-pics {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #6a6a6a;
    width: 100%;
    flex-wrap: wrap;
    margin-top: -10px;

    .pic.checked {
      background: #f1f5fb;
    }

    .pic.checked:hover {
      background: #f1f5fb;
    }

    .pic:hover {
      background: #f5f5f5;
    }

    .pic {
      display: flex;
      flex-direction: column;
      width: 180px;
      margin-left: 10px;
      align-items: center;
      position: relative;
      margin-top: 10px;
      padding: 15px 10px;
      cursor: pointer;

      .unchecked {
        width: 15px;
        height: 15px;
        background: #fff;
        border-radius: 50%;
        border: 1px solid #949494;
        display: flex;
        align-items: center;
        padding: 3px;
        position: absolute;
        right: 0px;
        top: 10px;
      }

      .checked {
        width: 15px;
        height: 15px;
        background: #2298f3;
        border-radius: 50%;
        display: flex;
        align-items: center;
        padding: 3px;
        position: absolute;
        right: 0px;
        top: 10px;

        img {
          width: 10px;
          height: 10px;
          margin: 0;
          padding: 0;
        }
      }

      .search {
        position: absolute;
        right: 40px;
        top: 170px;
        z-index: 999;

        img {
          width: 18px;
          height: 18px;
          margin: 0;
          padding: 0;
          cursor: pointer;
        }
      }

      .image {
        border-radius: 2px;
      }

      .image:hover {
        cursor: url("../../assets/images/search.ico"), auto;
      }

      img {
        flex: 1;
        display: flex;
        align-items: center;
        margin-right: 10px;
        margin-left: 10px;
        height: 180px;
        border-radius: 3px;
      }

      .nickname {
        text-align: center;
        margin-top: 5px;
        color: #666;
      }

      .time {
        text-align: center;
        font-size: 13px;
        color: #999;
      }

      .position {
        text-align: center;
        font-size: 13px;
        color: #999;
      }
    }
  }
}
</style>
