<template>
  <div class="customer-service-inner">
    <div class="desc">微信客服：</div>
    <img class="qrcode" src="../assets/images/cm-qr.jpg" />
    <div class="desc">客服微信：dakaxiangji01</div>
    <div class="desc">官方QQ群：720844363</div>
  </div>
</template>

<script>

export default {
  name: "customer-service",
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.customer-service-inner {
  height: 100%;
  display: flex;
  flex-direction: column;

  .qrcode {
    width: 200px;
    height: 200px;
    margin: 7px auto 0 28px;
  }

  .desc {
    margin-left: 40px;
    margin-top: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
  }

  .desc:nth-child(1) {
    margin-top: 33px;
  }
  
}
</style>
