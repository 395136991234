<template>
  <div class="folder-list">
    <div class="row-header" v-show="$store.state.category !== 'member'">
      <el-checkbox v-model="checkAll" @change="handleCheckAll">
        {{ $store.state.checkedFolderTotal > 0 ? '已选中' + $store.state.checkedFolderTotal + '个文件夹' : '全选' }}
      </el-checkbox>
    </div>
    <div class="row-folders">
      <div @click="handleCheckFolder($event,findex)" @dblclick="handleDbclickFolder($event,findex)"
           :class="folder.checked?'folder checked':'folder'" v-for="(folder,findex) in $store.state.folders"
           :key="findex">
        <div class="checked" v-if="folder.checked"><img src="../../assets/images/unchecked.png"/></div>
        <img class="folder-icon" src="../../assets/images/folder.png" alt="文件夹" onselectstart="return false">
        <div class="tag" onselectstart="return false">{{ folerDisplayTitle(folder) }}</div>
        <div v-if="folder.imageNum !== undefined" class="tag" onselectstart="return false" style="margin-top:0px;">({{ folder.imageNum }}张)</div>
      </div>
    </div>
  </div>
</template>

<script>

import {getApi} from "@/common"

export default {
  name: "FolderThumbList",
  props: ['type'],
  data() {
    return {
      checkAll: false,
      singleClickTimeout: null,
      startDate: '',
      endDate: ''
    }
  },
  mounted () {
    const end = new Date();
    const start = new Date();
    start.setDate(1)
    // start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
    this.startDate = start.format('yyyy-MM-dd')
    this.endDate = end.format('yyyy-MM-dd')
  },
  methods: {
    folerDisplayTitle(folder) {
      var displayStr = ""
      if (folder.title) {
        displayStr = folder.title
      } else {
        displayStr = folder.nickname
      }
      if (displayStr.length > 8) {
        return displayStr.substring(0,7) + "..."
      }
      return displayStr
    },
    handleCheckAll(check) {
      console.log('全选状态：', check)
      this.$store.commit('checkAllFolders', check)
      this.calcCheckedStatus()
    },
    handleCheckFolder(check, index) {
      if (this.$store.state.category === 'member') {
        return
      }
      const that = this
      clearTimeout(this.singleClickTimeout)
      this.singleClickTimeout = setTimeout(function () {
        console.log("单张文件夹的选中状态：", check, index);
        that.$store.commit("checkOneFolder", index);
        that.calcCheckedStatus();
      }, 200)
    },
    handleDbclickFolder(evt, index) {
      var that = this
      clearTimeout(this.singleClickTimeout)
      const currentFolder = this.$store.state.folders[index]
      if (this.$store.state.category === 'work') {
        const params =
          "teamId=" + currentFolder.teamId
          + "&start=" + that.startDate
          + "&end=" + that.endDate
          + '&pageSize=30&pageNum=1'
          + '&labelId=' + currentFolder.id
        getApi(this.apiBase + "/camera/admin/v2/labelImages?" + params, function (res) {
          if (res.success === "1") {
            that.$store.commit("syncPictures", {
              pictures: res.content.list,
              total: res.content.total,
              previousFolder: currentFolder,
              previousFolders: that.$store.state.folders
            });
          } else {
            that.$message.error(res.message);
          }
        });
      } else if (this.$store.state.category === 'member') {
        const params =
          "teamId=" + currentFolder.teamId
          + "&start=" + that.startDate
          + "&end=" + that.endDate
          + '&pageSize=30&pageNum=1'
          + '&userId=' + currentFolder.id
        getApi(this.apiBase + "/camera/admin/v2/userImages?" + params, function (res) {
          if (res.success === "1") {
            that.$store.commit("syncPictures", {
              pictures: res.content.list,
              total: res.content.total,
              previousFolder: currentFolder,
              previousFolders: that.$store.state.folders
            });
          } else {
            that.$message.error(res.message);
          }
        });
      }
      // if (this.$store.state.category === 'work' && currentFolder.grade === 1) {
      //   //一级目录，需要进入二级目录
      //   this.$store.commit('syncFolders', {
      //     folders: currentFolder.cameraTeamLabelInfos,
      //     previousFolder: currentFolder,
      //     previousFolders: this.$store.state.folders,
      //     folderLevel: 2
      //   })
      // } else {
      //   //二级目录，需要进入图片列表页面
      //   this.$store.commit('syncPictures', {
      //     pictures: currentFolder.cameraTeamImageInfos,
      //     previousFolder: currentFolder,
      //     previousFolders: this.$store.state.folders
      //   })
      // }
    },
    calcCheckedStatus() {
      //计算全选的状态
      let checkAll = true
      this.$store.state.folders.forEach(folder => {
        if (!folder.checked) {
          checkAll = false
        }
      })
      this.checkAll = checkAll
    },
  }
}
</script>

<style scoped lang="scss">
.folder-list {
  margin-left: 25px;
  background: #fff;

  .row-header {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #6a6a6a;
    height: 50px;

    .check {
      flex: 3;
      margin-right: 10px;
    }

    .time {
      width: 400px;
      text-align: center;
    }
  }


  .row-folders {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #6a6a6a;
    width: 100%;
    flex-wrap: wrap;

    .folder.checked {
      background: #F1F5FB;
    }

    .folder.checked:hover {
      background: #F1F5FB;
    }

    .folder:hover {
      background: #F5F5F5;
    }

    .folder {
      display: flex;
      flex-direction: column;
      width: 160px;
      height: 160px;
      align-items: center;
      position: relative;
      margin-right: 10px;
      justify-content: center;

      .checked {
        width: 15px;
        height: 15px;
        background: #2298f3;
        border-radius: 50%;
        display: flex;
        align-items: center;
        padding: 3px;
        position: absolute;
        justify-content: center;
        right: 10px;
        top: 10px;

        img {
          width: 10px;
          margin: 0;
          padding: 0;
        }
      }

      .folder-icon {
        display: flex;
        align-items: center;
        height: 56px;
        width: 68px;
        border-radius: 3px;
      }

      .tag {
        text-align: center;
        margin-top: 25px;
        color: #666;
        max-width: 160px;
      }
    }
  }
}
</style>
