import Vue from 'vue'
import Vuex from 'vuex'

//挂载Vuex
Vue.use(Vuex)

let data = {
  state: {
    picIndex: -1,
    loading: false,
    //图片列表相关
    pictures: [],
    pictureTotal: 0,
    //文件夹列表相关
    folders: [],
    folderIndex: 1,
    total: 0,
    folderLevel: 1,
    previousFolders: [],
    previousFolder: [],
    //图片预览相关
    showBottomBar: false,
    //团队选择相关
    teams: [],
    team: {},
    teamId: '',
    //图片选择相关
    checkedPictures: [],
    checkedPictureTotal: 0,
    //文件夹选择相关
    hasCheckedFolder: false,
    checkedFolders: [],
    checkedFolderTotal: 0,
    //列表显示形式相关
    category: 'time',
    listType: 'thumb',
    listLevel: 'folder'
  },
  mutations: {
    syncPicIndex(state, index) {
      state.picIndex = index
    },
    showLoading(state) {
      state.loading = true
    },
    hideLoading(state) {
      state.loading = false
    },
    syncListType(state, listType) {
      state.listType = listType
      localStorage.setItem('camera-store-state', JSON.stringify(state))
    },
    syncCategory(state, category) {
      state.category = category
      localStorage.setItem('camera-store-state', JSON.stringify(state))
    },
    syncPictures(state, data) {
      console.log('同步图片：', data)
      if (data.previousFolder) {
        state.previousFolder.push(data.previousFolder)
      }
      if (data.previousFolders) {
        state.previousFolders.push(data.previousFolders)
      }
      console.log('同步图片：', state.previousFolders, state.previousFolder)
      const pictures = data.pictures
      pictures.forEach(pic => {
        pic.checked = false
      })
      state.pictures = pictures
      state.pictureTotal = data.total
      state.total = data.total
      state.listLevel = 'picture'
      state.checkedPictures = []
      state.checkedPictureTotal = 0
      localStorage.setItem('camera-store-state', JSON.stringify(state))
    },
    gotoParentFolders(state) {
      const folders = state.previousFolders.pop()
      console.log('弹出之后的：', state.previousFolders)
      state.folders = folders
      state.total = state.folders.length
      state.folderLevel = state.previousFolders.length + 1
      state.listLevel = 'folder'
      console.log('目录层级：', state.folderLevel)
    },
    gotoRootFolders(state) {
      state.folders = state.previousFolders[0]
      state.folderLevel = 1
      state.total = state.folders.length
      state.previousFolders = []
      state.listLevel = 'folder'
    },
    syncFolders(state, data) {
      if (data.folderLevel === 1) {
        state.previousFolder = []
        state.previousFolders = []
      }
      if (data.previousFolder) {
        state.previousFolder.push(data.previousFolder)
      }
      if (data.previousFolders) {
        state.previousFolders.push(data.previousFolders)
      }
      console.log('当前的文件夹：', state.previousFolders, state.previousFolder)
      data.folders.forEach(folder => {
        folder.checked = false
      })
      state.folders = data.folders
      state.folderIndex = 1
      state.total = data.total
      state.listLevel = 'folder'
      state.folderLevel = data.folderLevel
      state.checkedFolderTotal = 0
      localStorage.setItem('camera-store-state', JSON.stringify(state))
    },
    syncCheckedPictures(state, checkedPictures) {
      state.checkedPictures = checkedPictures
      state.checkedPictureTotal = checkedPictures.length
      localStorage.setItem('camera-store-state', JSON.stringify(state))
    },
    syncCheckedFolders(state, checkedFolders) {
      state.checkedFolders = checkedFolders
      state.checkedFolderTotal = checkedFolders.length
      state.hasCheckedFolder = checkedFolders.length > 0
      localStorage.setItem('camera-store-state', JSON.stringify(state))
    },
    syncTeams(state, teams) {
      state.teams = teams
      localStorage.setItem('camera-store-state', JSON.stringify(state))
    },
    syncTeam(state, team) {
      state.team = team
      localStorage.setItem('camera-store-state', JSON.stringify(state))
    },
    activeTeam(state, payload) {
      Vue.set(state.teams, payload.index, payload.team)
      state.teamId = payload.team.id
      localStorage.setItem('camera-store-state', JSON.stringify(state))
    },
    showBottomBar(state) {
      state.showBottomBar = true
      localStorage.setItem('camera-store-state', JSON.stringify(state))
    },
    hideBottomBar(state) {
      state.showBottomBar = false
      localStorage.setItem('camera-store-state', JSON.stringify(state))
    },
    switchListType(state) {
      if (state.listType === 'item') {
        state.listType = 'thumb'
      } else {
        state.listType = 'item'
      }
      localStorage.setItem('camera-store-state', JSON.stringify(state))
    },
    checkAllPictures(state, checked) {
      state.pictures.forEach(pic => {
        pic.checked = checked
      })
      Vue.set(state, 'pictures', state.pictures)
      if (checked) {
        state.checkedPictureTotal = state.pictures.length
        state.checkedPictures = state.pictures
      } else {
        state.checkedPictureTotal = 0
        state.checkedPictures = []
      }
      localStorage.setItem('camera-store-state', JSON.stringify(state))
    },
    checkOnePicture(state, index) {
      if (index !== -1) {
        const pic = state.pictures[index]
        pic.checked = !pic.checked
        Vue.set(state.pictures, index, pic)
      }
      //计算出新的选中张数
      let total = 0;
      state.pictures.forEach(pic => {
        if (pic.checked) {
          total++;
        }
      })
      state.checkedPictureTotal = total
      //整理出选中的图片
      const checkedPics = [];
      state.pictures.forEach(pic => {
        if (pic.checked) {
          checkedPics.push(pic)
        }
      })
      state.checkedPictures = checkedPics
      localStorage.setItem('camera-store-state', JSON.stringify(state))
    },
    checkAllFolders(state, checked) {
      state.folders.forEach(folder => {
        folder.checked = checked
      })
      Vue.set(state, 'folders', state.folders)
      if (checked) {
        state.checkedFolderTotal = state.folders.length
        state.checkedFolders = state.folders
      } else {
        state.checkedFolderTotal = 0
        state.checkedFolders = []
      }
      localStorage.setItem('camera-store-state', JSON.stringify(state))
    },
    deleteOnePicture(state, index) {
      state.pictures.splice(index, 1)
    },
    checkOneFolder(state, index) {
      const folder = state.folders[index]
      folder.checked = !folder.checked
      Vue.set(state.folders, index, folder)
      //计算出新的选中张数
      let total = 0;
      state.folders.forEach(folder => {
        if (folder.checked) {
          total++;
        }
      })
      state.checkedFolderTotal = total
      //整理出选中的图片
      const checkedFolders = [];
      state.folders.forEach(folder => {
        if (folder.checked) {
          checkedFolders.push(folder)
        }
      })
      state.checkedFolders = checkedFolders
      localStorage.setItem('camera-store-state', JSON.stringify(state))
    },
  },
  actions: {}
}

if (localStorage.getItem('camera-store-state')) {
  data.state = JSON.parse(localStorage.getItem('camera-store-state'))
  data.state.loading = false
  //默认展示缩略图形式
  data.state.listType = 'thumb'
}

//创建VueX对象
const store = new Vuex.Store(data)

export default store
