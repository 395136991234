<template>
  <div class="mapContainer">
    <iframe class="mapView" :src="mapSrc" scrolling="auto" frameborder="0"></iframe>
  </div>
</template>

<script>

import AMap from 'AMap'

export default {
  name: "map",
  data() {
    return {
      key: 'b35c23dfff378a85b5825d54534ba16e',
      jscode: '11e88f09ebe4ab4ab57cc7d2294fb12d',
      lat: undefined,
      lang: undefined,
      name: undefined,
      mapSrc: undefined
    }
  },
  created() {
    if (this.$route.query.lat && this.$route.query.lng) {
      var self = this
      self.lat = this.$route.query.lat
      self.lng = this.$route.query.lng
      self.name = decodeURIComponent(this.$route.query.name)
    } else {
      this.$message.error('参数不正确!')
      this.$router.back(-1)
    }
  },
  mounted () {
    var self = this
    AMap.plugin('AMap.Geolocation', function() {

      var geolocation = new AMap.Geolocation({
        enableHighAccuracy: true,
        timeout: 10000
      })
      geolocation.getCurrentPosition()
      AMap.event.addListener(geolocation, 'complete', onComplete)
      AMap.event.addListener(geolocation, 'error', onError)

      function onComplete (res) {
        self.mapSrc = `https://m.amap.com/navi/?start=${res.position.lng},${res.position.lat}&dest=${self.lng},${self.lat}&destName=${self.name}&naviBy=car&key=${self.key}&jscode=${self.jscode}`
      }

      function onError () {
        self.mapSrc = `https://m.amap.com/navi/?start=&dest=${self.lng},${self.lat}&destName=${self.name}&key=${self.key}&jscode=${self.jscode}`
      }
    })
  }
}
</script>

<style scoped lang="scss">

.mapContainer {
  width: 100%;
  height: 100%;

  .mapView {
    width: 100%;
    height: 100%;
  }
}

</style>
