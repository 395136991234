<template>
  <div class="banner-detail-1-inner">
    <div class="first-row">
      <img src="../../../img/pc/home/banner_detail_1.png">
      <div class="content">
        <div class="title">工作真实拍照用工作蜂</div>
        <div class="desc">时间地点无法修改、无滤镜、无美颜， 每张照片都是真实的。</div>
      </div>
    </div>
    <div class="third-row">
      <div class="customer-case">
        <div class="case">
          <img style="margin-right:150px;" src="../../../img/pc/home/shuiyinpaizhao.png"/>
          <div class="intro">
            <p>水印真实拍照，自动上传</p>
            <p>自动通过网络校准时间，无滤镜、无美颜，真实拍照，时间地点防修改。</p>
          </div>
        </div>
        <div class="case" style="background: #f4f4f4;">
          <div class="intro">
            <p>自定义分类存储，无限空间</p>
            <p>拍照自动上传至团队，自定义相册分类存储无限存储空间，长期保存归档方便查看，支持批量导出。</p>
          </div>
          <img style="margin-left:150px;" src="../../../img/pc/home/zidingyifenlei1.png"/>
        </div>
        <div class="case">
          <img style="margin-right:150px;" src="../../../img/pc/home/kaoqintongji.png"/>
          <div class="intro">
            <p>实时考勤统计</p>
            <p>拍照自动生成考勤数据，可查看团队实时、周月统计数据。</p>
          </div>
        </div>
        <div class="case" style="background: #f4f4f4;">
          <div class="intro">
            <p>拍照自动转发</p>
            <p>支持钉钉群、企业微信群、企业内部管理系统，无需更换平台即可完成照片同步。</p>
          </div>
          <img style="margin-left:150px;" src="../../../img/pc/home/shandianzhuanfa.png"/>
        </div>
        <div class="case">
          <img style="margin-right:150px;" src="../../../img/pc/home/duopingtai.png"/>
          <div class="intro">
            <p>多平台协作</p>
            <p>工作蜂APP、工作蜂小程序、工作蜂管理后台，实时同步，提高团队协作效率。</p>
          </div>
        </div>
      </div>
    </div>
    <sub-footer></sub-footer>
  </div>
</template>

<script>

import SubFooter from './SubFooter.vue'
import PubSub from 'pubsub-js'

export default {
  name: "BannerDetail1",
  components: {
    SubFooter
  },
  mounted () {
    PubSub.publish('changeHeaderIndex', 101)
    PubSub.publish('menuName', '')
  }
}

</script>

<style scoped lang="scss">

.banner-detail-1-inner {
  display: flex;
  flex-direction: column;
  align-items: center;

  .first-row {
    width: 1424px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;

    img {
      width: 1424px;
      height: 460px;
    }

    .content {
      display: flex;
      flex-direction: column;
      color: #FFF;
      position: absolute;

      top: 95px;
      left: 120px;

      .title {
        font-size: 50px;
        text-align: left;
        line-height: 70px;
      }

      .desc {
        width: 345px;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        margin-top: 20px;
      }
  }
  }

  .third-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .customer-case {
      display: flex;
      flex-direction: column;
      width: 100%;

      .case {
        display: flex;
        height: 434px;
        justify-content: center;
        align-items: center;

        img {
          height: 400px;
        }

        .intro {
          display: flex;
          flex-direction: column;
          width: 450px;

          p {
            margin: 0px;
          }

          p:nth-of-type(1) {
            font-size: 30px;
            font-weight: 500;
            line-height: 42px;
            text-shadow: 1px 0px #000;
          }

          p:nth-of-type(2) {
            font-size: 20px;
            font-weight: 300;
            line-height: 28px;
            margin-top: 15px;
          }
        }
      }
    }
  }
}

</style>
